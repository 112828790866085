import React, { useState, useEffect } from "react";
import global from "../../global-variables";
import Header from "../layout/header";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import WOW from "wowjs";
import { GetCareerList } from "../../api/career-functions";
import { Container, Row, Col } from "reactstrap";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import axios from "axios";
import globalVariables from "../../global-variables";
import Metatag from "../../hooks/Metatag";
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import Accordion from 'react-bootstrap/Accordion';


export default function Careers() {


  const navigate = useNavigate();
  const [careerList, setCareerList] = useState();

  const [categoryTypeList, setCategoryTypeList] = useState([]);

  useEffect(() => {

    // initiate the wow effect
    new WOW.WOW({
      live: false,
    }).init();


  }, [careerList]);

  // document.title = "Careers"
  // success function when the list is retrived
  const OnGetCareerSuccess = (p_response) => {
    // set the list for looping
    setCareerList(p_response);
  }

  // things to be done when the page is loaded
  useEffect(() => {
    //make the object for getting the active career list
    let _objCareerList = new Object();
    _objCareerList.career_status = 1;
    // call the function to get the career
    GetCareerList(_objCareerList, OnGetCareerSuccess, navigate);

  }, []);

  useEffect(() => {
    $(window).on("scroll", function () {
      $(".navbar").offset().top > 10 ? $(".fixed-top").removeClass("bg-header-home") : $(".fixed-top").removeClass("bg-header-home")
    });

    let _obj = new Object()
    _obj.ctype_status = 1
    axios.post(globalVariables.variables.strApiUrl + "/careerApi/getcareerTypeList", _obj)
      .then((response_ctype) => {
        if (response_ctype.data === 'error') {
          navigate('/error-page')
        }
        else {
          setCategoryTypeList(response_ctype.data[0])
        }
      })
  }, [])


  return (
    <>
      <Header conversion_code='1'></Header>

      {/* MetaTag */}
      <Metatag title='Job Openings for Green Energy and Sustainability Research at IIT Bombay'
        description='Job Openings for Green Energy and Sustainability Research at IIT Bombay'
        keywords='Job Openings for Green Energy Research, Job Openings for Sustainability Research, Job Openings at GESH IIT Bombay'
      />
      {/* career */}
      <Container fluid className="bg-visual-inside p-0" >
        <div className="visual-inside-big visual-container-inside">
          <Container>
            <Row className="g-md-0 rounded-4">
              <Col md={6} className="">
              <div className="d-flex align-items-end h-100 pb-md-5">
                  <div className="d-inline-block m-0 align-self-end py-xl-3 p-2 text-white">
                    <h1 className="fw-light text-white m-0 align-self-center wow fadeInUp">
                      We are Hiring!
                    </h1>
                  </div>
                </div>
              </Col>
              <Col md={6} className="ms-auto">
                <div className="border-bottom border-5 border-primary rounded-4">
                  <img src={global.variables.strDomainUrl + "images/visual-careers.jpg"} className="img-fluid rounded-4" alt="" title="" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>

      {/* careers Container */}




      <Container className="my-md-5 my-3 pt-5 careers">
        <Row className="g-0 border rounded-4 p-md-5 p-3">
          {
            categoryTypeList?.map((val_ctype) => {
              return (
                <>

                  <h3 className="text-primary mb-3 mt-4">{val_ctype.ctype_name}</h3>
                  <Accordion defaultActiveKey="" flush >
                    {
                      careerList?.map((val, index) => {
                        const scrollToPosition = () => {
                          const position =  340 + index * 30; // Adjust the calculation based on your requirements
                          window.scrollTo({ top: position, left: 0, behavior: 'smooth' });
                      };
                        return (
                          <>
                            {

                              //check ctype id and career ctype id
                              val_ctype.ctype_id === val.career_ctype_id ?

                                <Accordion.Item key={index} eventKey={index.toString()} className="overflow-hidden mb-2">
                                  <Accordion.Header onClick={scrollToPosition}>{val.career_name}</Accordion.Header>
                                  <Accordion.Body>
                                    {
                                      val.career_location != null && val.career_location != '' ?
                                        <p ><b class="text-dark">Job Location:</b> {val.career_location}</p>
                                        :
                                        null
                                    }
                                    {
                                      val.career_desc != null && val.career_desc != '' ?
                                        <span className='projects list-group list-group-flush' dangerouslySetInnerHTML={{ __html: val.career_desc }}></span>
                                        :
                                        null
                                    }
                                    {
                                      val.career_apply_url != null && val.career_apply_url != '' ?
                                        <Link to={val.career_apply_url} target="_blank" className="btn btn-primary rounded-pill px-4 ">
                                          Apply Here
                                        </Link>
                                        :
                                        null
                                    }
                                  </Accordion.Body>
                                </Accordion.Item>

                                : null
                            }
                          </>

                        )

                      })
                    }
                  </Accordion>

                </>
              )
            })
          }
        </Row>
      </Container>

      {/* Breadcrumb */}
      <Breadcrumb className="breadcrumb-panel mt-auto">
        <Breadcrumb.Item href={globalVariables.variables.strDomainUrl}>Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Careers</Breadcrumb.Item>
      </Breadcrumb>
    </>
  );
}