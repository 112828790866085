/** @format */

import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import global from "../../global-variables";
import Header from "../layout/header";
import { Link } from "react-router-dom";
import $ from "jquery";
import WOW from "wowjs";
import { FaRegCalendar, FaMapMarkerAlt, FaLinkedinIn, FaInstagram, FaYoutube, FaPinterest, FaAngleLeft } from "react-icons/fa";


import { Container, Row, Col } from "reactstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import FsLightbox from "fslightbox-react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Button } from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';

import Metatag from "../../hooks/Metatag";

export default function GESHProjectdestailsStatic() {
  const [toggler, setToggler] = useState(true);
  const [show, setShow] = useState(false);
  const [index, setIndex] = useState(0);

  const [key, setKey] = useState('Day 1');

  const [news_gallery_toggler, setNewsGalleryToggler] = useState(false);
  const [news_gallery, setNewsGallery] = useState([]);
  const [gallery_key, setGalleryKey] = useState("");

  const navigate = useNavigate();

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  useEffect(() => {
    $(window).on("scroll", function () {
      $(".navbar").offset().top > 10
        ? $(".fixed-top").removeClass("bg-header-home")
        : $(".fixed-top").removeClass("bg-header-home");
    });

    // initiate the wow effect
    new WOW.WOW({
      live: false,
    }).init();
  }, []);

  return (
    <>
      <Header></Header>
      <Metatag title='Sustainability Research Projects by IIT Bombay'
        description='Sustainability Research Projects by IIT Bombay'
        keywords='Sustainability Research Projects by IIT Bombay'
      />
      {/* Research Visual Panel */}
      <Container fluid className="bg-visual-inside-eve-details p-0" >
        <div className="visual-inside-big">
          <Container>
            <Row className="g-md-0 rounded-4 justify-content-center align-items-center mt-md-5">
              <Col xs={8} md={6}>
                <h1 className="fw-light text-white d-inline-block m-0 align-self-center wow fadeInUp">
                  GESH Project
                </h1>
              </Col>
              <Col xs={4} md={6}>
                <Button onClick={() => navigate(-1)} className="btn btn-primary rounded-pill Faicon px-3 py-1 ms-auto d-flex align-items-center wow fadeInUp">
                  <FaAngleLeft /> Back
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>

      <Container className="event-info pt-4 pt-md-5 pt-xl-0">
        <div className="rounded-4 bg-light shadow-sm position-relative overflow-hidden">
          <Row className="p-md-4 p-3 g-3">
            <Col md={8} lg={6} className="ps-lg-4 px-lg-3 pe-md-5">
              <img src={global.variables.strDomainUrl + "images/pic-event.jpg"} alt="IITB" title="IITB" className="img-fluid rounded-3 bg-white wow fadeInUp" />
            </Col>
            <Col md={12} lg={6} className="d-flex align-items-start flex-column px-lg-4">
              <h5 className="mb-3 lh-base text-dark wow fadeInUp">
                Towards a unified physics-based theory for opposing trends of seasonal and extreme monsoon rainfall for their reliable projections
              </h5>

              <ul className="list-group projectslist mb-3 wow fadeInUp">
                <li className="list-group-item border-0 px-0 py-1 bg-transparent"><span className="text-black fw-medium">Faculty:</span> <Link to="#">Dinesh Kabra</Link>, <Link to="#">Karthikeyan Lanka</Link></li>
                <li className="list-group-item border-0 px-0 py-1 bg-transparent"><span className="text-black fw-medium">Department(s):</span> Chemical Engineering, Environment Science and Engineering                </li>
                <li className="list-group-item border-0 px-0 py-1 bg-transparent"><span className="text-black fw-medium">Focus Areas:</span> Solar Thermal & PV, Biofuels, Green Hydrogen</li>
              </ul>
              <b className="text-black mb-2 fw-medium">Sustainable Development Goals (SDGs):</b>
              <Row className="row-cols-md-4 g-3 projectslist w-100">
                <Col className="small text-center">
                  <Link to="#">
                    <div className="rounded-3 border bg-white mb-1 wow fadeInUp">
                      <img src={global.variables.strDomainUrl + "images/icon-2.png"} alt="Zero Hunger" title="Zero Hunger" className="img-fluid p-0" />
                    </div>
                    <div className="wow fadeInUp">Zero Hunger</div>
                  </Link>
                </Col>
                <Col className="small text-center">
                  <Link to="#">
                    <div className="rounded-3 border bg-white mb-1 wow fadeInUp">
                      <img src={global.variables.strDomainUrl + "images/icon-11.png"} alt="Sustainable Cities & Communities" title="Sustainable Cities & Communities" className="img-fluid p-0" />
                    </div>
                    <div className="wow fadeInUp">Sustainable Cities & Communities</div>
                  </Link>
                </Col>
                <Col className="small text-center">
                  <Link to="#">
                    <div className="rounded-3 border bg-white mb-1 wow fadeInUp">
                      <img src={global.variables.strDomainUrl + "images/icon-15.png"} alt="Life on Land" title="Life on Land" className="img-fluid p-0" />
                    </div>
                    <div className="wow fadeInUp">Life on Land</div>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Container>

      {/* Container */}
      <Container className="my-md-5 mt-5 mb-0">
        <Row className="g-0">
          <Col md={9} lg={9}>
            <div className="border rounded-4 px-lg-5 p-4 py-lg-5 h-100">
              <div className="pe-md-2 col-md-11">
                <div className="mb-5" id="AboutEvent">
                  <h3 className="mb-3 wow fadeInUp">About Project</h3>
                  <p className="wow fadeInUp">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                </div>


                {/* Event Archive */}
                <div className="my-md-5">
                  <div className="mb-4">
                    <h3 className="wow fadeInUp">Project Gallery</h3>
                  </div>
                  <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 1280: 3 }}>
                    <Masonry gutter="1.5rem">
                      {/* <div>
                        <Link className="pointer" onClick={() => setToggler(!toggler)}>
                          <img src={global.variables.strDomainUrl + "images/pic-sustainability-assessment.jpg"} alt="" title="" className="img-fluid p-0 rounded-4 border bg-white wow fadeInUp" />
                        </Link>
                      </div> */}

                      <div>
                        <Link className="pointer" onClick={() => setToggler(!toggler)}>
                          <img src="https://i.imgur.com/fsyrScY.jpg" alt="" title="" className="img-fluid p-0 rounded-3 border bg-white wow fadeInUp" />
                        </Link>
                      </div>
                      <div>
                        <img src="https://img.freepik.com/free-photo/view-green-forest-trees-with-co2_23-2149675040.jpg?t=st=1730895017~exp=1730898617~hmac=04bcc40835c72e8ef8d6a48c48ea4eba8f76d745be25c6f64cf610ec55d3b758&w=740" alt="" title="" className="img-fluid p-0 rounded-3 border bg-white wow fadeInUp" />
                      </div>
                      <div>
                        <img src="https://img.freepik.com/free-photo/researcher-woman-measuring-eco-sapling-while-observing-biological-transformation_482257-2415.jpg?t=st=1730894609~exp=1730898209~hmac=0cb1e9870ed222c618692901a1a53a2808fa9e44b3170d5a10cd3fbd26e19911&w=740" alt="" title="" className="img-fluid p-0 rounded-3 border bg-white wow fadeInUp" />
                      </div>
                      <div>
                        <img src="https://img.freepik.com/free-photo/front-view-man-using-tablet-eco-friendly-wind-power-project-layout_23-2148847802.jpg?t=st=1730895201~exp=1730898801~hmac=4e4c4e92eaa0c1c6c2d6bf3589927a7479591928bec311281906f8507f3915a5&w=740" alt="" title="" className="img-fluid p-0 rounded-3 border bg-white wow fadeInUp" />
                      </div>


                    </Masonry>
                  </ResponsiveMasonry >
                </div>
              </div>
            </div>
          </Col>
          <Col md={4} lg={4} className="py-5 container-end" sticky="top">
            <div className="ps-lg-5 ps-md-4 bg-white">
              <div className="border-bottom border-4 border-primary rounded-4 h-100">
                <div className="bg-primary bg-opacity-10 rounded-4 py-4 px-4 border h-100">
                  <div className="publications">
                    <h5 className="fw-bold mb-0">Publications</h5>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item bg-transparent px-0 py-3">
                        <Link to="https://www.sciencedirect.com/science/article/abs/pii/S1364032115007194?via%3Dihub" target="_blank">Zhao, D. X., He, B. J., Johnson, C., & Mou, B. (2015). Social problems of green buildings: From the humanistic needs to social acceptance. Renewable and Sustainable Energy Reviews, 51, 1594-1609</Link>
                      </li>
                      <li className="list-group-item bg-transparent px-0 py-3">
                        <Link to="https://linkinghub.elsevier.com/retrieve/pii/S0301421519304380" target="_blank">Sharma, S. V., Han, P., & Sharma, V. K. (2019). Socio-economic determinants of energy poverty amongst Indian households: A case study of Mumbai. Energy Policy, 132, 1184-1190</Link>
                      </li>
                      <li className="list-group-item bg-transparent px-0 py-3">
                        <Link to="https://www.tandfonline.com/doi/full/10.1080/15481603.2018.1549819" target="_blank">Singh, S., & Mishra, S. (2021). Identifying and Reviewing Green Building Alternatives for Navi Mumbai: A Comparative Analysis of Green Building Rating Systems in India. Territorio della Ricerca su Insediamentie Ambiente. Rivista internazionale di cultura urbanistica, 14(26), 59-78</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container >




      {/* Breadcrumb */}
      <Breadcrumb Breadcrumb className="breadcrumb-panel mt-auto">
        <Breadcrumb.Item href={global.variables.strDomainUrl}>
          Home
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Seminar on Micro Nano Plastics and their Environmental
          Impact</Breadcrumb.Item>
      </Breadcrumb>


      {/* News Gallery popup */}
      {/* this link will open the news gallery popup which will be hidden because the button from the grid is causing issue */}
      <FsLightbox toggler={toggler}
        sources={[
          "https://i.imgur.com/fsyrScY.jpg",
        ]}
      />
    </>
  );
}
