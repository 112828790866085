import React, { useState, useEffect } from "react";
import global from "../../global-variables";
import Header from "../layout/header";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import WOW from "wowjs";
import { GetResearchCategoryList } from "../../api/research-category-functions";
import { Container, Row, Col } from "reactstrap";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import globalVariables from "../../global-variables";
import Metatag from "../../hooks/Metatag";


export default function Research() {

  const navigate = useNavigate();
  const [getrcategoryList, setRcategoryList] = useState(null);


  const OnGetResearchCategorySuccess = (p_response) => {

    // set the list for looping
    setRcategoryList(p_response);
  }
  // things to be done when the page is loaded
  useEffect(() => {

    // make the object for getting the research category list
    let _obj = new Object();
    _obj.rcategory_status = 1;

    // call the function to get the research category
    GetResearchCategoryList(_obj, OnGetResearchCategorySuccess, navigate);

  }, []);

  useEffect(() => {

     // remove bg-header-home 
     $(window).on("scroll", function () {
      $(".navbar").offset().top > 10 ? $(".fixed-top").removeClass("bg-header-home") : $(".fixed-top").removeClass("bg-header-home")
    });
    
    // initiate the wow effect
    new WOW.WOW({
      live: false,
    }).init();
  }, [getrcategoryList])

  return (
    <>
      <Header></Header>
      {/* MetaTag */}

      <Metatag title='Sustainability Research Projects by IIT Bombay'
        description='Sustainability Research Projects by IIT Bombay'
        keywords="Sustainability Research Projects by IIT Bombay"
      />


      {/* Visual Panel */}
      <Container fluid className="bg-visual-inside p-0" >
        <div className="visual-inside-big visual-container-inside">
          <Container>
            <Row className="g-md-0 rounded-4">
              <Col md={6}>
                <div className="d-flex align-items-end h-100 pb-md-5">
                  <div className="d-inline-block m-0 align-self-end py-xl-3 p-2 text-white">
                    <h1 className="fw-light text-white m-0 align-self-center wow fadeInUp">
                      Overview
                    </h1>
                  </div>
                </div>
              </Col>
              <Col md={6} className="mt-auto ms-auto">
                <div className="border-bottom border-5 border-primary rounded-4">
                  <img src={global.variables.strDomainUrl + "images/visual-research2.jpg"} className="img-fluid rounded-4" alt="" title="" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>

      {
        getrcategoryList != null ?
          getrcategoryList.length > 0 ?

            <Container className="my-5 pt-md-5 pt-4">
              <div className="m-auto text-center mb-4 col-md-8 mx-auto">
                <div className="wow fadeInUp">The research activities and interests of the IIT Bombay Research Hub for Green Energy and Sustainability can be broadly classified into the following areas:</div>
              </div>
              <Row className="row-cols-1 row-cols-md-2 row-cols-lg-3 g-3 g-xl-4 g-xxl-5 pb-3 research justify-content-center">
                {
                  getrcategoryList.map((val) => {
                    return (
                      <Col className="researchlink">
                      <Link to={globalVariables.variables.strDomainUrl + "research/" + val.rcategory_pagename}>
                        <div className="border-bottom border-4 border-primary rounded-4 h-100">
                          <div className="bg-primary bg-opacity-10 p-4 p-xl-4 rounded-4 h-100 text-center position-relative overflow-hidden">
                            <figure className="position-relative overflow-hidden p-0 rounded-4 border border-white border-4 wow fadeInUp">
                              <img src={global.variables.strApiUrl + "/researchcategory-image/" + val.rcategory_image} width="380" height="175" alt={val.rcategory_name} title={val.rcategory_name} className="img-fluid" />
                            </figure>
                            <h6 className="px-md-3 pt-3 mb-1 text-dark wow fadeInUp">
                              {val.rcategory_name}
                            </h6>
                            {
                              val.rcategory_description != null && val.rcategory_description != '' ?
                                <p className="mb-3 px-md-3 wow fadeInUp"><span className="wow fadeInUp" dangerouslySetInnerHTML={{ __html: val.rcategory_description }}></span></p>
                                :
                                null
                            }
                            <div className="mt-auto">
                              <Link to={globalVariables.variables.strDomainUrl + "research/" + val.rcategory_pagename}
                                className="btn btn-primary rounded-pill px-4 mt-2 mx-1">View
                                Projects</Link>
                            </div>

                          </div>
                        </div>
                        </Link>
                      </Col>
                    )

                  })
                }
              </Row>
            </Container>
            :
            <Container className="my-5 h-100">
              <Row>
                <span className="text-center">No Records Found</span>
              </Row>
            </Container>
          :
          <Container className="my-5 h-100">
            <Row>
              <span className="text-center">Loading...</span>
            </Row>
          </Container>
      }

      {/* Breadcrumb */}
      <Breadcrumb className="breadcrumb-panel mt-auto">
        <Breadcrumb.Item href={globalVariables.variables.strDomainUrl}>Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Research</Breadcrumb.Item>
        <Breadcrumb.Item active>Overview</Breadcrumb.Item>
      </Breadcrumb>
    </>
  );
}