import React, { useState, useEffect } from "react";
import global from "../../global-variables";
import Header from "../layout/header1";
import Footer1 from "../layout/footer1";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import WOW from "wowjs";
import { GetNewsOnHomePageList } from "../../api/news-functions";
import { Container, Row, Col } from "reactstrap";
import Image from 'react-image-webp';
import Slider from 'react-slick';
import { Parallax } from 'react-parallax';
import VisualpanelBlock from "./visualpanel-block";
import globalVariables from "../../global-variables";
import Roadblock_Popup from './roadblock-popup'
import Metatag from '../../hooks/Metatag';
import { GetResearchCategoryList } from "../../api/research-category-functions";
import CookieConsent from "react-cookie-consent";
import ShowMoreText from "react-show-more-text";
import { FaLink, FaImage, FaRegFilePdf, FaVideo, FaEllipsisH, FaRegCalendarAlt } from "react-icons/fa";
import { GetNewsGalleryList } from "../../api/news-gallery-functions";
import FsLightbox from "fslightbox-react";
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Button from 'react-bootstrap/Button';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';

import { Autoplay, Navigation } from 'swiper/modules';

export default function Home1() {
  // const [toggler, setToggler] = useState(true);

  const navigate = useNavigate()
  const [newsOnHomePageList, setNewsOnHomePageList] = useState();
  const [getrcategoryList, setRcategoryList] = useState(null);


  const OnGetResearchCategorySuccess = (p_response) => {

    // set the list for looping
    setRcategoryList(p_response);
  }
  // things to be done when the page is loaded
  useEffect(() => {

    // make the object for getting the research category list
    let _obj = new Object();
    _obj.rcategory_status = 1;

    // call the function to get the research category
    GetResearchCategoryList(_obj, OnGetResearchCategorySuccess, navigate);

  }, []);

  useEffect(() => {
    $(window).on("scroll", function () {
      $(".navbar").offset().top > 10 ? $(".fixed-top").removeClass("bg-header-home") : $(".fixed-top").removeClass("bg-header-home")
    });

    // initiate the wow effect
    new WOW.WOW({
      live: false,
    }).init();

  }, [newsOnHomePageList, getrcategoryList]);

  var settings = {
    fade: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    autoplay: true,
    autoplaySpeed: 10000,
  };

  const [videos, setvideosToggler] = useState(false);

  const [news_gallery_toggler, setNewsGalleryToggler] = useState(false);
  const [news_gallery, setNewsGallery] = useState([]);
  const [news_gallery_titel, setNewsGalleryTitel] = useState([]);

  const [gallery_key, setGalleryKey] = useState("");
  const [lightbox_video, setLightBoxVideo] = useState([]);

  // success function when news gallery is retrived
  const OnGetNewsGallerySuccess = (p_response) => {


    // set the array in the constant
    setNewsGallery(p_response);

    setGalleryKey(Date.now());

    // using time out for the value to get saved in state 
    setTimeout(() => {
      // trigger the link click to display the news gallery popup
      document.getElementById("lnk_trigger_news_gallery_popup_home").click();
    }, 200);

  }


  // function to invoke video popup
  const InvokeVideoPopup = (e, p_news_video_url) => {

    e.preventDefault();

    let _arrVideos = [];
    _arrVideos.push(p_news_video_url);

    // set the path to the constant for displaying
    setLightBoxVideo(_arrVideos);

    // trigger the link click to display the popup
    document.getElementById("lnk_trigger_video_popup_home").click();

  };


  // function to invoke photo gallery
  const InvokePhotoGallery = (e, p_news_id) => {

    e.preventDefault();

    // Make an object for getting news gallery
    let _objNewsGallery = new Object();
    _objNewsGallery.gallery_status = 1;
    _objNewsGallery.gallery_news_id = p_news_id;

    // call the function to get the news gallery
    GetNewsGalleryList(_objNewsGallery, OnGetNewsGallerySuccess, navigate);

  };

  // success function when the list is retrived
  const OnGetNewsHomeSuccess = (p_response) => {
    // set the list for looping
    setNewsOnHomePageList(p_response);
  }

  // things to be done when the page is loaded
  useEffect(() => {

    // call the function to get the news
    GetNewsOnHomePageList(OnGetNewsHomeSuccess);

    $(".footer-common").addClass("d-none");

  }, []);

  return (
    <>
      <Header></Header>
      <Metatag title='Research and Development Programs for Green Energy, Environment and Sustainability'
        description='Research Programs for Green Energy, Battery Technologies, Bio Fuels and Other Green Energy Sustainability Programs offered by IIT Bombay Research Hub'
        keywords='Research Programs for Green Energy by IIT Bombay, Research Programs for Green Hydrogen by IIT Bombay, Research Programs for Battery Technologies by IIT Bombay, Research Programs for Bio Fuels by IIT Bombay, Research Programs for Sustainability by IIT Bombay, IIT Bombay Research Hub for Green Energy, IIT Bombay Research Hub for Sustainability'
      />

      {/* Slider Container */}
      <div className="visual-container">
        <VisualpanelBlock />
      </div>

      {/* Intro Panel */}
      <Container className="my-5 pt-md-4">

        <h1 className="text-primary fw-light text-center mb-4 wow fadeInUp">Driving research, education and collaboration in sustainability.</h1>
        <Col lg={10} className="m-auto text-center">
          <p>The Green Energy and Sustainability Research Hub (GESH IITB) is an innovative hub focused on
            tackling the global climate crisis. We promote research in green energy and sustainability, facilitating
            technological, management, and policy solutions. GESH IITB connects academic research with practical
            applications while supporting education and entrepreneurship in these vital areas. Our mission is to
            cultivate the next generation of leaders in green energy and sustainability, empowering them to address
            complex environmental challenges through creativity and innovation.</p>

          <Row className="row-cols-xl-6 row-cols-2 g-4 px-md-5 justify-content-center mt-4">
            <Col className="align-items-center overflow-hidden">
              <div className="mb-2">
                <div className="icon-100 bg-primary rounded-circle p-1 overflow-hidden mx-auto">
                  <Image className="img-fluid p-3 wow fadeInUp" loading="lazy" alt="Knowledge Creation and Sharing" title="Knowledge Creation and Sharing" width={100} height={100}
                    src={(global.variables.strDomainUrl + "images/icon-workshops.png")}
                    webp={(global.variables.strDomainUrl + "images/icon-workshops.webp")}
                  />
                </div>
              </div>
              <div>
                <p className="m-0 wow fadeInUp">Knowledge Creation <br />and Sharing</p>
              </div>
            </Col>
            <Col className="align-items-center overflow-hidden">
              <div className="mb-2">
                <div className="icon-100 bg-primary rounded-circle p-1 overflow-hidden mx-auto">
                  <Image className="img-fluid p-3 wow fadeInUp" loading="lazy" alt="Research Partnerships" title="Research Partnerships" width={100} height={100}
                    src={(global.variables.strDomainUrl + "images/icon-research.png")}
                    webp={(global.variables.strDomainUrl + "images/icon-research.webp")}
                  />
                </div>
              </div>
              <div>
                <p className="m-0 wow fadeInUp">Research <br />Partnerships</p>
              </div>
            </Col>
            <Col className="align-items-center overflow-hidden">
              <div className="mb-2">
                <div className="icon-100 bg-primary rounded-circle p-1 overflow-hidden mx-auto">
                  <Image className="img-fluid p-3 wow fadeInUp" loading="lazy" alt="Industry Trainings" title="Industry Trainings" width={100} height={100}
                    src={(global.variables.strDomainUrl + "images/icon-educational-programs.png")}
                    webp={(global.variables.strDomainUrl + "images/icon-educational-programs.webp")}
                  />
                </div>
              </div>
              <div>
                <p className="m-0 wow fadeInUp">Industry <br />Trainings</p>
              </div>
            </Col>
            <Col className="align-items-center overflow-hidden">
              <div className="mb-2">
                <div className="icon-100 bg-primary rounded-circle p-1 overflow-hidden mx-auto">
                  <Image className="img-fluid p-3 wow fadeInUp" loading="lazy" alt="Green Entrepreneurship" title="Green Entrepreneurship" width={100} height={100}
                    src={(global.variables.strDomainUrl + "images/icon-green-entrepreneurship.png")}
                    webp={(global.variables.strDomainUrl + "images/icon-green-entrepreneurship.webp")}
                  />
                </div>
              </div>
              <div>
                <p className="m-0 wow fadeInUp">Green <br />Entrepreneurship</p>
              </div>
            </Col>
            <Col className="align-items-center overflow-hidden">
              <div className="mb-2">
                <div className="icon-100 bg-primary rounded-circle p-1 overflow-hidden mx-auto">
                  <Image className="img-fluid p-3 wow fadeInUp" loading="lazy" alt="Advanced Lab Facilities" title="Advanced Lab Facilities" width={100} height={100}
                    src={(global.variables.strDomainUrl + "images/icon-laboratory.png")}
                    webp={(global.variables.strDomainUrl + "images/icon-laboratory.webp")}
                  />
                </div>
              </div>
              <div>
                <p className="m-0 wow fadeInUp">Advanced Lab <br />Facilities</p>
              </div>
            </Col>
          </Row>
        </Col>
      </Container>

      {/* News & Events Container */}
      <div className="bg-primay b-opacity-10 mt-md-5 bg-newssection">
        <Container className="bg-newssection-news py-md-5 py-3">
          <Row className="pt-4 pb-4 g-4">
            <Col lg={9} className="pe-md-4">
              <div className="d-flex flex-column h-100">
                <h2 className="h1 text-primary fw-light mb-4 wow fadeInUp">News</h2>
                <Row className="row-cols-1 row-cols-md-2 row-cols-xl-3 h-100 g-4">
                  {
                    newsOnHomePageList?.slice(0, 3).map((val, index) => {

                      return (
                        <Col key={index}>
                          <div className="border-bottom border-4 border-primary rounded-4 h-100 bg-white">
                            <div className="rounded-4 overflow-hidden position-relative border h-100 d-flex flex-column">
                              {
                                val.news_images !== null && val.news_images !== '' ?
                                  <img loading="lazy" src={global.variables.strApiUrl + "/news-main-image/" + val.news_images} alt={val.news_headline} title={val.news_headline} className="img-fluid w-100 rounded-4 wow fadeIn" width={325} height={200} />
                                  :
                                  <img loading="lazy" src={global.variables.strDomainUrl + "images/news/eve3.jpg"} alt="" title="" className="img-fluid w-100 rounded-4 wow fadeIn" width={325} height={200} />
                              }
                              <div className="px-3 py-3 d-flex flex-column align-items-start h-100">
                                <h5 className="mb-3 wow fadeInUp">{val.news_headline}</h5>
                                <Row className="row-cols-1 g-2 mb-4">
                                  <Col className="d-flex wow fadeInUp">
                                    <div className="icon-w30 flex-grow-0"><FaRegCalendarAlt className="icon-20" /></div>
                                    <div className="flex-grow-1">{val.news_date === val.news_to_date ? val.news_date : `${val.news_date} to ${val.news_to_date}`}</div>
                                  </Col>
                                </Row>
                                <div className="d-flex gap-2 mt-auto">
                                  {/* {/ link /} */}
                                  {
                                    val.news_link != null && val.news_link != "null" && val.news_link != "" ?
                                      <Link to={val.news_link} target="_blank" className="btn btn-primary rounded-pill Faicon px-3 py-1 ms-auto mt-auto wow fadeInUp"><FaLink /></Link>
                                      :
                                      null
                                  }

                                  {/* {/ Photo Gallery /} */}
                                  {
                                    parseInt(val.news_gallery_count) > 0 ?
                                      <a className="btn btn-primary rounded-pill Faicon px-3 py-1 ms-auto mt-auto wow fadeInUp" onClick={event => InvokePhotoGallery(event, val.news_id)}><FaImage /></a>
                                      :
                                      null
                                  }

                                  {/* {/ Document /} */}
                                  {
                                    val.news_document != null && val.news_document != "null" && val.news_document != "" ?
                                      <Link to={global.variables.strApiUrl + "/news-documents/" + val.news_document} target="_blank" className="btn btn-primary rounded-pill Faicon px-3 py-1 ms-auto mt-auto wow fadeInUp"><FaRegFilePdf /></Link>
                                      :
                                      null
                                  }

                                  {/* {/ Video /} */}
                                  {
                                    val.news_video_link != null && val.news_video_link != "null" && val.news_video_link != "" ?
                                      <a className="btn btn-primary rounded-pill Faicon px-3 py-1 ms-auto mt-auto wow fadeInUp" onClick={event => InvokeVideoPopup(event, val.news_video_link)}>    <FaVideo /></a>
                                      :
                                      null
                                  }
                                  {/* {
                                val.news_details != null && val.news_details != "" ?
                                  <Link to={global.variables.strDomainUrl + "news/" + val.news_type_pagename + "/" + val.news_pagename} className="btn btn-primary rounded-pill Faicon px-3 py-1 ms-auto mt-auto wow fadeInUp">
                                    <FaEllipsisH />
                                  </Link>
                                  :
                                  null

                              } */}
                                  {
                                    val.news_details != null && val.news_details != "" ?
                                      <Link to={global.variables.strDomainUrl + "news-details/" + val.news_pagename} className="btn btn-primary rounded-pill Faicon px-3 py-1 ms-auto mt-auto wow fadeInUp">
                                        <FaEllipsisH />
                                      </Link>
                                      :
                                      null

                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      )
                    })
                  }
                </Row>

                <div className="mt-auto">
                  <Link to="" className="btn btn-primary rounded-pill px-4 py-2 mt-4">View All</Link>
                </div>
              </div>
            </Col>
            <Col lg={3} className="ps-md-3">
              <div className="d-flex flex-column h-100 position-relative">
                <h2 className="h1 text-primary fw-light mb-4 wow fadeInUp">Events</h2>
                <div className="h-100">
                  <Swiper
                    cssMode={true}
                    navigation={true}
                    autoplay={{
                      delay: 5000,
                      disableOnInteraction: false,
                    }}
                    pagination={true}
                    mousewheel={true}
                    keyboard={true}
                    modules={[Autoplay, Navigation]}
                    className="mySwiper h-100 position-static"
                  >
                    <SwiperSlide>
                      <div className="border-bottom border-4 border-primary rounded-4 h-100 bg-white">
                        <div className="rounded-4 overflow-hidden position-relative border h-100 d-flex flex-column">
                          <img src={global.variables.strDomainUrl + "images/news/eve3.jpg"} alt="" title="" className="img-fluid rounded-4 wow fadeIn" />
                          <div className="px-3 py-3 d-flex flex-column align-items-start h-100">
                            <h5 className="mb-3 wow fadeInUp">Sustainable Chemical Manufacturing - Seminar</h5>
                            <Row className="row-cols-1 g-2">
                              <Col className="d-flex wow fadeInUp">
                                <div className="icon-w30 flex-grow-0"><FaRegCalendarAlt className="icon-20" /></div>
                                <div className="flex-grow-1">27-May-2024</div>
                              </Col>
                            </Row>

                            <div className="d-flex gap-2 mt-auto">
                              {/* {/ link /} */}
                              {
                                <Link to="" target="_blank" className="btn btn-primary rounded-pill Faicon px-3 py-1 mt-auto ms-auto wow fadeInUp"><FaLink /></Link>
                              }

                              {/* {/ Photo Gallery /} */}
                              {
                                <a className="btn btn-primary rounded-pill Faicon px-3 py-1 mt-auto ms-auto wow fadeInUp" href=""><FaImage /></a>

                              }

                              {/* {/ Document /} */}
                              {
                                <Link to="" target="_blank" className="btn btn-primary rounded-pill Faicon px-3 py-1 ms-auto mt-auto wow fadeInUp"><FaRegFilePdf /></Link>
                              }
                              {/* {/ Video /} */}
                              {
                                <a className="btn btn-primary rounded-pill Faicon px-3 py-1 ms-auto mt-auto wow fadeInUp" href=""><FaVideo /></a>
                              }
                              <Link to="" className="btn btn-primary rounded-pill Faicon px-3 py-1 ms-auto mt-auto wow fadeInUp">
                                <FaEllipsisH />
                              </Link>
                            </div>
                          </div>

                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="border-bottom border-4 border-primary rounded-4 h-100 bg-white">
                        <div className="rounded-4 overflow-hidden position-relative border h-100 d-flex flex-column">
                          <img src={global.variables.strDomainUrl + "images/news/eve2.jpg"} alt="" title="" className="img-fluid rounded-4 wow fadeIn" />
                          <div className="px-3 py-3 d-flex flex-column align-items-start h-100">
                            <h5 className="mb-3 wow fadeInUp">Sustainable Chemical Manufacturing - Seminar</h5>
                            <Row className="row-cols-1 g-2">
                              <Col className="d-flex wow fadeInUp">
                                <div className="icon-w30 flex-grow-0"><FaRegCalendarAlt className="icon-20" /></div>
                                <div className="flex-grow-1">27-May-2024</div>
                              </Col>
                            </Row>

                            <div className="d-flex gap-2 mt-auto">
                              {/* {/ link /} */}
                              {
                                <Link to="" target="_blank" className="btn btn-primary rounded-pill Faicon px-3 py-1 mt-auto ms-auto wow fadeInUp"><FaLink /></Link>
                              }

                              {/* {/ Photo Gallery /} */}
                              {
                                <a className="btn btn-primary rounded-pill Faicon px-3 py-1 mt-auto ms-auto wow fadeInUp" href=""><FaImage /></a>

                              }

                              {/* {/ Document /} */}
                              {
                                <Link to="" target="_blank" className="btn btn-primary rounded-pill Faicon px-3 py-1 ms-auto mt-auto wow fadeInUp"><FaRegFilePdf /></Link>
                              }
                              {/* {/ Video /} */}
                              {
                                <a className="btn btn-primary rounded-pill Faicon px-3 py-1 ms-auto mt-auto wow fadeInUp" href=""><FaVideo /></a>
                              }
                              <Link to="" className="btn btn-primary rounded-pill Faicon px-3 py-1 ms-auto mt-auto wow fadeInUp">
                                <FaEllipsisH />
                              </Link>
                            </div>
                          </div>

                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="border-bottom border-4 border-primary rounded-4 h-100 bg-white">
                        <div className="rounded-4 overflow-hidden position-relative border h-100 d-flex flex-column">
                          <img src={global.variables.strDomainUrl + "images/news/eve1.jpg"} alt="" title="" className="img-fluid rounded-4 wow fadeIn" />
                          <div className="px-3 py-3 d-flex flex-column align-items-start h-100">
                            <h5 className="mb-3 wow fadeInUp">Sustainable Chemical Manufacturing - Seminar</h5>
                            <Row className="row-cols-1 g-2">
                              <Col className="d-flex wow fadeInUp">
                                <div className="icon-w30 flex-grow-0"><FaRegCalendarAlt className="icon-20" /></div>
                                <div className="flex-grow-1">27-May-2024</div>
                              </Col>
                            </Row>

                            <div className="d-flex gap-2 mt-auto">
                              {/* {/ link /} */}
                              {
                                <Link to="" target="_blank" className="btn btn-primary rounded-pill Faicon px-3 py-1 mt-auto ms-auto wow fadeInUp"><FaLink /></Link>
                              }

                              {/* {/ Photo Gallery /} */}
                              {
                                <a className="btn btn-primary rounded-pill Faicon px-3 py-1 mt-auto ms-auto wow fadeInUp" href=""><FaImage /></a>

                              }

                              {/* {/ Document /} */}
                              {
                                <Link to="" target="_blank" className="btn btn-primary rounded-pill Faicon px-3 py-1 ms-auto mt-auto wow fadeInUp"><FaRegFilePdf /></Link>
                              }
                              {/* {/ Video /} */}
                              {
                                <a className="btn btn-primary rounded-pill Faicon px-3 py-1 ms-auto mt-auto wow fadeInUp" href=""><FaVideo /></a>
                              }
                              <Link to="" className="btn btn-primary rounded-pill Faicon px-3 py-1 ms-auto mt-auto wow fadeInUp">
                                <FaEllipsisH />
                              </Link>
                            </div>
                          </div>

                        </div>
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </div>
                <div className="mt-auto">
                  <Link to="" className="btn btn-primary rounded-pill px-4 py-2 mt-4">View All</Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Intro Panel */}
      <Container className="my-5">
        <Row className="g-0 my-5">
          <Col md={3} xs={7} className="py-md-5 container-start z-3 d-md-block d-none">
            <div className="pe-xl-5">
              <div className="border-bottom border-4 border-primary rounded-4 overflow-hidden wow fadeInUp">
                <div className="bg-primary bg-opacity-10 rounded-4 border h-100 wow fadeInUp">
                  <img src={global.variables.strDomainUrl + "images/pic-shireesh-kedare.jpg"} className="img-fluid" />
                </div>
              </div>
            </div>
          </Col>
          <Col md={10} lg={10}>
            <div className="border rounded-4 p-md-5 p-3">
              <div className="col-md-11 ms-auto">
                <div className="col-7 mb-4  d-md-none d-block border-bottom border-4 border-primary rounded-4 overflow-hidden wow fadeInUp">
                  <div className="bg-primary bg-opacity-10 rounded-4 border h-100 wow fadeInUp">
                    <img src={global.variables.strDomainUrl + "images/pic-shireesh-kedare.jpg"} className="img-fluid" />
                  </div>
                </div>
                <h2 className="h1 fw-light text-primary wow fadeInUp">Director's Note</h2>
                <h6 className="mb-4 wow fadeInUp">Prof. Shireesh Kedare, Director, Indian Institute of Technology Bombay</h6>
                <p className="wow fadeInUp">Green energy and sustainability are the cornerstones of progress in the 21st century. As the world grapples with climate change and environmental degradation, the need for innovative solutions has never been more critical. Today, India stands at a pivotal point in its journey towards sustainable development. This vision aligns with the India Green Energy Mission, which aims to create a robust ecosystem for renewable energy and sustainability ecosystem and net zero emission target of 2070. IIT Bombay is a key player in this mission, contributing from policy development to practical implementation.</p>
                <p className="wow fadeInUp">IIT Bombay established the Research Hub for Green Energy and Sustainability Hub (GESH IITB) to integrate key resources and talent across all disciplines related to green energy and sustainability. GESH IITB aims to build a platform that propels India's ambitions towards global leadership in these critical areas. The vision of GESH IITB is to support the transition to green energy (environment-friendly and sustainable energy sources) and sustainability through research, education and capacity building, with academia, industry, regulators and other stakeholders. GESH commitment extends to advancing sustainability, climate services, solutions, and related domains.</p>
                <p className="m-0 wow fadeInUp">With our extensive network of industry partners, faculty, students, infrastructure, alumni with world-class experience, and policymakers, GESH IITB is poised to deliver transformational changes in the way research, training, incubation, management and policy development are conducted in the field of green energy and sustainability.</p>
              </div>
            </div>
          </Col>

        </Row>
      </Container >

      {/* Quick Form */}
      <div className="bg-primary d-none" >
        <Container className="py-md-5">
          <h2 className="h1 text-white fw-light text-center mb-4 wow fadeInUp">Contact Us</h2>
          <Row className=" justify-content-center gx-3">
            <Col md={3}>
              <FloatingLabel controlId="Name" type="text" label="Name" className="border rounded-2">
                <Form.Control className="shadow-none border-0" placeholder="Name" />
              </FloatingLabel>
            </Col>
            <Col md={3}>
              <FloatingLabel controlId="Email" type="text" label="Email" className="border rounded-2">
                <Form.Control className="shadow-none border-0" placeholder="Email" />
              </FloatingLabel>
            </Col>
            <Col md={4}>
              <FloatingLabel controlId="Comment" type="text" label="Comment" className="border rounded-2">
                <Form.Control className="shadow-none border-0" placeholder="Comment" />
              </FloatingLabel>
            </Col>
            <Col md={2}>
              <FloatingLabel controlId="Captcha" type="text" label="Enter Captcha" className="border rounded-2">
                <Form.Control className="shadow-none border-0" placeholder="Name" />
              </FloatingLabel>
            </Col>
            <Col md={12} className="text-center pt-3">
              <Button type="submit" className="rounded-pill h-100 px-5 py-3">Submit</Button>
            </Col>
          </Row>
        </Container>
      </div>


      <Roadblock_Popup />
      <CookieConsent
        location="bottom"
        buttonText="GOT IT"
        buttonClasses="btn rounded-0 text-white px-3"
        containerClasses="cookie-wrapper"
        contentClasses="cookie-content"
        cookieName="cookiesAccepted"
        expires={150}
        className="cookie-popup-inner"
      >We use cookies to ensure that we give you the best experience and to analyze our website traffic and performance; we never collect any personal data.</CookieConsent>

      {/* {/ Video popup /}
        {/ this link will open the video popup which will be hidden because the button from the grid is causing issue /} */}
      <a onClick={(e) => {
        e.preventDefault();
        setvideosToggler(!videos);
      }}
        className="d-none"
        id="lnk_trigger_video_popup_home"></a>

      <FsLightbox
        toggler={videos}
        sources={lightbox_video}
      />

      {/* {/ News Gallery popup /}
      {/ this link will open the news gallery popup which will be hidden because the button from the grid is causing issue /} */}
      <a onClick={(e) => {
        e.preventDefault();
        setNewsGalleryToggler(!news_gallery_toggler);
      }}
        className="d-none"
        id="lnk_trigger_news_gallery_popup_home"></a>

      <FsLightbox
        toggler={news_gallery_toggler}
        sources={news_gallery?.map(item => {
          return (
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center'
            }} key={item.gallery_id} className="fsDiv">
              <img className="img-fluid" src={`${globalVariables.variables.strApiUrl}/news-gallery-image/${item.gallery_pic}`} alt={item.gallery_title} style={{ objectFit: 'contain' }} />
              <p style={{ textAlign: 'center', color: 'white', fontSize: '16px' }}>{item.gallery_title}</p>
            </div>
          )
        })}
        key={gallery_key}
      />
      <Footer1></Footer1>
    </>

  );
}