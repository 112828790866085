import React, { useState, useEffect } from "react";
import global from "../../global-variables";
import Header from "../layout/header";
import { Link, useNavigate, useParams } from "react-router-dom";
import $ from "jquery";
import WOW from "wowjs";
import { FaLink, FaImage, FaRegFilePdf, FaVideo, FaEllipsisH, FaRegCalendarAlt } from "react-icons/fa";
import Metatag from '../../hooks/Metatag'
import { GetEventList, GetEventGalleryList, GetEventTypeDistinctYearList } from "../../api/event-function";
import { Container, Row, Col } from "reactstrap";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import FsLightbox from "fslightbox-react";
import Form from 'react-bootstrap/Form';
import globalVariables from "../../global-variables";
import axios from "axios";

export default function Events() {
  // //Getting id from url
  // let { etype_name } = useParams();
  // let qs_etype_name = etype_name

  const navigate = useNavigate();
  const [event, setEventList] = useState(null);

  const [eventtypeyear, setEventTypeYearList] = useState([]);
  const [event_gallery_toggler, setEventGalleryToggler] = useState(false);
  const [event_gallery, setEventGallery] = useState([]);

  const [eventTypeId, setEventTypeId] = useState(null);

  const [gallery_key, setGalleryKey] = useState("");

  const [eventType, setEventType] = useState([]);
  const [eventYear, setEventYear] = useState();

  const [lightbox_video, setLightBoxVideo] = useState([]);
  const [videos, setvideosToggler] = useState(false);

  useEffect(() => {
    
    axios.post(global.variables.strApiUrl + "/eventtypeApi/getEventTypeListHavingEventList")
      .then((response_list) => {
        // pass the result in the success function of the respective page
        setEventType(response_list.data[0]);
      });

  }, [])

  // function to invoke video popup
  const InvokeVideoPopup = (e, p_event_video_url) => {

    e.preventDefault();

    let _arrVideos = [];
    _arrVideos.push(p_event_video_url);

    // set the path to the constant for displaying
    setLightBoxVideo(_arrVideos);

    // trigger the link click to display the popup
    document.getElementById("lnk_trigger_video_popup_home").click();

  };

  // success function when the list is retrived
  const OnGetEventSuccess = (p_response) => {

    // set the list for looping
    setEventList(p_response);

  }

  const OnGetEventTypeYearSuccess = (p_response) => {

    // set the list for looping
    setEventTypeYearList(p_response);

    // get the current event dropdown list
    let _ddlYear = document.getElementById("ddlEventYear");

    // bind the on change event to the dropdown list
    _ddlYear.onchange = (e) => {

      setEventYear(e.target.value)
    };
    // call the onchange event for loading the event for the first time
    // Create a new 'change' event
    let event = new Event('change');

    // Dispatch it.
    _ddlYear.dispatchEvent(event);

  }

  useEffect(() => {

    let _ddlType = document.getElementById("ddlEventType");

    // bind the on change event to the dropdown list
    _ddlType.onchange = (e) => {

      //call the function to get the event
      // make the object for getting the event list
      let objEvent = new Object();
      objEvent.event_status = 1;
      objEvent.etype_status = 1;
      objEvent.event_etype_id = e.target.value !== "All" ? e.target.value : null;
      objEvent.selected_year = eventYear;

      setEventTypeId(e.target.value !== "All" ? e.target.value : null)
      // // call the function to get the event list
      GetEventList(objEvent, OnGetEventSuccess, navigate);
    }

    // Create a new 'change' event
    let event = new Event('change');
    _ddlType.dispatchEvent(event);

  }, [eventYear])

  // success function when event gallery is retrived
  const OnGetEventGallerySuccess = (p_response) => {

    // set the array in the constant
    setEventGallery(p_response);

    setGalleryKey(Date.now());

    // using time out for the value to get saved in state 
    setTimeout(() => {
      // trigger the link click to display the event gallery popup
      document.getElementById("lnk_trigger_event_gallery_popup_home").click();
    }, 200);

  }

  // function to invoke photo gallery
  const InvokePhotoGallery = (e, p_event_id) => {

    e.preventDefault();

    // Make an object for getting event gallery
    let _objEventGallery = new Object();
    _objEventGallery.egallery_status = 1;
    _objEventGallery.egallery_event_id = p_event_id;

    // call the function to get the event gallery
    GetEventGalleryList(_objEventGallery, OnGetEventGallerySuccess, navigate);

  };

  useEffect(() => {

    // remove bg-header-home 
    $(window).on("scroll", function () {
      $(".navbar").offset().top > 10 ? $(".fixed-top").removeClass("bg-header-home") : $(".fixed-top").removeClass("bg-header-home")
    });

    // initiate the wow effect
    new WOW.WOW({
      live: false,
    }).init();

  }, [event]);


  // things to be done when the page is loaded
  useEffect(() => {

    //make the object for getting the event type year list
    let _objEventTypeYear = new Object();
    _objEventTypeYear.event_status = 1
    _objEventTypeYear.event_etype_id = eventTypeId

    // call the function to get the event type year list
    GetEventTypeDistinctYearList(_objEventTypeYear, OnGetEventTypeYearSuccess, navigate)

  }, [eventTypeId, event]);


  return (
    <>
      <Header></Header>

      {/* MetaTag */}
      <Metatag title='Green Energy Events at IIT Bombay | Sustainable Energy Events at IIT Bombay'
        description='Green Energy Events, Sustainable Energy Events at IIT Bombay'
        keywords="Green Energy Events at IIT Bombay, Sustainable Energy Events at IIT Bombay, Sustainable Energy Events at IIT Bombay, Sustainable Energy Events at IIT Bombay"
      />


      {/* {/ Event Visual Panel /} */}
      <Container fluid className="bg-visual-inside p-0" >
        <div className="visual-inside-big visual-container-inside">
          <Container>
            <Row className="g-md-0 rounded-4">
              <Col md={6} className="order-2 order-md-1">
                <div className="d-flex align-items-end h-100 pb-5">
                  <div className="d-flex m-0 align-items-center py-xl-3 p-2 text-white wow fadeInUp">
                    <h1 className="fw-light text-white d-inline-block m-0 align-self-center">
                      Events -
                    </h1>
                    <div className="ms-md-3 ms-2">
                      {
                        <Form.Select aria-label="Default select example" className="shadow-none" id="ddlEventType">
                          <option value="All">All Event Type</option>
                          {
                            eventType.map((val) => {
                              return (

                                <option value={val.etype_id} key={val.etype_id}>{val.etype_name}</option>
                              )
                            })
                          }
                        </Form.Select>
                      }
                    </div>
                    <div className="ms-md-3 ms-2">
                      {
                        eventtypeyear.length > 0 ?
                          <Form.Select aria-label="Default select example" className="shadow-none" id="ddlEventYear">
                            {
                              eventtypeyear.map((val) => {
                                return (

                                  <option value={val.event_year} key={val.event_year}>{val.event_year}</option>
                                )
                              })
                            }
                          </Form.Select>
                          :
                          <Form.Select aria-label="Default select example" className="shadow-none" id="ddlEventYear">
                            <option value={new Date().getFullYear()}>{new Date().getFullYear()}</option>
                          </Form.Select>
                      }
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={6} className="order-1 order-md-2 ms-auto">
                <div className="border-bottom border-5 border-primary rounded-4">
                  <img src={global.variables.strDomainUrl + "images/visual-events.jpg"} className="img-fluid rounded-4" alt="" title="" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>

      {/* {/ Event Container /} */}
      {
        event != null ?

          event.length > 0 ?
            <Container className="mb-5 pt-5 news-details ">
              <Row className="row-cols-1 row-cols-md-1 row-cols-lg-1 row-cols-xl-4 g-4 g-lg-5 g-xl-5 pt-5 grid">
                {
                  event.map((val, index) => {
                    return (
                      <Col key={index}>
                        <div className="border d-flex flex-column h-100 rounded-4 position-relative">

                          <div md={12} lg={12} className="p-0">
                          <Link to={global.variables.strDomainUrl + "events/" + val.event_pagename} className="text-decoration-none">
                            {
                              val.event_thumbnail != null && val.event_thumbnail != "" ?
                                <>
                                  <div className="event-category-eve">{val.etype_name}</div>
                                  <img src={global.variables.strApiUrl + "/event-thumbnail" + "/" + val.event_thumbnail} alt={val.event_name} title={val.event_name} className="img-fluid rounded-4 w-100 mb-md-0 mb-3 border" />
                                </>
                                :
                                <>
                                <div className="event-category-eve">{val.etype_name}</div>
                                  <img src={global.variables.strDomainUrl + "images/event_default_thumbnail.png"} alt={val.event_name} title={val.event_name} className="img-fluid rounded-4 w-100 mb-md-0 mb-3 border" />
                                </>
                            }
                             </Link>
                          </div>

                          <div md={12} lg={12} className="d-flex flex-column align-items-start h-100 p-3">
                            <h5 className="text-dark wow fadeInUp">
                              {/* {/ head line /} */}
                              {val.event_name}
                            </h5>
                            <Row className="row-cols-1 g-2">
                              <Col className="d-flex wow fadeInUp mt-3">
                                <div className="icon-w30 flex-grow-0"><FaRegCalendarAlt className="icon-20" /></div>
                                {
                                  val.event_from_date != val.event_to_date ?
                                    <>
                                      {
                                        val.from_mon != val.to_mon ?
                                          <>
                                            {` ${val.from_day}-${val.from_mon}-${val.from_year} to ${val.to_day}-${val.to_mon}-${val.to_year}`}

                                          </>
                                          :
                                          <>
                                            {` ${val.from_day}-${val.from_mon}-${val.from_year} to ${val.to_day}-${val.to_mon}-${val.to_year}`}
                                          </>
                                      }
                                    </>
                                    :
                                    // when from and to date same 
                                    <>
                                      {
                                        //when from and to date's month is same
                                        val.from_mon != val.to_mon ?
                                          <>
                                            {` ${val.from_day}-${val.from_mon}-${val.from_year} to ${val.to_day}-${val.to_mon}-${val.to_year}`}
                                          </>
                                          :
                                          <>
                                            {` ${val.from_day}-${val.from_mon}-${val.from_year}`}
                                          </>
                                      }
                                    </>
                                }
                              </Col>
                            </Row>

                            <p className="wow fadeInUp">
                              {val.event_venue != null && val.event_venue != "" && val.event_venue != "null" ? <><br /> Venue: {val.event_venue}</> : null}
                              {val.event_time != null && val.event_time != "" && val.event_time != "null" ? <><br /> Time: {val.event_time}</> : null} <br /></p>
                            <p className="wow fadeInUp d-none">
                              {/* {/ Details /} */}
                              {
                                val.event_desc != null && val.event_desc != "null" && val.event_desc != "" ?
                                  <div className="mb-3 wow fadeInUp projects"><span dangerouslySetInnerHTML={{ __html: val.event_desc }}></span></div>
                                  : null
                              }
                            </p>


                            <div className="d-flex gap-2 mt-auto">
                              {/* {/ link /} */}
                              {
                                val.event_url != null && val.event_url != "null" && val.event_url != "" ?
                                  <Link to={val.event_url} target="_blank" className="btn btn-primary rounded-pill Faicon px-3 py-1 ms-auto mt-auto wow fadeInUp"><FaLink /></Link>
                                  :
                                  null
                              }

                              {/* {/ Photo Gallery /} */}
                              {
                                parseInt(val.event_gallery_count) > 0 ?
                                  <a className="btn btn-primary rounded-pill Faicon px-3 py-1 ms-auto mt-auto wow fadeInUp" onClick={event => InvokePhotoGallery(event, val.event_id)}><FaImage /></a>
                                  :
                                  null
                              }

                              {/* {/ Document /} */}
                              {
                                val.event_brochure != null && val.event_brochure != "null" && val.event_brochure != "" ?
                                  <Link to={global.variables.strApiUrl + "/event-brochure/" + val.event_brochure} target="_blank" className="btn btn-primary rounded-pill Faicon px-3 py-1 ms-auto mt-auto wow fadeInUp"><FaRegFilePdf /></Link>
                                  :
                                  null
                              }
                              {/* {/ Video /} */}
                              {
                                      val.event_video_link != null && val.event_video_link != "null" && val.event_video_link != "" ?
                                        <a className="btn btn-primary rounded-pill Faicon px-3 py-1 ms-auto mt-auto wow fadeInUp" onClick={event => InvokeVideoPopup(event, val.event_video_link)}>    <FaVideo /></a>
                                        :
                                        null
                                    }
                              <Link to={global.variables.strDomainUrl + "events/" + val.event_pagename} className="btn btn-primary rounded-pill Faicon px-3 py-1 ms-auto mt-auto wow fadeInUp">
                                <FaEllipsisH />
                              </Link>

                            </div>

                          </div>

                        </div>
                      </Col>

                    )
                  })
                }

              </Row>
            </Container>
            :
            <Container className="mb-5 pt-5 news-details">
              <Row className="">
                {/* <span className="text-center">No Records Found</span> */}
              </Row>
            </Container>
          :
          <Container className="mb-5 pt-5 news-details">
            <Row >
              <span className="text-center">Loading...</span>
            </Row>
          </Container>
      }

      {/* {/ Video popup /}
        {/ this link will open the video popup which will be hidden because the button from the grid is causing issue /} */}
      <a onClick={(e) => {
        e.preventDefault();
        setvideosToggler(!videos);
      }}
        className="d-none"
        id="lnk_trigger_video_popup_home"></a>

      <FsLightbox
        toggler={videos}
        sources={lightbox_video}
      />
      
      {/* {/ event Gallery popup /}
      {/ this link will open the event gallery popup which will be hidden because the button from the grid is causing issue /} */}
      <a onClick={(e) => {
        e.preventDefault();
        setEventGalleryToggler(!event_gallery_toggler);
      }}
        className="d-none"
        id="lnk_trigger_event_gallery_popup_home"></a>

      <FsLightbox
        toggler={event_gallery_toggler}
        sources={event_gallery?.map(item => {
					return(
						<div style={{
							display: 'flex', 
							flexDirection: 'column', 
							alignItems: 'center', 
							justifyContent: 'center'
						}} key={item.egallery_id} className="fsDiv">
						<img className="img-fluid" src={`${globalVariables.variables.strApiUrl}/events-gallery-image/${item.egallery_pic}`} alt={item.egallery_title} style={{ objectFit: 'contain' }} />
						<p style={{ textAlign: 'center', color: 'white', fontSize: '16px' }}>{item.egallery_title}</p>
						</div>
					)
				})}
        key={gallery_key}
        
      />

      {/* {/ Breadcrumb /} */}
      <Breadcrumb className="breadcrumb-panel mt-auto">
        <Breadcrumb.Item href={globalVariables.variables.strDomainUrl}>Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Events</Breadcrumb.Item>
      </Breadcrumb>

    </>
  );
}