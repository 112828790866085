import React, { useState, useEffect, useRef } from 'react';
import global from "../../global-variables";
import { Link, useLocation } from "react-router-dom";
import { FiSearch } from "react-icons/fi";
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import TrackingCode from '../../hooks/TrackingCode';
import WOW from 'wowjs';
import { Container, Row, Col } from 'reactstrap';
import Image from 'react-image-webp';
import axios from 'axios';
import globalVariables from '../../global-variables';
import { HashLink } from "react-router-hash-link";
import $ from "jquery";

export const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth' // Optional smooth scrolling
    });
};

export default function Header(props) {

    //navbar active
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    //navbar active

    const ref = useRef(null);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [fullscreen, setFullscreen] = useState(true);
    // const pathname = useLocation();

    const scrollWithOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -134;
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    }

    useEffect(() => {
        // Go to Top OR Scroll to top
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation (comment behavior if u want to animate)
        });
    }, [pathname]);

    useEffect(() => {
        // initiate the wow effect
        new WOW.WOW({
            live: false
        }).init();

        $(document).ready(function () {
            if ($(window).width() >= 1198) { $(".dropdown").hover(function () { $('.dropdown-menu', this).not('.in .dropdown-menu').stop(true, true).slideDown(); $(this).toggleClass('open'); $('.offcanvas-overlay').eq(0).addClass('activeoverlay'); $('.dropdown-menu').addClass('openmenu'); }, function () { $('.dropdown-menu', this).not('.in .dropdown-menu').stop(true, true).slideUp("400"); $(this).toggleClass('open'); $('.offcanvas-overlay').eq(0).removeClass('activeoverlay'); }); }
        });
        $(document).ready(function () {
            if ($(window).width() >= 1198) { $(".dropend").hover(function () { $('.dropdown-menu', this).not('.in .dropdown-menu').stop(true, true).slideDown();  }); }
        });
    }, []);

    const [peopleCategory, setPeopleCategoryList] = useState([]);
    const [newsTypeList, setNewsTypeList] = useState([]);

    useEffect(() => {
        let _obj = new Object();
        _obj.pcategory_status = 1;
        setTimeout(() => {

            axios.post(global.variables.strApiUrl + "/peoplecategoryApi/getcategoryHavingPeopleList", _obj)
                .then((response_list) => {
                    // pass the result in the success function of the respective page
                    setPeopleCategoryList(response_list.data[0]);
                });
        }, 300);

    }, [])

    //serach  function
    function url_generator(url) {
        var url_generated = "";
        // For local host
        if (window.location.href.indexOf('localhost') != -1) {
            url_generated = url;
        }

        //    // For my machine Server
        else if (window.location.href.indexOf('192.168.2.202') != -1) {
            // url_generated = "/a/amnsinternationalschool" + url;
            url_generated = url;
        }

        // For Live server
        else {
            url_generated = url;
        }
        // alert(url_generated);
        return url_generated;
    }

    useEffect(() => {

        $("#btnsearchSite").on("click", function (e) {

            $("#searchSiteerr").html("");
            if ($("#txtSearchSite").val() == "") {
                $("#searchSiteerr").html("Please Enter Search String");
                return;
            }
            else {
                window.location.href = (global.variables.strDomainUrl + "search-result/" + $("#txtSearchSite").val());
            }
        });


        $('#txtSearchSite').keypress(function (e) {
            var key = e.which;
            if (key == 13)  // the enter key code
            {
                if ($("#txtSearchSite").val() == "") {
                    $("#searchSiteerr").html("Please Enter Search String");
                    return;
                }
                else {
                    <Link to=""></Link>
                    window.location.href = url_generator(global.variables.strDomainUrl + "search-result/" + $("#txtSearchSite").val());
                }
                return false;
            }
        });

        $('#focusOnInput').on('click', function () {
            setTimeout(() => {
                ref.current.focus();

            }, 500);
        })

    })


    let conversion_code = "";

    if (props.conversion_code != undefined) {
        conversion_code = 1;
    }
    else {
        conversion_code = 2;
    }

    //variable to close left navbar on link click
    const [expanded, setExpanded] = useState(false);

    return (
        <>

            <Navbar expanded={expanded} className={props.main_class + " fixed-top p-0 bg-white"} collapseonselect expand="xl">
                <Container className='d-block'>
                    <Row className="d-flex justify-content-between ">
                        <Col xs={9} md={6} lg={6} xl={5} className='pe-0'>
                            <div className='logo'>
                                <Link to="/" onClick={scrollToTop} className="position-relative overflow-hidden">
                                    <h1 className='mb-0'>
                                        <Image className="img-fluid my-2 my-md-3 animated fadeInUp" loading="lazy" alt="IIT Bombay Research Hub for Green Energy and Sustainability" title="IIT Bombay Research Hub for Green Energy and Sustainability" width={200} height={86}
                                            src={(global.variables.strDomainUrl + "images/logo.png")}
                                            webp={(global.variables.strDomainUrl + "images/logo.webp")}
                                        />
                                    </h1>
                                </Link>
                            </div>
                        </Col>
                        <Col xs={3} md={6} lg={6} xl={7} className='ps-xxl-5 d-flex flex-column justify-content-center'>
                            <div className="d-flex justify-content-end align-items-center z-2">

                                <div>
                                    <ul className="navbar-nav fw-normal top-nav m-0">
                                        <li className={`nav-item animated fadeInUp d-xl-block d-none`}><Link to={globalVariables.variables.strDomainUrl + 'news-events'} onClick={scrollToTop} className="bg-transparent nav-link">News</Link></li>
                                        <li className={`nav-item animated fadeInUp d-xl-block d-none`}><Link to={globalVariables.variables.strDomainUrl + 'events'} onClick={scrollToTop} className="bg-transparent nav-link">Events</Link></li>
                                        <li className={`nav-item animated fadeInUp d-xl-block d-none`}><Link to={globalVariables.variables.strDomainUrl + 'blog'} onClick={scrollToTop} className="bg-transparent nav-link">Knowledge Hub</Link></li>
                                        {/* <li className="nav-item d-xl-block d-none animated fadeInUp"><Link to="/careers" onClick={scrollToTop} className="bg-transparent nav-link">Careers</Link></li>
                                        <li className="nav-item d-xl-block d-none animated fadeInUp"><HashLink to="#contact" className="bg-transparent nav-link" scroll={el => scrollWithOffset(el)}>Contact Us</HashLink></li> */}
                                        <li className="nav-item d-xl-block d-none animated fadeInUp"><Link className="bg-transparent nav-link icon-search" id='focusOnInput' onClick={handleShow}><FiSearch /></Link></li>
                                    </ul>
                                </div>
                                <Navbar.Toggle onClick={() => setExpanded(expanded ? false : "expanded")} className="offcanvas-toggle shadow-none px-1 animated fadeInUp" aria-controls={'offcanvas-menu'}>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                </Navbar.Toggle>
                            </div>
                            <Navbar.Offcanvas id={'offcanvas-menu'} aria-labelledby={'offcanvas-menu'} placement="end" responsive="xl" className="px-md-0 px-3 mt-auto">
                                <Offcanvas.Header closeButton onClick={() => setExpanded(false)}>
                                    <Offcanvas.Title>
                                    </Offcanvas.Title>
                                </Offcanvas.Header>
                                <ul className="navbar-nav mt-xl-auto justify-content-between top-bt-nav">
                                    <li className={splitLocation[1] === "about" ? "active" : ""}>
                                        <NavDropdown title="About" id="basic-nav-dropdown" className="animated fadeInUp" renderMenuOnMount={true}>
                                            <ul className='px-md-0 px-3 py-xl-2 bg-white rounded-bottom-3'>
                                                <NavDropdown.Item as="li"><Link to="/aboutus">About GESH</Link></NavDropdown.Item>
                                                {/* <NavDropdown.Item as="li"><Link to="">People</Link></NavDropdown.Item> */}
                                                <NavDropdown title="People"  className="dropend dropdown-item bg-white" renderMenuOnMount={true}>
                                                    <ul className='px-md-0 px-0 py-xl-2 bg-white rounded-bottom-3 dropdown-2'>
                                                        <NavDropdown.Item as="li"><Link to="#">Professor-in-Charge</Link></NavDropdown.Item>
                                                        <NavDropdown.Item as="li"><Link to="#">Advisory Board</Link></NavDropdown.Item>
                                                        <NavDropdown.Item as="li"><Link to="#">Faculty Core Team</Link></NavDropdown.Item>
                                                        <NavDropdown.Item as="li"><Link to="#">GESH Operations Team</Link></NavDropdown.Item>
                                                    </ul>
                                                </NavDropdown>
                                                {/* <NavDropdown.Item as="li"><Link to="">Centers</Link></NavDropdown.Item>
                                                <NavDropdown.Item as="li"><Link to="">Student Bodies</Link></NavDropdown.Item> */}
                                            </ul>
                                        </NavDropdown>
                                    </li>
                                    <li className={splitLocation[1] === "education" ? "active" : ""}>
                                        <NavDropdown title="Education" id="basic-nav-dropdown" className="animated fadeInUp" renderMenuOnMount={true}>
                                            <ul className='px-md-0 px-3 py-xl-2 bg-white rounded-bottom-3'>
                                                <NavDropdown.Item as="li"><Link to="#">IITB Courses</Link></NavDropdown.Item>
                                                <NavDropdown.Item as="li"><Link to="#">Industry Training Programs</Link></NavDropdown.Item>
                                                <NavDropdown.Item as="li"><Link to="#">EPGD</Link></NavDropdown.Item>
                                                <NavDropdown.Item as="li"><Link to="#">Online Courses</Link></NavDropdown.Item>
                                            </ul>
                                        </NavDropdown>
                                    </li>
                                    <li className={splitLocation[1] === "research" ? "active" : ""}>
                                        <NavDropdown title="Research" className="animated fadeInUp" renderMenuOnMount={true}>
                                            <ul className='px-md-0 px-3 py-xl-2 bg-white rounded-bottom-3'>
                                                <NavDropdown.Item as="li"><Link to="#">Overview</Link></NavDropdown.Item>
                                                <NavDropdown.Item as="li"><Link to="#">Focus Areas</Link></NavDropdown.Item>
                                                <NavDropdown.Item as="li"><Link to="#">GESH Projects</Link></NavDropdown.Item>
                                                <NavDropdown.Item as="li"><Link to="#">Research @ IITB</Link></NavDropdown.Item>
                                            </ul>
                                        </NavDropdown>
                                    </li>
                                    <li className={splitLocation[1] === "campus-sustainability" ? "active" : ""}>
                                        <NavDropdown title="Campus Sustainability" id="basic-nav-dropdown" className="animated fadeInUp" renderMenuOnMount={true}>
                                            <ul className='px-md-0 px-3 py-xl-2 bg-white rounded-bottom-3'>
                                                <NavDropdown.Item as="li"><Link to="#">Sustainability @ IITB</Link></NavDropdown.Item>
                                                <NavDropdown.Item as="li"><Link to="#">GESH Living Lab Program</Link></NavDropdown.Item>
                                                <NavDropdown.Item as="li"><Link to="#">Policies</Link></NavDropdown.Item>
                                            </ul>
                                        </NavDropdown>
                                    </li>
                                    <li className={splitLocation[1] === "outreach" ? "active" : ""}>
                                        <NavDropdown title="Outreach" id="basic-nav-dropdown" className="animated fadeInUp" renderMenuOnMount={true}>
                                            <ul className='px-md-0 px-3 py-xl-2 bg-white rounded-bottom-3'>
                                                <NavDropdown.Item as="li"><Link to="#">Partnerships</Link></NavDropdown.Item>
                                                <NavDropdown.Item as="li"><Link to="#">Events</Link></NavDropdown.Item>
                                            </ul>
                                        </NavDropdown>
                                    </li>
                                    {/* <li className={splitLocation[1] === "people" ? "active" : ""}>
                                        <NavDropdown title="People" id="basic-nav-dropdown" className="animated fadeInUp" renderMenuOnMount={true}>
                                            <ul className='px-md-0 px-3 py-xl-2 bg-white rounded-bottom-3'>
                                                {
                                                    peopleCategory.map((val_people) => {
                                                        return (
                                                            <NavDropdown.Item as="li"><Link to={globalVariables.variables.strDomainUrl + 'people/' + val_people.pcategory_pagename}
                                                                onClick={() => {
                                                                    scrollToTop()
                                                                    setExpanded(false)
                                                                }
                                                                } id='ClickByRef'>{val_people.pcategory_name}</Link>
                                                            </NavDropdown.Item>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </NavDropdown>
                                    </li> */}
                                    {/* <li className={splitLocation[1] === "focus-areas" ? "active nav-item animated fadeInUp" : "nav-item animated fadeInUp"}><Link to="/focus-areas"
                                        onClick={() => {
                                            scrollToTop()
                                            setExpanded(false)
                                        }} className="nav-link">Focus Areas</Link></li> */}
                                    {/* <li className={splitLocation[1] === "services" ? "active nav-item animated fadeInUp" : "nav-item animated fadeInUp"}><Link to="/services"
                                        onClick={() => {
                                            scrollToTop()
                                            setExpanded(false)
                                        }} className="nav-link">Services</Link></li> */}
                                    {/* <li className={splitLocation[1] === "services" ? "active" : "" || splitLocation[1] === "services" ? "active" : ""}>
                                        <NavDropdown title="Services" id="basic-nav-dropdown" className="animated fadeInUp" renderMenuOnMount={true}>
                                            <ul className='px-0 py-xl-2 bg-white rounded-bottom-3'>
                                                <NavDropdown.Item as="li"><Link to="/services" onClick={scrollToTop}>Sustainability Assessment</Link></NavDropdown.Item>
                                                <NavDropdown.Item as="li"><Link to="/services" onClick={scrollToTop}>Training & Workshops</Link></NavDropdown.Item>
                                            </ul>
                                        </NavDropdown>
                                    </li> */}
                                    {/* <li className={splitLocation[1] === "research" ? "active" : "" || splitLocation[1] === "publications" ? "active" : "" || splitLocation[1] === "research-projects" ? "active" : ""}>
                                        <NavDropdown title="Research" id="basic-nav-dropdown" className="animated fadeInUp" renderMenuOnMount={true}>
                                            <ul className='px-md-0 px-3 py-xl-2 bg-white rounded-bottom-3'>
                                                <NavDropdown.Item as="li"><Link to="/research"
                                                    onClick={() => {
                                                        scrollToTop()
                                                        setExpanded(false)
                                                    }}>Overview</Link></NavDropdown.Item>
                                                <NavDropdown.Item as="li"><Link to="/research-projects" onClick={scrollToTop}>GESH Projects</Link></NavDropdown.Item>
                                                <NavDropdown.Item as="li"><Link to="/publications"
                                                    onClick={() => {
                                                        scrollToTop()
                                                        setExpanded(false)
                                                    }}>Publications</Link></NavDropdown.Item>
                                            </ul>
                                        </NavDropdown>
                                    </li> */}
                                    {/* <li className={splitLocation[1] === "campus-sustainability" ? "active nav-item animated fadeInUp" : "nav-item animated fadeInUp"}><Link to="/campus-sustainability"
                                        onClick={() => {
                                            scrollToTop()
                                            setExpanded(false)
                                        }} className="nav-link">Campus Sustainability</Link></li>
                                    <li className={splitLocation[1] === "ourpartners" ? "active nav-item animated fadeInUp" : "nav-item animated fadeInUp"}><Link to="/ourpartners"
                                        onClick={() => {
                                            scrollToTop()
                                            setExpanded(false)
                                        }} className="nav-link">Partnerships</Link></li> */}

                                    {/* <li className={`${splitLocation[1] === "news" ? "active" : ""} ${newsTypeList?.length > 1 ? `d-xl-none d-block` : `d-none` }`}>
                                        <NavDropdown title="News" id="basic-nav-dropdown" className="animated fadeInUp" renderMenuOnMount={true}>
                                            <ul className='px-md-0 px-3 py-xl-2 bg-white rounded-bottom-3'>
                                                {
                                                    newsTypeList.map((val_news) => {
                                                        return (
                                                            <NavDropdown.Item as="li"><Link to={globalVariables.variables.strDomainUrl + 'news/' + val_news.news_type_pagename}
                                                                onClick={()=>{scrollToTop()
                                                                setExpanded(false)
                                                                }
                                                                }>{val_news.news_type_name}</Link>
                                                            </NavDropdown.Item>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </NavDropdown>
                                        </li> */}

                                    {/* <li className={`nav-item animated fadeInUp d-xl-none ${newsTypeList?.length === 1 ? `d-block ` : `d-none` }`}><Link to={globalVariables.variables.strDomainUrl + 'news/' + newsTypeList[0]?.news_type_pagename}
                                    onClick={()=>{scrollToTop()
                                        setExpanded(false)
                                    }} className="nav-link">News & Events</Link></li> */}
                                    <li className={`nav-item animated fadeInUp d-xl-none`}><Link to={globalVariables.variables.strDomainUrl + 'news-events'}
                                        onClick={() => {
                                            scrollToTop()
                                            setExpanded(false)
                                        }} className="nav-link">News & Events</Link></li>
                                    {/* <li className="nav-item animated fadeInUp d-xl-none"><Link to="/events" className="nav-link"
                                    onClick={()=>{scrollToTop()
                                        setExpanded(false)}}>Events</Link></li> */}
                                    <li className="nav-item animated fadeInUp d-xl-none"><Link to="/careers" className="nav-link"
                                        onClick={() => {
                                            scrollToTop()
                                            setExpanded(false)
                                        }}>Careers</Link></li>
                                    <li className="nav-item animated fadeInUp d-xl-none"><HashLink to="#contact" className="nav-link" onClick={() => setExpanded(false)} scroll={el => scrollWithOffset(el)}>Contact Us</HashLink></li>
                                </ul>
                            </Navbar.Offcanvas>
                        </Col>
                    </Row >
                </Container >

                <Modal show={show} fullscreen={fullscreen} onHide={handleClose} className="bg-search-panel">
                    <Modal.Body className="d-flex justify-content-center align-items-center">
                        <Container className='d-flex align-items-center flex-column'>
                            <div className="col-md-8 mx-auto">
                                <Modal.Header closeButton className="border-0 py-md-5 py-4 h5 ms-auto">
                                    <Modal.Title></Modal.Title>
                                </Modal.Header>
                                <InputGroup className="mb-3 border rounded bg-white">
                                    <Form.Control
                                        className="border-0 form-control-lg shadow-none" placeholder="Search" aria-label="Search" aria-describedby="" required="" id='txtSearchSite' ref={ref} />
                                    <span id="searchSiteerr" class="text-danger me-2" style={{ 'margin-top': ' 10px', 'margin-right': '5px' }}></span>
                                    <Button variant="outline-dark border-0" id="btnsearchSite">
                                        <FiSearch />
                                    </Button>
                                </InputGroup>
                            </div>
                        </Container>
                    </Modal.Body>
                </Modal>
            </Navbar >
            <TrackingCode conversion_code={conversion_code} />
        </>
    )
}