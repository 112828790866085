import React, { useEffect } from "react";
import global from "../../global-variables";
import Header from "../layout/header";
import { Link } from "react-router-dom";
import $ from "jquery";
import WOW from "wowjs";


import { Container, Row, Col } from "reactstrap";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import PublicationsNav from "../layout/publications-nav";
import Metatag from "../../hooks/Metatag";
import globalVariables from "../../global-variables";

export default function Publications() {

  useEffect(() => {
    
     // remove bg-header-home 
     $(window).on("scroll", function () {
      $(".navbar").offset().top > 10 ? $(".fixed-top").removeClass("bg-header-home") : $(".fixed-top").removeClass("bg-header-home")
    });
    
    // initiate the wow effect
    new WOW.WOW({
      live: false,
    }).init();

  }, []);

  return (
    <>
      <Header></Header>

      {/* MetaTag */}
      <Metatag title='Green Energy and Sustainability Research Publications by IIT Bombay'
        description='Green Energy and Sustainability Research Publications by IIT Bombay'
        keywords="Green Energy and Sustainability Research Publications by IIT Bombay"
      />

      {/* Visual Panel */}
      <Container fluid className="bg-visual-inside p-0" >
        <div className="visual-inside-big visual-container-inside">
          <Container>
            <Row className="g-md-0 rounded-4">
              <Col md={6}>
                <div className="d-flex align-items-end h-100 pb-md-5">
                  <div className="d-inline-block m-0 align-self-end py-xl-3 p-2 text-white">
                    <h1 className="fw-light text-white m-0 align-self-center wow fadeInUp">
                      Publications
                    </h1>
                  </div>
                </div>
              </Col>
              <Col md={6} className="mt-auto ms-auto">
                <div className="border-bottom border-5 border-primary rounded-4">
                  <img src={global.variables.strDomainUrl + "images/visual-research2.jpg"} className="img-fluid rounded-4" alt="" title="" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>

      {/* Container */}
      <Container className="my-5 pt-5">
        <Row className="g-0">
          <Col md={12} lg={12}>
            <div className="border rounded-4 px-5 py-5 h-100">
              <div className="pe-2 col-11">
                <p>This section is currentky being updated, please visit us soon.</p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      {/* Breadcrumb */}
      <Breadcrumb className="breadcrumb-panel mt-auto">
        <Breadcrumb.Item href={globalVariables.variables.strDomainUrl}>Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Research</Breadcrumb.Item>
        <Breadcrumb.Item active>Publications</Breadcrumb.Item>
      </Breadcrumb>
    </>
  );
}