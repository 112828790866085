import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import global from "../../global-variables";
import Header from "../layout/header";
import { Link } from "react-router-dom";
import $ from "jquery";
import WOW from "wowjs";
import { FaLink, FaImage, FaRegFilePdf, FaVideo, FaAngleLeft } from "react-icons/fa";
import Metatag from '../../hooks/Metatag'

import { Container, Row, Col } from "reactstrap";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import FsLightbox from "fslightbox-react";

import { GetNewsList } from "../../api/news-functions";
import { GetNewsGalleryList } from "../../api/news-gallery-functions";
import globalVariables from "../../global-variables";
import { Button } from "react-bootstrap";


export default function Home() {
  const [toggler, setToggler] = useState(true);
  const [videos, setvideosToggler] = useState(false);
  const [index, setIndex] = useState(0);

  // constant to be used on the page
  const [news, setNewsList] = useState(null);

  const [news_gallery_toggler, setNewsGalleryToggler] = useState(false);
  const [news_gallery, setNewsGallery] = useState([]);

  const [lightbox_video, setLightBoxVideo] = useState([]);


  const [gallery_key, setGalleryKey] = useState("");

  const navigate = useNavigate();

  //Getting id from url
  let { page_name } = useParams();
  const qs_news_pagename = page_name;

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  // success function when news gallery is retrived
  const OnGetNewsGallerySuccess = (p_response) => {

    // set the array in the constant
    setNewsGallery(p_response);

    setGalleryKey(Date.now());

    // using time out for the value to get saved in state 
    setTimeout(() => {
      // trigger the link click to display the news gallery popup
      document.getElementById("lnk_trigger_news_gallery_popup_news").click();
    }, 200);

  }

  // function to invoke photo gallery
  const InvokePhotoGallery = (e, p_news_id) => {

    e.preventDefault();

    // Make an object for getting news gallery
    let _objNewsGallery = new Object();
    _objNewsGallery.gallery_status = 1;
    _objNewsGallery.gallery_news_id = p_news_id;

    // call the function to get the news gallery
    GetNewsGalleryList(_objNewsGallery, OnGetNewsGallerySuccess);

  };

  // function to invoke video popup
  const InvokeVideoPopup = (e, p_news_video_url) => {

    e.preventDefault();

    let _arrVideos = [];
    _arrVideos.push(p_news_video_url);

    // set the path to the constant for displaying
    setLightBoxVideo(_arrVideos);

    // trigger the link click to display the popup
    document.getElementById("lnk_trigger_video_popup_news").click();

  };

  // success function when the list is retrived
  const OnGetNewsSuccess = (p_response) => {
    // set the list for looping
    setNewsList(p_response);
  }

  useEffect(() => {
    
    // initiate the wow effect
    new WOW.WOW({
      live: false,
    }).init();

  }, []);

  // things to be done when the page is loaded
  useEffect(() => {

    // make the object for getting the visual panel list
    let _objNews = new Object();
    _objNews._news_pagename = qs_news_pagename;
    _objNews._news_status = 1;

    // call the function to get the roadblock
    GetNewsList(_objNews, OnGetNewsSuccess);

  }, []);

  return (
    <>
      <Header></Header>

      {/* MetaTag */}
      <Metatag title={news != null ? news[0]?.news_pagetitle : null}
        description={news != null ? news[0]?.news_meta_description : null}
        keywords={news != null ? news[0]?.news_meta_keywords : null}
      />

      {/* News Visual Panel */}
      <Container fluid className="bg-visual-inside p-0" >
        <div className="visual-inside-big visual-container-inside">
          <Container>
            <Row className="g-md-0 rounded-4">
              <Col md={6} className="order-2 order-md-1">
                <div className="d-flex align-items-end h-100 pb-5">
                  <div className="d-flex m-0 align-items-center py-xl-3 p-2 text-white wow fadeInUp">
                    <h1 className="fw-light text-white d-inline-block m-0 align-self-center wow fadeInUp">
                      News
                    </h1>
                  </div>
                </div>
              </Col>
              <Col md={6} className="order-1 order-md-2 ms-auto">
                <div className="border-bottom border-5 border-primary rounded-4">
                  <img src={global.variables.strDomainUrl + "images/visual-news-events.jpg"} className="img-fluid rounded-4" alt="" title="" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>

      {/* News Container */}

      {
        news != null ?

          news.length > 0 ?

            <Container className="mb-5 pt-5">
              <Row className="row-cols-1 row-cols-md-1 row-cols-lg-1 row-cols-xl-1 g-4 g-lg-5 g-xl-4 mt-4 grid">
                <Col>
                  <Container className="border p-md-4 p-2 rounded-3">
                    <Row className="row">

                      <Col md={4} lg={3}>
                        {
                          news[0].news_images != null && news[0].news_images != "null" && news[0].news_images != "" ?
                            <img src={global.variables.strApiUrl + "/news-main-image/" + news[0].news_images} alt={news[0].news_headline} title={news[0].news_headline} className="img-fluid rounded-3 mb-md-0 mb-3 border wow fadeInUp" />
                            :
                            //default img
                            null
                        }

                      </Col>
                      <Col md={8} lg={9} className="d-flex align-items-start flex-column">
                        <h5 className="text-dark wow fadeInUp">
                          {news[0].news_headline}
                        </h5>
                        <p className="wow fadeInUp">
                          {/* Date: {news[0].news_date}  */}

                          {/*when from and to date are diffrent  */}
                          Date:
                          {
                            news[0].news_date != news[0].news_to_date ?
                              <>

                                {
                                  news[0].from_mon != news[0].to_mon ?
                                    <>
                                      {` ${news[0].from_day}-${news[0].from_mon}-${news[0].from_year} to ${news[0].to_day}-${news[0].to_mon}-${news[0].to_year}`}

                                    </>
                                    :
                                    <>
                                      {` ${news[0].from_day}-${news[0].from_mon}-${news[0].from_year} to ${news[0].to_day}-${news[0].to_mon}-${news[0].to_year}`}
                                    </>

                                }

                              </>

                              :
                              // when from and to date same 
                              <>
                                {
                                  //when from and to date's month is same
                                  news[0].from_mon != news[0].to_mon ?
                                    <>
                                      {` ${news[0].from_day}-${news[0].from_mon}-${news[0].from_year} to ${news[0].to_day}-${news[0].to_mon}-${news[0].to_year}`}
                                    </>
                                    :
                                    <>
                                      {` ${news[0].from_day}-${news[0].from_mon}-${news[0].from_year}`}
                                    </>
                                }
                              </>


                          }


                          {news[0].news_caption != null && news[0].news_caption != "" && news[0].news_caption != "null" ?
                            <><br /> Venue: {news[0].news_caption}</> : null}
                          {news[0].news_sourse != null && news[0].news_sourse != "" && news[0].news_sourse != "null" ? <><br /> Source: {news[0].news_sourse}</> : null} <br /></p>


                        {/* Details */}
                        {
                          news[0].news_details != null && news[0].news_details != "null" && news[0].news_details != "" ?
                            <div className=" wow fadeInUp"><span dangerouslySetInnerHTML={{ __html: news[0].news_details }}></span></div>
                            : null
                        }

                        <div className="d-flex gap-2 mt-2 w-100">

                          {/* link */}
                          {
                            news[0].news_link != null && news[0].news_link != "null" && news[0].news_link != "" ?
                              <Link to={news[0].news_link} target="_blank" className="btn btn-primary rounded-pill Faicon px-3 py-1 mt-auto wow fadeInUp"><FaLink /></Link>
                              :
                              null
                          }

                          {/* Photo Gallery */}
                          {
                            parseInt(news[0].news_gallery_count) > 0 ?
                              <a className="btn btn-primary rounded-pill Faicon px-3 py-1 mt-auto wow fadeInUp" onClick={event => InvokePhotoGallery(event, news[0].news_id)}><FaImage /></a>
                              :
                              null
                          }

                          {/* Document */}
                          {
                            news[0].news_document != null && news[0].news_document != "null" && news[0].news_document != "" ?
                              <Link to={global.variables.strApiUrl + "/news-documents/" + news[0].news_document} target="_blank" className="btn btn-primary rounded-pill Faicon px-3 py-1 mt-auto wow fadeInUp"><FaRegFilePdf /></Link>
                              :
                              null
                          }


                          {/* Video */}
                          {
                            news[0].news_video_link != null && news[0].news_video_link != "null" && news[0].news_video_link != "" ?
                              <a className="btn btn-primary rounded-pill Faicon px-3 py-1 mt-auto wow fadeInUp" onClick={event => InvokeVideoPopup(event, news[0].news_video_link)}>    <FaVideo /></a>
                              :
                              null
                          }

                          {/* <Link to="#" className="btn btn-warning rounded-pill Faicon px-3 py-1 mt-auto wow fadeInUp">
                              <FaVideo />
                            </Link> */}
                          <Button onClick={()=> navigate(-1)} className="btn btn-primary rounded-pill Faicon px-3 py-1 ms-auto d-flex align-items-center mt-auto wow fadeInUp">
                            <FaAngleLeft /> Back
                          </Button>
                        </div>

                        <FsLightbox toggler={toggler}
                          sources={[
                            "images/news/Digital-Health-Insights.png",
                            "https://i.imgur.com/fsyrScY.jpg",
                          ]}
                        />
                      </Col>
                    </Row>
                  </Container>
                </Col>
              </Row>
            </Container>
            :
            <Container className="mb-5 pt-5">
              <Row>
                <span className="text-center">No Records Found</span>
              </Row>
              {/* back button */}
            </Container>
          :
          <Container className="mb-5 pt-5">
            <Row>
              <span className="text-center">Loading...</span>
            </Row>
            {/* back button */}
          </Container>
      }

      {/* Video popup */}
      {/* this link will open the video popup which will be hidden because the button from the grid is causing issue */}
      <a onClick={(e) => {
        e.preventDefault();
        setvideosToggler(!videos);
      }}
        className="d-none"
        id="lnk_trigger_video_popup_news"></a>

      <FsLightbox
        toggler={videos}
        sources={lightbox_video}
      />

      {/* News Gallery popup */}
      {/* this link will open the news gallery popup which will be hidden because the button from the grid is causing issue */}
      <a onClick={(e) => {
        e.preventDefault();
        setNewsGalleryToggler(!news_gallery_toggler);
      }}
        className="d-none"
        id="lnk_trigger_news_gallery_popup_news"></a>

      <FsLightbox
        toggler={news_gallery_toggler}
        sources={news_gallery?.map(item => {
					return(
						<div style={{
							display: 'flex', 
							flexDirection: 'column', 
							alignItems: 'center', 
							justifyContent: 'center'
						}} key={item.gallery_id} className="fsDiv">
						<img className="img-fluid" src={`${globalVariables.variables.strApiUrl}/news-gallery-image/${item.gallery_pic}`} alt={item.gallery_title} style={{ objectFit: 'contain' }} />
						<p style={{ textAlign: 'center', color: 'white', fontSize: '16px' }}>{item.gallery_title}</p>
						</div>
					)
				})}
        key={gallery_key}
      />


      {/* Breadcrumb */}
      <Breadcrumb className="breadcrumb-panel mt-auto">
        <Breadcrumb.Item href={globalVariables.variables.strDomainUrl}>Home</Breadcrumb.Item>
        {
          news !== null ?
          <Breadcrumb.Item href={globalVariables.variables.strDomainUrl + "news-events"}>News & Events</Breadcrumb.Item>
          : ''
        }
        <Breadcrumb.Item active>{news != null ? news[0]?.news_headline : null}</Breadcrumb.Item>
      </Breadcrumb>
    </>
  );
}