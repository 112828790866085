import React, { useState, useEffect } from "react";
import global from "../../global-variables";
import Header from "../layout/header";
import { Link, useParams, useNavigate } from "react-router-dom";
import $ from "jquery";
import WOW from "wowjs";
import { FaLink, FaImage, FaRegFilePdf, FaVideo, FaChevronRight, FaLinkedinIn } from "react-icons/fa";
import axios from "axios";
import { Container, Row, Col } from "reactstrap";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Modal from 'react-bootstrap/Modal';
import globalVariables from "../../global-variables";
import Metatag from "../../hooks/Metatag";
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import PeoplePopup from "./people-popup";

export default function PeopleDetails() {

  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [getPeopleid, setPeopleid] = useState('');
  const handleClose = () => setShow(false);
  const handleShow = (e, people_id) => {
    e.preventDefault();
    setShow(true);
    //get people id
    setPeopleid(people_id);
  }

  //getting pagename value from url
  let { pcategory_pagename, ps_category_pagename, department_pagename } = useParams();
  const qs_pcategory_pagename = pcategory_pagename;
  const qs_ps_category_pagename = ps_category_pagename;
  const qs_department_pagename = department_pagename;

  //getting perticular people category list
  const [getpeopleCategory, setPeopleCategoryList] = useState(null);

  //saving people category id value to get subcategory list
  const [getPcategoryId, setpcategoryId] = useState();

  //getting perticular people subcategory list
  const [getPeopleSubCategoryList, setPeopleSubCategoryList] = useState([])

  //save people list data
  const [getPeopleList, setPeopleList] = useState([]);

  //People category list
  useEffect(() => {
    let _obj = new Object();
    _obj.pcategory_status = 1;
    _obj.pcategory_pagename = qs_pcategory_pagename
    setTimeout(() => {

      axios.post(global.variables.strApiUrl + "/peoplecategoryApi/getpeopleCategoryList", _obj)
        .then((response_perticular_pCategory) => {
          if (response_perticular_pCategory.data === 'error') {
            navigate('/error-page')
          }
          else {
            // pass the result in the success function of the respective page
            setPeopleCategoryList(response_perticular_pCategory.data[0]);
            setpcategoryId(response_perticular_pCategory.data[0][0]?.pcategory_id);
          }
        });
    }, 300);


  }, [qs_pcategory_pagename])

  //People sub category list to bind left side
  useEffect(() => {
    let _obj = new Object();
    // _obj.ps_category_status = 1;
    _obj.ps_pcategory_id = getPcategoryId

    axios.post(global.variables.strApiUrl + "/peoplesubcategoryApi/getsubcategoryHavingActivePeopleList", _obj)
      .then((response_perticular_pSubCategory) => {
        if (response_perticular_pSubCategory.data === 'error') {
          navigate('/error-page')
        }
        else {
          // pass the result in the success function of the respective page
          setPeopleSubCategoryList(response_perticular_pSubCategory.data[0]);
        }

      });
  }, [getPcategoryId])

  //saving ps_category_id to get perticular data
  const [getPsCategoryIdForPerticularPeople, setPsCategoryIdForPerticularPeople] = useState()

  const [getMappedDeptList, setMappedDeptList] = useState(null);


  const [SubCategortyName, setSubCategortyName] = useState()
  useEffect(() => {

    let _obj = new Object();
    _obj.ps_category_status = 1;
    _obj.ps_category_pagename = qs_ps_category_pagename;

    axios.post(globalVariables.variables.strApiUrl + "/peoplesubcategoryApi/getpeopleSubCategoryList", _obj)
      .then((val_ps_category) => {
        if (val_ps_category.data === 'error') {
          navigate('/error-page')
        }
        else {
          if (val_ps_category.data[0].length > 0) {

            if (val_ps_category.data[0][0].ps_category_show_dept === 1) {
              // call the function to get the mapped department list 

              let obj = new Object();
              obj.ps_category_id = val_ps_category.data[0][0].ps_category_id

              axios.post(globalVariables.variables.strApiUrl + "/getmapPeopledepartmentApi/getMappedPeopleDepartment", obj)
                .then((response_mappedPeopleDepartment) => {
                  if (response_mappedPeopleDepartment.data === 'error') {
                    navigate('/error-page')
                  }
                  else {
                    setMappedDeptList(response_mappedPeopleDepartment.data[0])
                  }
                })
            }
            else {
              //make the state of the list empty []
            }

            setPsCategoryIdForPerticularPeople(val_ps_category.data[0][0]?.ps_category_id)
            setSubCategortyName(val_ps_category.data[0])
          }
          else {

            window.location = globalVariables.variables.strDomainUrl + 'people/' + qs_pcategory_pagename;

          }
        }


      })
  }, [qs_ps_category_pagename])

  useEffect(() => {
    let _objPeople = new Object();
    _objPeople.people_status = 1
    _objPeople.people_ps_category_id = getPsCategoryIdForPerticularPeople
    if (getPsCategoryIdForPerticularPeople != null && getPsCategoryIdForPerticularPeople != '') {

      axios.post(globalVariables.variables.strApiUrl + "/peopleApi/getpeopleList", _objPeople)
        .then((response_peopleList) => {
          if (response_peopleList.data === 'error') {
            navigate('/error-page')
          }
          else {
            if (response_peopleList.data[0].length > 0) {
              setPeopleList(response_peopleList.data[0])
            }
          }
        })
    }
  }, [getPsCategoryIdForPerticularPeople])


  const [peopleSubCategoryListForMetaTag, setPeopleSubCategoryListForMetaTag] = useState([]);
  //People sub category list
  useEffect(() => {
    let _obj = new Object();
    _obj.ps_category_status = 1;
    _obj.ps_category_pagename = qs_ps_category_pagename


    axios.post(global.variables.strApiUrl + "/peoplesubcategoryApi/getpeopleSubCategoryList", _obj)
      .then((response_perticular_psCategory) => {
        if (response_perticular_psCategory.data === 'error') {
          navigate('/error-page')
        }
        else {
          // pass the result in the success function of the respective page
          setPeopleSubCategoryListForMetaTag(response_perticular_psCategory.data[0]);
        }

      });


  }, [qs_ps_category_pagename])

  //variable to close left navbar on link click
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    // add class for home header and remove inner class
    $(window).on("scroll", function () {
      $(".navbar").offset().top > 10 ? $(".fixed-top").removeClass("bg-header-home") : $(".fixed-top").removeClass("bg-header-home")
    });

    // initiate the wow effect
    new WOW.WOW({
      live: false,
    }).init();


  }, [getPeopleList]);

  return (
    <>
      <Header conversion_code></Header>
      {/* {
        peopleSubCategoryListForMetaTag != null?
          <Metatag title={peopleSubCategoryListForMetaTag[0]?.ps_category_pagetitle} keywords={peopleSubCategoryListForMetaTag[0]?.ps_category_meta_keywords} description={peopleSubCategoryListForMetaTag[0]?.ps_category_meta_description}/>
          :
          null
      } */}
      {
        getpeopleCategory != null ?
          <Metatag title={getpeopleCategory[0]?.pcategory_pagetitle} keywords={getpeopleCategory[0]?.pcategory_meta_keywords} description={getpeopleCategory[0]?.pcategory_meta_description} />
          :
          null
      }
      {/* Category name */}
      {
        getpeopleCategory != null ?
          getpeopleCategory.length > 0 ?
            getpeopleCategory.map((val_peoplecategory) => {
              return (
                <>
                  <Container fluid className="bg-visual-inside p-0" >
                    <div className="visual-inside-big visual-container-inside">
                      <Container>
                        <Row className="g-md-0">
                          <Col md={6}>
                            <div className="d-flex align-items-end h-100 pb-md-5">
                              {
                                <>
                                  {
                                    qs_pcategory_pagename === val_peoplecategory.pcategory_pagename ?

                                      <div className="d-inline-block m-0 align-self-end py-xl-3 p-2 text-white">
                                        <h1 className="fw-light text-white m-0 align-self-center wow fadeInUp">
                                          {val_peoplecategory.pcategory_name}
                                        </h1>
                                      </div>
                                      :
                                      null
                                  }
                                </>
                              }
                            </div>
                          </Col>

                          <Col md={6} className="mt-auto ms-auto">
                            <div className="border-bottom border-5 border-primary rounded-4">
                              <img src={global.variables.strDomainUrl + "images/visual-people.jpg"} className="img-fluid rounded-4" alt="" title="" />
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                  </Container>
                  
                  {
                    val_peoplecategory.psubcategory_count > 1 ?
                      <>
                        <Container className="my-5 pt-5">
                          <Row className="g-0">
                            <Col md={8} lg={10} className="order-2 order-md-1">
                              <div className="tab-content border rounded-4 px-5 py-5 h-100" id="v-pills-tabContent">
                                <div className="pe-2 col-11">
                                  <div className="tab-pane fade show active overflow-hidden" role="tabpanel" tabindex="0">
                                    <div id="collapseOne" className="d-lg-block" data-bs-parent="#v-pills-tabContent">
                                      <Row className="row-cols-1 g-5 people">
                                        {
                                          // getPeopleList != [] ?
                                          getPeopleList.length > 0 ?
                                            getPeopleList.map((val_people) => {
                                              return (
                                                <>
                                                  {
                                                    // val_people.people_ps_category_id === getPsCategoryIdForPerticularPeople ?
                                                    <Col>
                                                      <Link onClick={(e) => handleShow(e, val_people.people_id)}>
                                                        <Row className="align-items-start">
                                                          <Col md={4} lg={2}>
                                                            {val_people.people_pic != null && val_people.people_pic != '' ?
                                                              <img src={globalVariables.variables.strApiUrl + '/people/' + val_people.people_pic} alt={val_people?.people_salutation != null && val_people?.people_salutation != '' ? `${val_people?.people_salutation} ${val_people?.people_name}` : `${val_people?.people_name}`} title={val_people?.people_salutation != null && val_people?.people_salutation != '' ? `${val_people?.people_salutation} ${val_people?.people_name}` : `${val_people?.people_name}`} className="img-fluid rounded-3 mb-md-0 mb-3 wow fadeInUp" />
                                                              :
                                                              <img src={global.variables.strDomainUrl + "images/people/people-default.jpg"} alt={val_people?.people_salutation != null && val_people?.people_salutation != '' ? `${val_people?.people_salutation} ${val_people?.people_name}` : `${val_people?.people_name}`} title={val_people?.people_salutation != null && val_people?.people_salutation != '' ? `${val_people?.people_salutation} ${val_people?.people_name}` : `${val_people?.people_name}`} className="img-fluid rounded-3 mb-md-0 mb-3 wow fadeInUp" />

                                                            }
                                                          </Col>
                                                          <Col md={8} lg={10} >

                                                            {
                                                              val_people.people_salutation != null && val_people.people_salutation != '' ?
                                                                <h5 className="text-dark m-0 wow fadeInUp">
                                                                  {`${val_people.people_salutation} ${val_people.people_name}`}
                                                                </h5>
                                                                :
                                                                <h5 className="text-dark m-0 wow fadeInUp">
                                                                  {val_people.people_name}
                                                                </h5>
                                                            }

                                                            {
                                                              val_people.people_designation != null && val_people.people_designation != '' ?
                                                                <p className="wow fadeInUp">
                                                                  <b>{val_people.people_designation}</b>
                                                                </p>
                                                                :
                                                                null
                                                            }
                                                            {/* Introduction  */}
                                                            {
                                                              val_people.people_research_inst != null && val_people.people_research_inst != '' ?
                                                                <p><span dangerouslySetInnerHTML={{ __html: val_people.people_research_inst }}></span></p>
                                                                :
                                                                null
                                                            }
                                                          </Col>
                                                        </Row>
                                                      </Link>
                                                    </Col>
                                                    // :
                                                    // null
                                                  }
                                                </>
                                              )
                                            })
                                            :
                                            // <Container>
                                            //   <Row>
                                            //     <span className="text-center"><b>No records found</b></span>
                                            //   </Row>
                                            // </Container>
                                            null
                                          // :
                                          //    <Container className="my-5">
                                          //     <Row>
                                          //       <span className="text-center">Loading...</span>
                                          //     </Row>
                                          //   </Container>

                                        }
                                      </Row>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Col>
                            <Col md={4} lg={3} className="py-5 container-end order-1 order-md-2">
                              <div className="ps-5 bg-white h-100">
                                <div className="border-bottom border-4 border-primary rounded-4 h-100">
                                  <div className="bg-primary bg-opacity-10 rounded-4 py-4 px-4 border h-100">
                                    <Navbar expanded={expanded} collapseonselect expand="md" className='d-block p-0'>
                                      <Navbar.Toggle onClick={() => setExpanded(expanded ? false : "expanded")} className="offcanvas-toggle shadow-none w-100 animated fadeInUp" aria-controls={'offcanvas-menu'}>
                                        <Link className="btn btn-outline-secondary rounded-pill px-4 py-2 w-100 text-start d-flex justify-content-between align-items-center d-md-none">
                                          {SubCategortyName[0]?.ps_category_name}
                                          <HiOutlineMenuAlt2 /></Link>
                                      </Navbar.Toggle>
                                      <Navbar.Offcanvas id={'offcanvasTop'} aria-labelledby={'offcanvasTop'} placement="start" responsive="md" className="p-md-0 p-2 d-block">
                                        <Offcanvas.Header closeButton onClick={() => setExpanded(false)}>
                                          <Offcanvas.Title>
                                          </Offcanvas.Title>
                                        </Offcanvas.Header>
                                        <ul className='left-navbar overflow-hidden position-relative w-100 mb-nav-link'>
                                          {
                                            getPeopleSubCategoryList.map((val_peopleSubCategory, index) => {

                                              return (<>
                                                {
                                                  val_peopleSubCategory.ps_category_show_dept != 1 ?

                                                    <div className="nav flex-column" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                                         <li className='text-uppercase fw-bold'><Link onClick={() => setExpanded(false)} to={globalVariables.variables.strDomainUrl + 'people/' + qs_pcategory_pagename + '/' + val_peopleSubCategory.ps_category_pagename} className={`nav-link ${qs_ps_category_pagename === val_peopleSubCategory.ps_category_pagename ? "active" : null}`} >{val_peopleSubCategory.ps_category_name}</Link></li>
                                                    </div>
                                                    :
                                                    <>


                                                      <div className="nav flex-column me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                                        
                                                        <li className='text-uppercase fw-bold'> <Link onClick={() => setExpanded(false)} to={globalVariables.variables.strDomainUrl + 'people/' + qs_pcategory_pagename + '/' + val_peopleSubCategory.ps_category_pagename} className={`nav-link ${qs_ps_category_pagename === val_peopleSubCategory.ps_category_pagename ? "active" : null}`}>{val_peopleSubCategory.ps_category_name}</Link></li>
                                                            
                                                        {
                                                          getMappedDeptList?.map((val_dept, _index) => {
                                                            return (
                                                              qs_ps_category_pagename === val_peopleSubCategory.ps_category_pagename ?
                                                                // index === 0 ?
                                                                <Link Link to={globalVariables.variables.strDomainUrl + 'people/' + qs_pcategory_pagename + '/' + val_peopleSubCategory.ps_category_pagename + '/' + val_dept.department_pagename} className="nav-link nav-sublink Faicon14"> {val_dept.department_name}</Link>
                                                                // :
                                                                // <Link Link to={globalVariables.variables.strDomainUrl + 'people/'+ qs_pcategory_pagename +'/' + val_peopleSubCategory.ps_category_pagename + '/' + val_dept.department_pagename} className="nav-link nav-sublink Faicon14" ><FaChevronRight /> {val_dept.department_name}</Link>
                                                                :
                                                                null
                                                            )
                                                          })
                                                        }
                                                      </div>
                                                    </>
                                                }
                                              </>)
                                            })
                                          }
                                        </ul>
                                      </Navbar.Offcanvas>
                                    </Navbar>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Container>
                      </>
                      :
                      <>
                        <Container className="my-5 careers">
                          <div className="border p-4 rounded-3 overflow-hidden">
                            <Row className="row-cols-1 g-4 people">
                              {
                                getPeopleList.length > 0 ?
                                  getPeopleList.map((val_people) => {
                                    return (
                                      <>
                                        {
                                          val_people.people_ps_category_id === getPsCategoryIdForPerticularPeople ?
                                            <Col>
                                              <Row>
                                                <Col md={4} lg={2}>
                                                  {
                                                    val_people.people_pic != null && val_people.people_pic != '' ?
                                                      <img src={globalVariables.variables.strApiUrl + '/people/' + val_people.people_pic} alt={val_people?.people_salutation != null && val_people?.people_salutation != '' ? `${val_people?.people_salutation} ${val_people?.people_name}` : `${val_people?.people_name}`} title={val_people?.people_salutation != null && val_people?.people_salutation != '' ? `${val_people?.people_salutation} ${val_people?.people_name}` : `${val_people?.people_name}`} className="img-fluid rounded-3 mb-md-0 mb-3 wow fadeInUp" />
                                                      :
                                                      <img src={global.variables.strDomainUrl + "images/people/people-default.jpg"} alt={val_people?.people_salutation != null && val_people?.people_salutation != '' ? `${val_people?.people_salutation} ${val_people?.people_name}` : `${val_people?.people_name}`} title={val_people?.people_salutation != null && val_people?.people_salutation != '' ? `${val_people?.people_salutation} ${val_people?.people_name}` : `${val_people?.people_name}`} className="img-fluid rounded-3 mb-md-0 mb-3 wow fadeInUp" />
                                                  }
                                                </Col>
                                                <Col md={8} lg={10} >
                                                  {
                                                    val_people.people_salutation != null && val_people.people_salutation != '' ?
                                                      <h5 className="text-dark m-0 wow fadeInUp">
                                                        {`${val_people.people_salutation} ${val_people.people_name}`}
                                                      </h5>
                                                      :
                                                      <h5 className="text-dark m-0 wow fadeInUp">
                                                        {val_people.people_name}
                                                      </h5>
                                                  }
                                                  {
                                                    val_people.people_designation != null && val_people.people_designation != '' ?
                                                      <p className="wow fadeInUp">
                                                        <b>{val_people.people_designation}</b>
                                                      </p>
                                                      :
                                                      null
                                                  }
                                                  {/* Introduction  */}
                                                  {
                                                    val_people.people_research_inst != null && val_people.people_research_inst != '' ?
                                                      <p><span dangerouslySetInnerHTML={{ __html: val_people.people_research_inst }}></span></p>
                                                      :
                                                      null
                                                  }

                                                  <div>
                                                    {
                                                      // linkedin link
                                                      val_people.people_linkedin_url != "" && val_people.people_linkedin_url != null ?
                                                        <Link to={val_people.people_linkedin_url} target={'_blank'} className="btn btn-dark rounded-circle falinked"><FaLinkedinIn /></Link> :
                                                        null
                                                    }
                                                    {
                                                        val_people.people_profile_brief != null && val_people.people_profile_brief != '' ?
                                                        <Link onClick={(e) => handleShow(e, val_people.people_id)} target="_blank" class="btn btn-primary rounded-pill px-4 text-uppercase fw-bold py-2 d-inline-block team wow fadeInUp">
                                                          Read More
                                                        </Link>
                                                        :
                                                         //browser link
                                                          val_people.people_url != null && val_people.people_url != "" ?
                                                            <p className="d-inline-block"><Link to={val_people.people_url} target={'_blank'} className="btn btn-primary rounded-pill px-4 py-2">Read More</Link> </p>
                                                           :
                                                          null
                                                    }
                                                  </div>
                                                </Col>
                                              </Row>
                                            </Col>
                                            :
                                            null
                                        }
                                      </>
                                    )
                                  })
                                  :
                                  null
                              }

                            </Row>
                          </div>
                        </Container>
                      </>
                  }
                </>

              )
            })
            :
            <>
              <Container fluid className="bg-visual-inside p-0" >
                <div className="visual-inside-big">
                  <Row className="g-md-0">
                    <Col md={6} className="order-2 order-md-1">
                      <Container className="d-flex align-items-end h-100">
                        <div className="d-inline-block m-0 align-self-end col-lg-6 col-xl-6 py-xl-3 p-2 text-white">
                          <h1 className="fw-light text-white m-0 align-self-center wow fadeInUp">

                          </h1>
                        </div>
                      </Container>
                    </Col>
                    <div className="col-md-6 order-1 order-md-2">
                      <img src={global.variables.strDomainUrl + "images/visual-people.jpg"} className="img-fluid" alt="" title="" />
                    </div>
                  </Row>
                </div>
              </Container>
              <Container className="my-5">
                <Row>
                  <span className="text-center"><b>No records found</b></span>
                </Row>
              </Container>

            </>
          :
          <>
            <Container fluid className="bg-visual-inside p-0" >
              <div className="visual-inside-big">
                <Row className="g-md-0">
                  <Col md={6} className="order-2 order-md-1">
                    <Container className="d-flex align-items-end h-100">
                      <div className="d-inline-block m-0 align-self-end col-lg-6 col-xl-6 py-xl-3 p-2 text-white">
                        <h1 className="fw-light text-white m-0 align-self-center wow fadeInUp">

                        </h1>
                      </div>
                    </Container>
                  </Col>
                  <div className="col-md-6 order-1 order-md-2">
                    <img src={global.variables.strDomainUrl + "images/visual-people.jpg"} className="img-fluid" alt="" title="" />
                  </div>
                </Row>
              </div>
            </Container>
            <Container className="my-5 careers">
              <Row>
                <span className="text-center">Loading...</span>
              </Row>
            </Container>

          </>
      }



      {/* Breadcrumb */}
      <Breadcrumb className="breadcrumb-panel mt-auto">
        <Breadcrumb.Item href={globalVariables.variables.strDomainUrl}>Home</Breadcrumb.Item>
        <Breadcrumb.Item active>People</Breadcrumb.Item>
        <Breadcrumb.Item active>{
          getpeopleCategory != null ?
            getpeopleCategory[0]?.pcategory_name
            :
            null
        }</Breadcrumb.Item>
      </Breadcrumb>


      {/* TEAM DETAILS */}
      <PeoplePopup
      show={show} 
      handleClose={handleClose}
      getpeopleCategory={getpeopleCategory}
      getPeopleList={getPeopleList}
      getPeopleid={getPeopleid}
      />
      {/* TEAM DETAILS */}
    </>
  );
}