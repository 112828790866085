// Roadblock Apis
export const RoadblockApiUrls = {
    get_roadblock_api : "/roadblockApi/getroadblockList"  
  };
  
  // Visual Panel Apis
  export const VisualpanelApiUrls = {
    get_visualpanel_api : "/visualpanelApi/getvisualpanelList"  
  };

// News / Admissions Apis
export const NewsApiUrls = {
  get_news_api : "/newsApi/getnewsList"  
};

// News / Year Apis
export const NewsTypeDistinctYearApiUrls = {
  get_news_type_distinct_year_api : "/newsApi/getnewstypedistinctyear"  
};

// News gallery
export const NewsGalleryApiUrls = {
  get_news_gallery_api : "/newsgalleryApi/getnewsgalleryList"  
};


// News On homepage  Api
export const NewsOnHomePageApiUrls = {
  get_newsOnHomePage_api : "/newsApi/getNewsForHomePageList"  
};

//careers api
export const CareersApiUlrs = {
  get_career_api :"/careerApi/getCareerList"
}

export const PeopleApiUrls = {
  get_people_api : "/peopleApi/getpeopleList"
}

export const ResearchCategoryApiUrls ={
  get_researchCategory_api : "/rcategoryApi/getrcategoryList"
}

export const ResearchTypeApiUrls ={
  get_researchType_api : "/researchApi/researchTypeList"
}

export const ResearchApiUrls ={
  get_research_api : "/researchApi/getresearchList"
}

export const EventApiUrls ={
  get_event_api : "/eventApi/geteventList",
  get_event_gallery_api : "/eventgalleryApi/geteventgalleryList",
  get_event_distinct_year : "/eventApi/getEventdistinctyear"
}