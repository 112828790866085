import { EventApiUrls } from "./api-urls";
import axios from "axios";
import globalVariables from "../global-variables";

export const GetEventList = (p_request_object, p_success_function,navigate) => {
	
    //Get Event List
	axios.post(globalVariables.variables.strApiUrl + EventApiUrls.get_event_api, p_request_object)
	.then((response_list) => {
		if(response_list.data === 'error')
		{
			navigate('/error-page')
		}
		else
		{
			// pass the result in the success function of the respective page
			p_success_function(response_list.data[0]);
		}
	});

};

export const GetEventGalleryList = (p_request_object, p_success_function, navigate) => {

    //Get event gallery List
	axios.post(globalVariables.variables.strApiUrl + EventApiUrls.get_event_gallery_api, p_request_object)
	.then((response_list) => {
        if(response_list.data === 'error')
		{
			navigate('/error-page')
		}
		else
		{
        // pass the result in the success function of the respective page
		p_success_function(response_list.data[0]);
		}
	});

};


export const GetEventTypeDistinctYearList = (p_request_object, p_success_function,navigate) => {
	
    //Get Event type yesr List
	axios.post(globalVariables.variables.strApiUrl + EventApiUrls.get_event_distinct_year, p_request_object)
	.then((response_list) => {
		if(response_list.data === 'error')
		{
			navigate('/error-page')
		}
		else
		{
        // pass the result in the success function of the respective page
		p_success_function(response_list.data[0]);
		}
	});

};