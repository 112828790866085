import React, { useState, useEffect } from "react";
import global from "../../global-variables";
import Header from "../layout/header";
import { Link } from "react-router-dom";
import $ from "jquery";
import WOW from "wowjs";
import { FaLink, FaImage, FaRegFilePdf, FaVideo, FaChevronRight } from "react-icons/fa";

import { Container, Row, Col } from "reactstrap";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Metatag from "../../hooks/Metatag";


export default function Services() {
  const [show, setShow] = useState(false);
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  useEffect(() => {

     // remove bg-header-home 
     $(window).on("scroll", function () {
      $(".navbar").offset().top > 10 ? $(".fixed-top").removeClass("bg-header-home") : $(".fixed-top").removeClass("bg-header-home")
    });
    
    // initiate the wow effect
    new WOW.WOW({
      live: false,
    }).init();


  }, []);


  return (
    <>
      <Header></Header>

      <Metatag title='Sustainable Energy Training and Workshops | Sustainability Assessment'
        description='Sustainable Energy Training and Workshops and Sustainability Assessment Services'
        keywords='Sustainable Energy Training, Sustainable Energy Workshops, Green Energy Training, Green Energy Workshops, Sustainable Energy Training by IIT Bombay, Sustainable Energy Workshops by IIT Bombay, Sustainability Assessment Services by IIT Bombay, Industrial Consultancy on green energy, Sponsored Research on green energy'
      />

      {/* Visual Panel */}
      <Container fluid className="bg-visual-inside p-0" >
        <div className="visual-inside-big visual-container-inside">
          <Container >
            <Row className="g-md-0 rounded-4">
              <Col md={6}>
                <div className="d-flex align-items-end h-100 pb-md-5">
                  <div className="d-inline-block m-0 align-self-end py-xl-3 p-2 text-white">
                    <h1 className="fw-light text-white m-0 align-self-center wow fadeInUp">
                    Services
                    </h1>
                  </div>
                </div>
              </Col>
              <Col md={6} className="mt-auto ms-auto">
                <div className="border-bottom border-5 border-primary rounded-4">
                  <img src={global.variables.strDomainUrl + "images/visual-services.jpg"} className="img-fluid rounded-4" alt="" title="" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>

      {/* Container */}
      <Container className="my-5 pt-5">
        <Row className="g-0">
          <Col md={12} lg={12}>
            <div className="border rounded-4 p-lg-5 p-3 h-100">
              <div className="row d-flex align-items-center">
            <div className="col-lg-3 col-md-4">
            <img src={global.variables.strDomainUrl + "images/pic-training-workshops.jpg"} className="img-fluid rounded-4 mb-md-0 mb-3" alt="Training & Workshops" title="Training & Workshops" />
              </div>
              <div className="col-lg-9 col-md-8 pe-md-2">
                <h4>Training & Workshops</h4>
                <p className="mb-0">GESH conducts training and workshops for industry members, faculties and students. Industry can propose a domain or a module for training and workshops in the area of green energy and sustainability.</p>
              </div>
              </div>

              <hr className="my-5"></hr>

              <div className="row d-flex align-items-center">
              <div className="col-lg-3 col-md-4">
            <img src={global.variables.strDomainUrl + "images/pic-sustainability-assessment.jpg"} className="img-fluid rounded-4 mb-md-0 mb-3" alt="Sustainability Assessment" title="Sustainability Assessment" />
              </div>
              <div className="col-lg-9 col-md-8 pe-md-2">
                <h4>Sustainability Assessment</h4>
                <p className="mb-0">GESH supports conducting sustainability assessments for industry and academic universities. This will also help identify areas where industry research and innovation are required. </p>
              </div>
              </div>

              <hr className="my-5"></hr>

              <div className="row d-flex align-items-center">
              <div className="col-lg-3 col-md-4">
            <img src={global.variables.strDomainUrl + "images/pic-icsr.jpg"} className="img-fluid rounded-4 mb-md-0 mb-3" alt="Industrial Consultancy and Sponsored Research" title="Industrial Consultancy and Sponsored Research" />
              </div>
              <div className="col-lg-9 col-md-8 pe-md-2">
                <h4>Industrial Consultancy and Sponsored Research</h4>
                <p className="mb-0">GESH facilitates industry-academia research at IITB in all the focus areas of green energy and sustainability. </p>
              </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      {/* Breadcrumb */}
      <Breadcrumb className="breadcrumb-panel mt-auto">
        <Breadcrumb.Item href={global.variables.strDomainUrl}>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Services</Breadcrumb.Item>
        <Breadcrumb.Item active>Services</Breadcrumb.Item>
      </Breadcrumb>
    </>
  );
}