import React from 'react'
import Modal from "react-bootstrap/Modal";
import { Link } from 'react-router-dom';
import { Row, Col } from "reactstrap";
import { FaLinkedinIn } from "react-icons/fa";
import global from "../../global-variables";


export default function PeoplePopup({show,handleClose,getPeopleid,getPeopleList}) {

  return (
    <Modal show={show} size="xl" scrollable centered onHide={handleClose} dialogClassName="p-2 popup">
      {
        getPeopleList.map((val) => {
          return (
              val.people_id === getPeopleid ?
      <>
        <Modal.Header closeButton className="border-0 align-items-start bg-primary p-3 px-4 h-auto">
            <Col>
            {
              val.people_salutation != null && val.people_salutation != '' ?
                <h5 className="text-white lh-sm m-0 mx-3">{`${val.people_salutation} ${val.people_name}`}</h5>
                :
                <h5 className="text-white lh-sm m-0 mx-3">{val.people_name}</h5>
            }
          </Col>
        </Modal.Header>
        <Modal.Body className="pt-3">
          <Row className='row-cols-1 px-md-4 p-2' key={val.people_id}>
            <Col md={4} lg={2} className="mb-3 mb-md-0">
              {
                val.people_pic != null && val.people_pic != '' ?
                  <img src={global.variables.strApiUrl + '/people/' + val.people_pic} className="img-fluid rounded-3" alt={val?.people_salutation != null && val?.people_salutation != '' ? `${val?.people_salutation} ${val?.people_name}` : `${val?.people_name}`} title={val?.people_salutation != null && val?.people_salutation != '' ? `${val?.people_salutation} ${val?.people_name}` : `${val?.people_name}`} />
                  :
                  <img src={global.variables.strDomainUrl + "images/people/people-default.jpg"} className="img-fluid rounded-3" alt={val?.people_salutation != null && val?.people_salutation != '' ? `${val?.people_salutation} ${val?.people_name}` : `${val?.people_name}`} title={val?.people_salutation != null && val?.people_salutation != '' ? `${val?.people_salutation} ${val?.people_name}` : `${val?.people_name}`} />
              }
            </Col>
            <Col md={8} lg={10} className="list">

              {
                val.people_designation != null && val.people_designation != '' ?
                  <p className='fw-bold text-dark'><span dangerouslySetInnerHTML={{ __html: val.people_designation }}></span></p>
                  :
                  null

              }
              {/* Description  */}
              {
                val.people_profile_brief != null && val.people_profile_brief != '' ?
                  <p><span dangerouslySetInnerHTML={{ __html: val.people_profile_brief }}></span></p>
                  :
                  null
              }

              {
                //linkedin link
                val.people_linkedin_url != null && val.people_linkedin_url != "" ?
                  <p className="d-inline-block mx-2"><Link to={val.people_linkedin_url} target={'_blank'} className="btn btn-dark rounded-circle falinked"><FaLinkedinIn /></Link> </p> :
                  null
              }

              {
                //browser link
                val.people_url != null && val.people_url != "" ?
                  <p className="d-inline-block"><Link to={val.people_url} target={'_blank'} className="btn btn-primary rounded-pill px-4 py-2">Read More</Link> </p> :
                  null
              }
            </Col>
          </Row>
        </Modal.Body>
      </>
       : null
      
        )
      })
    }
  </Modal >
  )
}
