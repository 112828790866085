import React, { useState, useEffect } from "react";
import global from "../../global-variables";
import Header from "../layout/header";
import { Link, useNavigate, useParams } from "react-router-dom";
import $ from "jquery";
import WOW from "wowjs";
import { FaLink, FaImage, FaRegFilePdf, FaVideo, FaEllipsisH, FaRegCalendarAlt } from "react-icons/fa";
import { GetNewsTypeDistinctYearList } from "../../api/news-type-distinct-year-functions";
import { GetNewsGalleryList } from "../../api/news-gallery-functions";
import Metatag from '../../hooks/Metatag'
import { GetNewsList } from "../../api/news-functions";

import { Container, Row, Col } from "reactstrap";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import FsLightbox from "fslightbox-react";
import Form from 'react-bootstrap/Form';
import globalVariables from "../../global-variables";
import axios from "axios";


export default function Home() {
  //Getting id from url
  let { news_type_pagename } = useParams();
  let qs_news_type_pagename = news_type_pagename

  const [toggler, setToggler] = useState(true);
  const [videos, setvideosToggler] = useState(false);
  const [index, setIndex] = useState(0);
  const navigate = useNavigate();
  const [news, setNewsList] = useState(null);

  const [newstypeyear, setNewsTypeYearList] = useState([]);
  const [news_gallery_toggler, setNewsGalleryToggler] = useState(false);
  const [news_gallery, setNewsGallery] = useState([]);
  
  const [newsTypeId, setNewsTypeId] = useState(null);
  
  const [gallery_key, setGalleryKey] = useState("");
  const [lightbox_video, setLightBoxVideo] = useState([]);

  // useEffect(()=>{
  //   let _obj = {news_type_pagename : qs_news_type_pagename}
  //   axios.post(global.variables.strApiUrl + "/newstypeApi/getnewstypeList",_obj)
  //     .then((response_list) => {
  //       // pass the result in the success function of the respective page
  //       setNewsTypeId(response_list.data[0]);
  //   });

  // },[qs_news_type_pagename])

  // success function when the list is retrived
  const OnGetNewsSuccess = (p_response) => {

    // set the list for looping
    setNewsList(p_response);
  }

  const OnGetNewsTypeYearSuccess = (p_response) => {


    // set the list for looping
    setNewsTypeYearList(p_response);

    setTimeout(() => {
      // get the current news dropdown list
      let _ddlYear = document.getElementById("ddlNewsYear");


      // bind the on change event to the dropdown list
      _ddlYear.onchange = (e) => {

        //call the function to get the news
        // make the object for getting the news list
        let _objNews = new Object();
        // _objNews.news_type_pagename = qs_news_type_pagename;
        _objNews._news_status = 1;
        _objNews._selected_year = e.target.value;
        
        // call the function to get the news list
        GetNewsList(_objNews, OnGetNewsSuccess, navigate);

      };

      // call the onchange event for loading the news for the first time
      // Create a new 'change' event
      var event = new Event('change');

      // Dispatch it.
      _ddlYear.dispatchEvent(event);
    }, 300);
  }

  // success function when news gallery is retrived
  const OnGetNewsGallerySuccess = (p_response) => {


    // set the array in the constant
    setNewsGallery(p_response);

    setGalleryKey(Date.now());

    // using time out for the value to get saved in state 
    setTimeout(() => {
      // trigger the link click to display the news gallery popup
      document.getElementById("lnk_trigger_news_gallery_popup_home").click();
    }, 200);

  }

  // function to invoke photo gallery
  const InvokePhotoGallery = (e, p_news_id) => {

    e.preventDefault();

    // Make an object for getting news gallery
    let _objNewsGallery = new Object();
    _objNewsGallery.gallery_status = 1;
    _objNewsGallery.gallery_news_id = p_news_id;

    // call the function to get the news gallery
    GetNewsGalleryList(_objNewsGallery, OnGetNewsGallerySuccess, navigate);

  };

  // function to invoke video popup
  const InvokeVideoPopup = (e, p_news_video_url) => {

    e.preventDefault();

    let _arrVideos = [];
    _arrVideos.push(p_news_video_url);

    // set the path to the constant for displaying
    setLightBoxVideo(_arrVideos);

    // trigger the link click to display the popup
    document.getElementById("lnk_trigger_video_popup_home").click();

  };


  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  useEffect(() => {

    // remove bg-header-home 
    $(window).on("scroll", function () {
      $(".navbar").offset().top > 10 ? $(".fixed-top").removeClass("bg-header-home") : $(".fixed-top").removeClass("bg-header-home")
    });
    
    // initiate the wow effect
    new WOW.WOW({
      live: false,
    }).init();

  }, [news]);



  // things to be done when the page is loaded
  useEffect(() => {

    //make the object for getting the news type year list
    let _objNewsTypeYear = new Object();
    //news type condition come here as general or event
    _objNewsTypeYear._news_type_id = '5c8432d2-34ea-11ee-ac53-f8b156be9900';
    // _objNewsTypeYear._news_type_id = newsTypeId !== null ? newsTypeId[0]?.news_type_id : '';
    // call the function to get the news type year list
    GetNewsTypeDistinctYearList(_objNewsTypeYear, OnGetNewsTypeYearSuccess, navigate)

  }, [newsTypeId]);


  return (
    <>
      <Header></Header>

      {/* MetaTag */}
      <Metatag title='IIT Bombay Green Energy News | Green Energy Events at IIT Bombay | Sustainable Energy Events at IIT Bombay'
        description='Green Energy News, Green Energy Events, Sustainable Energy News and Events at IIT Bombay'
        keywords="IIT Bombay Green Energy News, Green Energy Events at IIT Bombay, Sustainable Energy Events at IIT Bombay, Sustainable Energy News at IIT Bombay, Sustainable Energy Events at IIT Bombay, Sustainable Energy Events at IIT Bombay"
      />



      {/* {/ News Visual Panel /} */}
      <Container fluid className="bg-visual-inside p-0" >
        <div className="visual-inside-big visual-container-inside">
          <Container>
            <Row className="g-md-0 rounded-4">
              <Col md={6} className="order-2 order-md-1">
                <div className="d-flex align-items-end h-100 pb-5">
                  <div className="d-flex m-0 align-items-center py-xl-3 p-2 text-white wow fadeInUp">
                    <h1 className="fw-light text-white d-inline-block m-0 align-self-center">
                      {/* {news !== null ? news[0]?.news_type_name : ''} - */}
                      News & Events -
                    </h1>
                    <div className="ms-md-3 ms-2">
                      {
                        newstypeyear.length > 0 ?
                          <Form.Select aria-label="Default select example" className="shadow-none" id="ddlNewsYear">
                            {
                              newstypeyear.map((val) => {
                                return (

                                  <option value={val.news_year} key={val.news_year}>{val.news_year}</option>
                                )
                              })
                            }
                          </Form.Select>
                          :
                          <Form.Select aria-label="Default select example" className="shadow-none" id="ddlNewsYear">
                            <option value={new Date().getFullYear()}>{new Date().getFullYear()}</option>
                          </Form.Select>
                      }
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={6} className="order-1 order-md-2 ms-auto">
                <div className="border-bottom border-5 border-primary rounded-4">
                  <img src={global.variables.strDomainUrl + "images/visual-news-events.jpg"} className="img-fluid rounded-4" alt="" title="" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>


      {/* {/ News Container /} */}

      {
        news != null ?

          news.length > 0 ?
            <Container className="mb-5 pt-5 news-details ">
              <Row className="row-cols-1 row-cols-md-1 row-cols-lg-1 row-cols-xl-4 g-4 g-lg-5 g-xl-4 pt-5 grid">
                {
                  news.map((val, index) => {
                    return (
                          <Col key={index}>
                            <div className="border d-flex flex-column h-100 rounded-4">
                              
                                <div md={12} lg={12} className="p-0">
                                  {
                                    val.news_images != null && val.news_images != "" ?
                                      <img src={global.variables.strApiUrl + "/news-main-image" + "/" + val.news_images} alt={val.news_headline} title={val.news_headline} className="img-fluid rounded-4 w-100 mb-md-0 mb-3 wow fadeIn" />
                                      :
                                      null
                                  }
                                </div>

                                <div md={12} lg={12} className="d-flex flex-column align-items-start h-100 p-3">
                                  <h5 className="text-dark wow fadeInUp">
                                    {/* {/ head line /} */}
                                    {val.news_headline}
                                  </h5>
                                  <Row className="row-cols-1 g-2">
                                    <Col className="d-flex wow fadeInUp mt-3">
                                      <div className="icon-w30 flex-grow-0"><FaRegCalendarAlt className="icon-20" /></div>
                                      {
                                        val.news_date != val.news_to_date ?
                                          <>
                                            {
                                              val.from_mon != val.to_mon ?
                                                <>
                                                  {` ${val.from_day}-${val.from_mon}-${val.from_year} to ${val.to_day}-${val.to_mon}-${val.to_year}`}

                                                </>
                                                :
                                                <>
                                                  {` ${val.from_day}-${val.from_mon}-${val.from_year} to ${val.to_day}-${val.to_mon}-${val.to_year}`}
                                                </>
                                            }
                                          </>
                                          :
                                          // when from and to date same 
                                          <>
                                            {
                                              //when from and to date's month is same
                                              val.from_mon != val.to_mon ?
                                                <>
                                                  {` ${val.from_day}-${val.from_mon}-${val.from_year} to ${val.to_day}-${val.to_mon}-${val.to_year}`}
                                                </>
                                                :
                                                <>
                                                  {` ${val.from_day}-${val.from_mon}-${val.from_year}`}
                                                </>
                                            }
                                          </>
                                      }
                                    </Col>
                                  </Row>

                                  <p className="wow fadeInUp">
                                    {val.news_caption != null && val.news_caption != "" && val.news_caption != "null" ? <><br /> Venue: {val.news_caption}</> : null}
                                    {val.news_sourse != null && val.news_sourse != "" && val.news_sourse != "null" ? <><br /> Source: {val.news_sourse}</> : null} <br /></p>
                                  <p className="wow fadeInUp d-none">
                                    {/* {/ Details /} */}
                                    {
                                      val.news_details != null && val.news_details != "null" && val.news_details != "" ?
                                        <div className="mb-3 wow fadeInUp projects"><span dangerouslySetInnerHTML={{ __html: val.news_details }}></span></div>
                                        : null
                                    }
                                  </p>


                                  <div className="d-flex gap-2 mt-auto">
                                    {/* {/ link /} */}
                                    {
                                      val.news_link != null && val.news_link != "null" && val.news_link != "" ?
                                        <Link to={val.news_link} target="_blank" className="btn btn-primary rounded-pill Faicon px-3 py-1 ms-auto mt-auto wow fadeInUp"><FaLink /></Link>
                                        :
                                        null
                                    }

                                    {/* {/ Photo Gallery /} */}
                                    {
                                      parseInt(val.news_gallery_count) > 0 ?
                                        <a className="btn btn-primary rounded-pill Faicon px-3 py-1 ms-auto mt-auto wow fadeInUp" onClick={event => InvokePhotoGallery(event, val.news_id)}><FaImage /></a>
                                        :
                                        null
                                    }

                                    {/* {/ Document /} */}
                                    {
                                      val.news_document != null && val.news_document != "null" && val.news_document != "" ?
                                        <Link to={global.variables.strApiUrl + "/news-documents/" + val.news_document} target="_blank" className="btn btn-primary rounded-pill Faicon px-3 py-1 ms-auto mt-auto wow fadeInUp"><FaRegFilePdf /></Link>
                                        :
                                        null
                                    }

                                    {/* {/ Video /} */}
                                    {
                                      val.news_video_link != null && val.news_video_link != "null" && val.news_video_link != "" ?
                                        <a className="btn btn-primary rounded-pill Faicon px-3 py-1 ms-auto mt-auto wow fadeInUp" onClick={event => InvokeVideoPopup(event, val.news_video_link)}>    <FaVideo /></a>
                                        :
                                        null
                                    }
                                    {/* {
                                      val.news_details != null && val.news_details != "" ?
                                        <Link to={global.variables.strDomainUrl + "news/" + val.news_type_pagename + "/" + val.news_pagename} className="btn btn-primary rounded-pill Faicon px-3 py-1 ms-auto mt-auto wow fadeInUp">
                                          <FaEllipsisH />
                                        </Link>
                                        :
                                        null

                                    } */}
                                    {
                                      val.news_details != null && val.news_details != "" ?
                                        <Link to={global.variables.strDomainUrl + "news-details/" + val.news_pagename} className="btn btn-primary rounded-pill Faicon px-3 py-1 ms-auto mt-auto wow fadeInUp">
                                          <FaEllipsisH />
                                        </Link>
                                        :
                                        null

                                    }
                                  </div>

                                </div>
                              
                            </div>
                          </Col>

                    )
                  })
                }

              </Row>
            </Container>
            :
            <Container className="mb-5 pt-5 news-details">
              <Row className="">
                <span className="text-center">No Records Found</span>
              </Row>
            </Container>
          :
          <Container className="mb-5 pt-5 news-details">
            <Row >
              <span className="text-center">Loading...</span>
            </Row>
          </Container>
      }

      {/* {/ Video popup /}
        {/ this link will open the video popup which will be hidden because the button from the grid is causing issue /} */}
      <a onClick={(e) => {
        e.preventDefault();
        setvideosToggler(!videos);
      }}
        className="d-none"
        id="lnk_trigger_video_popup_home"></a>

      <FsLightbox
        toggler={videos}
        sources={lightbox_video}
      />

      {/* {/ News Gallery popup /}
      {/ this link will open the news gallery popup which will be hidden because the button from the grid is causing issue /} */}
      <a onClick={(e) => {
        e.preventDefault();
        setNewsGalleryToggler(!news_gallery_toggler);
      }}
        className="d-none"
        id="lnk_trigger_news_gallery_popup_home"></a>

      <FsLightbox
        toggler={news_gallery_toggler}
        sources={news_gallery?.map(item => {
					return(
						<div style={{
							display: 'flex', 
							flexDirection: 'column', 
							alignItems: 'center', 
							justifyContent: 'center'
						}} key={item.gallery_id} className="fsDiv">
						<img className="img-fluid" src={`${globalVariables.variables.strApiUrl}/news-gallery-image/${item.gallery_pic}`} alt={item.gallery_title} style={{ objectFit: 'contain' }} />
						<p style={{ textAlign: 'center', color: 'white', fontSize: '16px' }}>{item.gallery_title}</p>
						</div>
					)
				})}
        key={gallery_key}
      />

      {/* {/ Breadcrumb /} */}
      <Breadcrumb className="breadcrumb-panel mt-auto">
        <Breadcrumb.Item href={globalVariables.variables.strDomainUrl}>Home</Breadcrumb.Item>
        <Breadcrumb.Item active>News & Events</Breadcrumb.Item>
        {
          newsTypeId !== null ?
          <Breadcrumb.Item active>{newsTypeId[0].news_type_name}</Breadcrumb.Item>
          : ''
        }
      </Breadcrumb>

    </>
  );
}