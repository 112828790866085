import React, { useState, useEffect} from "react";
import global from "../../global-variables";
import Header, { scrollToTop } from "../layout/header";
import { Link,useParams,useNavigate } from "react-router-dom";
import { FaSearch, FaTag, FaFileAlt, FaComment } from "react-icons/fa";
import $ from "jquery";
import WOW from "wowjs";
import axios from "axios";
import { Container, Row, Col, Alert } from "reactstrap";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Metatag from '../../hooks/Metatag'
import GetblogTags from "./getblogTags";
export default function Blog() {

    const [toggler, setToggler] = useState(true);
    const [index, setIndex] = useState(0);
    const navigate = useNavigate();

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
    

    
    const {blog_search} = useParams();

    let _list_blog_id = []; 

    const qs_blog_search = blog_search; 
    const {blog_year,blog_category_pagename,blog_author_pagename,blog_tags_pagename} = useParams()

    const qs_blog_tags_pagename = blog_tags_pagename;
    
    // const [getMappedBlogTagList , setMappedBlogTagList] = useState();

    // const [setting] = useState([]);

    const [getBlogList , setBlogList] = useState(null);
    
    useEffect(() => {

        if(qs_blog_tags_pagename !== undefined)
        {
            let _obj = new Object();
            _obj.blog_status = 1;
            _obj.blog_tags_pagename = qs_blog_tags_pagename;

            axios.post(global.variables.strApiUrl + "/BlogApi/getMappedBlog", _obj)
            .then((response_list) => {
                if(response_list.data === 'error')
                    {
                        navigate('/error-page')
                    }
                    else
                    {
                        setBlogList(response_list.data[0]);
                    }
            });
        }
        else{

            let _obj = new Object();
            _obj.blog_status = 1;
            _obj.selected_year = blog_year != undefined ? blog_year : '0'
            _obj.blog_category_pagename = blog_category_pagename != undefined ? blog_category_pagename : null;
            _obj.searchstr = qs_blog_search != undefined ? qs_blog_search  : null;
            _obj.blog_author_pagename = blog_author_pagename != undefined ? blog_author_pagename : null;
            _obj.page_count = global.variables.blog_page_count;
                
                axios.post(global.variables.strApiUrl + "/BlogApi/getblogList", _obj)
                    .then((response_list) => {
                        if(response_list.data === 'error')
                        {
                            navigate('/error-page')
                        }
                        else
                        {
                            // pass the result in the success function of the respective page
                            setBlogList(response_list.data[0]);
                        }
                    });
        }

    }, [blog_year,blog_category_pagename,blog_author_pagename,qs_blog_tags_pagename,qs_blog_tags_pagename,qs_blog_search])

    const [getLimitedBlogList , setLimitedBlogList] = useState();

    //Blog list recent post 
    useEffect(() => {
        let _obj = new Object();
        _obj.blog_status = 1;

                axios.post(global.variables.strApiUrl + "/BlogApi/getblogList", _obj)
                .then((response_list) => {
                    if(response_list.data === 'error')
                    {
                        navigate('/error-page')
                    }
                    else
                        {

                        let _list_blog_name = [];

                        if(response_list.data[0]?.length > 5)
                        {
                            for(let i = 0 ; i < 5 ;i++ )
                            {
                                _list_blog_name.push({blog_heading : response_list.data[0][i]?.blog_heading,blog_pagename : response_list.data[0][i]?.blog_pagename}) 
                            }
                        }
                        else
                        {

                            for(let i = 0 ; i < response_list.data[0]?.length;i++ )
                            {
                                _list_blog_name.push({blog_heading : response_list.data[0][i]?.blog_heading,blog_pagename : response_list.data[0][i]?.blog_pagename}) 
                               
                            }
                        }

                         
                        setLimitedBlogList(_list_blog_name);
                    }
                });

    }, [])

    const [getYearByBlogList , setYearByBlogList] = useState([]);

    useEffect(() => {

            axios.post(global.variables.strApiUrl + "/BlogApi/getblogdistinctyear")
                .then((response_list) => {
                    if(response_list.data === 'error')
                    {
                        navigate('/error-page')
                    }
                    else
                    {
                        // pass the result in the success function of the respective page
                        setYearByBlogList(response_list.data[0]);
                    }
                });
       

    }, [])

    //get active category
    const [getcategoryList , setCategoryList] = useState([]);
    
    useEffect(() => {
                axios.post(global.variables.strApiUrl + "/BlogCategoryApi/getcategoryHavingBlogList")
                    .then((response_list) => {
                        if(response_list.data === 'error')
                    {
                        navigate('/error-page')
                    }
                    else
                    {
                        // pass the result in the success function of the respective page
                        setCategoryList(response_list.data[0]);
                    }
                    });
       

    }, [])
    
    useEffect(() => {
        // initiate the wow effect
        new WOW.WOW({
            live: false,
        }).init();
    }, [getBlogList])

       //serach  function
       function url_generator(url) {
        var url_generated = "";
        // For local host
        if (window.location.href.indexOf('localhost') != -1) {
            url_generated = url;
        }
    
        //    // For my machine Server
        else if (window.location.href.indexOf('192.168.2.202') != -1) {
            
            url_generated = url;  
        }
    
        // For Live server
        else {
            url_generated = url;
        }
       
        return url_generated;
    }

    useEffect(()=>{

        $("#btnsearch").on("click", function (e) {
            $("#searcherr").html("");
            if ($("#txtSearch").val() == "") {
                $("#searcherr").html("Please enter search string");
                return;
            }
            else {
                window.location.href =  (global.variables.strDomainUrl + "blog-search/" + $("#txtSearch").val());
            }
        });
        
        
        $('#txtSearch').keypress(function (e) {
            var key = e.which;
            if (key == 13)  // the enter key code
            {
                if ($("#txtSearch").val() == "") {
                    $("#searcherr").html("Please enter search string");
                    return;
                }
                else {
                    <Link to=""></Link>
                    window.location.href = url_generator(global.variables.strDomainUrl + "blog-search/" + $("#txtSearch").val());
                }
                return false;
            }
        });
        
        $("#btnclickfocus").on("click",function(){

            if ($("#txtSearch").val() == "") {
                $("#searcherr").html("Please enter search string");
                return;
            }
            else {
                <Link to=""></Link>
                window.location.href = url_generator(global.variables.strDomainUrl + "blog-search/" + $("#txtSearch").val());
            }
        })

    })
    
    
   

    return (
        <>
            <Header main_className="bg-header"></Header>

            {/* Metatag  */}
            <Metatag title='Blogs of Green Energy and Sustainability (GESH)'
                keywords='Blogs of Green Energy and Sustainability (GESH)'
                description='Blogs of Green Energy and Sustainability (GESH)' />

            {/* Visual Panel */}
            <Container fluid className="bg-visual-inside p-0" >
                <div className="visual-inside-big visual-container-inside">
                <Container >
                    <Row className="g-md-0 rounded-4">
                    <Col md={6}>
                        <div className="d-flex align-items-end h-100 pb-md-5">
                        <div className="d-inline-block m-0 align-self-end py-xl-3 p-2 text-white">
                            <h1 className="fw-light text-white m-0 align-self-center wow fadeInUp">
                            Blog <span className="h3 fw-normal">{blog_year != undefined ? `- ${blog_year}` : ''}{blog_category_pagename !== undefined ? `- ${getBlogList !== null ? getBlogList[0]?.blog_category_name : null}` : ''}{blog_author_pagename !== undefined ? `- ${getBlogList !== null ? getBlogList[0]?.blog_author_name : null}` : ''}{blog_tags_pagename !== undefined ? `- ${getBlogList !== null && getBlogList[0]?.blog_tags_name !== undefined ? getBlogList[0]?.blog_tags_name : ''}` : ''}</span>
                            </h1>
                        </div>
                        </div>
                    </Col>
                    <Col md={6} className="mt-auto ms-auto">
                        <div className="border-bottom border-5 border-primary rounded-4">
                        <img src={global.variables.strDomainUrl + "images/visual-blog.jpg"} className="img-fluid rounded-4" alt="" title="" />
                        </div>
                    </Col>
                    </Row>
                </Container>
                </div>
            </Container>

            <Container className="my-5 pt-4 pt-xl-5">
                    {
                        getBlogList != null ?
                            getBlogList.length > 0 ?
                            
                            <Row className="">
                                <Col lg="4" xl="3" className="order-lg-2 mb-3">
                                    <div className="bg-primary bg-opacity-10 rounded-4 py-4 px-4 border">
                                        <div className="form-group mb-0">
                                            <div className="input-group">
                                                <input name="txtBlogsearch" type="text" id="txtSearch" className="form-control form-control-sm py-2 ps-3 pe-4 rounded-pill" placeholder="Search Blog" />
                                                
                                                <div className="search-btn">
                                                    <Link id='btnclickfocus'><FaSearch /></Link>
                                                </div> 
                                            </div>
                                            <span id="searcherr" class="text-danger small mx-2"></span>
                                        </div>
                                        <div className="navigation">
                                            <ul className="recent">
                                                <li>
                                                    <span className="blg-mbl-head"><Link to="/blog" onClick={scrollToTop}>Blog Home</Link></span>
                                                </li>
                                            </ul>
                                            <ul className="recent">
                                                <li>
                                                    <span className="blg-mbl-head">Recent Posts</span>
                                                    <ul> 
                                                        {
                                                            getLimitedBlogList?.length > 0 ? 
                                                                getLimitedBlogList.map((val_limited_blog,index)=>{
                                                                    
                                                                    return(
                                                                        <li key={index}><Link to={`${global.variables.strDomainUrl}blog-details/${val_limited_blog.blog_pagename}`}>{val_limited_blog.blog_heading}</Link></li>
                                                                    )
                                                                })
                                                            :
                                                            null
                                                        }
                                                        
                                                    </ul>
                                                </li>
                                            </ul> 
                                            <ul className="recent">
                                                <li>
                                                    <span className="blg-mbl-head">Archives</span>
                                                    <div>
                                                        <ul>
                                                        {
                                                            getYearByBlogList.length > 0 ? 
                                                            getYearByBlogList.map((val_year_blog,index)=>{
                                                                    return(
                                                                        <li key={index}><Link to={`${global.variables.strDomainUrl}blog/${val_year_blog.blog_year}`} onClick={scrollToTop} id="ddlBlogYear">{val_year_blog.blog_year} ({val_year_blog.blog_year_wise_count})</Link></li>
                                                                    )
                                                                })
                                                            :
                                                            null
                                                        } 
                                                        </ul>
                                                    </div>
                                                </li>
                                            </ul> 
                                            <ul className="recent">
                                                <li>
                                                    <span className="blg-mbl-head">Categories</span>
                                                    <ul>
                                                        {
                                                            getcategoryList.length > 0 ?
                                                                getcategoryList.map((val_cat)=>{
                                                                    
                                                                    return(

                                                                        <li><Link to={`${global.variables.strDomainUrl}blog-category/${val_cat.blog_category_pagename}`} onClick={scrollToTop}>{val_cat.blog_category_name} ({val_cat.category_hav_actblogcount})</Link></li> 
                                                                    )
                                                                })
                                                            :
                                                                null
                                                        }
                                                    </ul>
                                                </li>
                                            </ul> 
                                        </div>
                                    </div>
                                </Col>
                                <Col lg="8" xl="9" className="order-lg-1">
                                <div class="border rounded-4 px-lg-5 p-4 py-lg-5 h-100">
                                    {
                                        qs_blog_search != "" && qs_blog_search != null ?
                                        <div><p className="fw-bold">Search for <i>'{qs_blog_search}'</i>, {getBlogList.length} article(s) found</p></div>
                                        : null
                                    }
                                    
                                    <ul className="blog-list">
                                        {
                                            getBlogList.map((blog_val,index)=>{
                                                return(
                                                    blog_val.blog_picture != null && blog_val.blog_picture != '' ?
                                                        <li key={index}>
                                                            <Row>
                                                                <Col xl="5" className="mb-lg-0 mb-2 text-lg-left">
                                                                    <Link to={`${global.variables.strDomainUrl}blog-details/${blog_val.blog_pagename}`}>
                                                                        {/* <img src={global.variables.strApiUrl + "/blog-picture/" + blog_val.blog_picture} alt="Title Comes Here" title="Title Comes Here" className="img-fluid rounded-2 mb-3 mb-xl-0 d-none" /> */}
                                                                        <img src={global.variables.strApiUrl + "/blog-picture/" + blog_val.blog_picture} alt={blog_val.blog_heading} title={blog_val.blog_heading} className="img-fluid rounded-3 mb-3 mb-xl-0 border" />
                                                                    </Link>
                                                                </Col>
                                                                <Col>
                                                                    <h4><Link className="text-dark text-decoration-none" to={`${global.variables.strDomainUrl}blog-details/${blog_val.blog_pagename}`}>{blog_val.blog_heading}</Link></h4>
                                                                    <p className="mb-1" title="Continue reading">
                                                                        <Link className="wow fadeInUp" to={`${global.variables.strDomainUrl}blog-details/${blog_val.blog_pagename}`}>{blog_val.blog_caption}</Link>
                                                                    </p>
                                                                    <div className="tg-list mb-2">
                                                                        <div className="fst-italic ml-0">
                                                                            Posted on {blog_val.blog_date}<span className="d-block d-md-inline-flex">
                                                                            {
                                                                                blog_val.blog_author_name != null && blog_val.blog_author_name != ''? 
                                                                                <>
                                                                                    <span className="d-none d-md-inline-flex me-1">,</span>by&nbsp; 
                                                                                    <Link to={`${global.variables.strDomainUrl}blog-authors/${blog_val.blog_author_pagename}`} onClick={scrollToTop}>{blog_val.blog_author_name}  </Link>
                                                                                </>
                                                                                    :
                                                                                    // <Link to={`${global.variables.strDomainUrl}blog-details/${blog_val.blog_pagename}`}>Administrator  </Link>
                                                                                    null
                                                                            }
                                                                            
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="clearfix"></div>
                                                                    {
                                                                        blog_val.map_tags_count != null && blog_val.map_tags_count != '' ?
                                                                            <div className="blog-tags d-flex align-items-center flex-wrap w-100 mb-2  wow fadeInUp mt-1">
                                                                                <span className="tags"><FaTag/>&nbsp;Tags: </span> 
                                                                                <ul className="tag-list">
                                                                                    <GetblogTags _blog_id={blog_val.blog_id} _scrollToTop={scrollToTop} _navigate={navigate}/>
                                                                                    
                                                                                </ul>
                                                                                <div className="clearfix"></div>
                                                                            </div>
                                                                        :
                                                                        null
                                                                    }

                                                                    <div className="links mt-1">
                                                                        <ul className="links-list">
                                                                            <li><FaFileAlt/>&nbsp;Posted In: <Link to={`${global.variables.strDomainUrl}blog-category/${blog_val.blog_category_pagename}`} onClick={scrollToTop}>{blog_val.blog_category_name}</Link></li>
                                                                            {
                                                                                blog_val.blog_comment_count > 0 ?
                                                                                    <li><FaComment/>&nbsp;Comments ({blog_val.blog_comment_count})</li>
                                                                                :
                                                                                    null

                                                                            }
                                                                        </ul>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </li>
                                                    :
                                                    <li key={index}>
                                                    <Row>
                                                        <Col>
                                                            <h4><Link className="text-dark text-decoration-none" to={`${global.variables.strDomainUrl}blog-details/${blog_val.blog_pagename}`}>{blog_val.blog_heading}</Link></h4>
                                                            <p className="mb-1" title="Continue reading">
                                                                <Link className="wow fadeInUp" to={`${global.variables.strDomainUrl}blog-details/${blog_val.blog_pagename}`}>{blog_val.blog_caption}</Link>
                                                            </p>
                                                            <div className="tg-list mb-2">
                                                                <div className="fst-italic ml-0">
                                                                    Posted on {blog_val.blog_date}<span className="d-block d-md-inline-flex">
                                                                    {
                                                                        blog_val.blog_author_name != null && blog_val.blog_author_name != ''? 
                                                                        <>
                                                                            <span className="d-none d-md-inline-flex me-1">,</span>by&nbsp; 
                                                                            <Link to={`${global.variables.strDomainUrl}blog-authors/${blog_val.blog_author_pagename}`} onClick={scrollToTop}>{blog_val.blog_author_name}  </Link>
                                                                        </>
                                                                            :
                                                                            // <Link to={`${global.variables.strDomainUrl}blog-details/${blog_val.blog_pagename}`}>Administrator  </Link>
                                                                            null
                                                                    }
                                                                    
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="clearfix"></div>
                                                            {
                                                                blog_val.map_tags_count != null && blog_val.map_tags_count != '' ?
                                                                    <div className="blog-tags d-flex align-items-center flex-wrap w-100 mb-2  wow fadeInUp mt-1">
                                                                        <span className="tags"><FaTag/>&nbsp;Tags: </span> 
                                                                        <ul className="tag-list">
                                                                            <GetblogTags _blog_id={blog_val.blog_id} _scrollToTop={scrollToTop} _navigate={navigate}/>
                                                                            
                                                                        </ul>
                                                                        <div className="clearfix"></div>
                                                                    </div>
                                                                :
                                                                null
                                                            }

                                                            <div className="links mt-1">
                                                                <ul className="links-list">
                                                                    <li><FaFileAlt/>&nbsp;Posted In: <Link to={`${global.variables.strDomainUrl}blog-category/${blog_val.blog_category_pagename}`} onClick={scrollToTop}>{blog_val.blog_category_name}</Link></li>
                                                                    {
                                                                        blog_val.blog_comment_count > 0 ?
                                                                            <li><FaComment/>&nbsp;Comments ({blog_val.blog_comment_count})</li>
                                                                        :
                                                                            null

                                                                    }
                                                                </ul>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </li>
                                                )
                                            })
                                        } 
                                        
                                    </ul>
                                    </div>
                                </Col>
                            </Row>   
                    :
                    // <Container className="p-md-5 p-4 content-bg bordr bg-white rounded-3 shadow-sm">.
                        // <Row>
                        //     <span className="text-center"><b>No records found</b></span>
                        // </Row>
                        <Row>
                                <Col lg="4" xl="3" className="order-lg-2 mb-3">
                                    <div className="blog-right rounded-3  py-3">
                                        <div className="form-group">
                                            <div className="input-group">
                                                <input name="txtBlogsearch" type="text" id="txtSearch" className="form-control form-control-sm py-2 ps-3 pe-4 rounded-pill" placeholder="Search Blog" />
                                                
                                                <div className="search-btn">
                                                    <Link id='btnclickfocus'><FaSearch /></Link>
                                                </div> 
                                            </div>
                                            <span id="searcherr" class="text-danger small mx-2"></span>
                                        </div>
                                        <div className="navigation">
                                            <ul className="recent">
                                                <li>
                                                    <span className="blg-mbl-head"><Link to="/blog" onClick={scrollToTop}>Blog Home</Link></span>
                                                </li>
                                            </ul>
                                            <ul className="recent">
                                                <li>
                                                    <span className="blg-mbl-head">Recent Posts</span>
                                                    <ul> 
                                                        {
                                                            getLimitedBlogList?.length > 0 ? 
                                                                getLimitedBlogList.map((val_limited_blog,index)=>{
                                                                    
                                                                    return(
                                                                        <li key={index}><Link to={`${global.variables.strDomainUrl}blog-details/${val_limited_blog.blog_pagename}`}>{val_limited_blog.blog_heading}</Link></li>
                                                                    )
                                                                })
                                                            :
                                                            null
                                                        }
                                                        
                                                    </ul>
                                                </li>
                                            </ul> 
                                            <ul className="recent">
                                                <li>
                                                    <span className="blg-mbl-head">Archives</span>
                                                    <div>
                                                        <ul>
                                                        {
                                                            getYearByBlogList.length > 0 ? 
                                                            getYearByBlogList.map((val_year_blog,index)=>{
                                                                    return(
                                                                        <li key={index}><Link to={`${global.variables.strDomainUrl}blog/${val_year_blog.blog_year}`} onClick={scrollToTop} id="ddlBlogYear">{val_year_blog.blog_year} ({val_year_blog.blog_year_wise_count})</Link></li>
                                                                    )
                                                                })
                                                            :
                                                            null
                                                        } 
                                                        </ul>
                                                    </div>
                                                </li>
                                            </ul> 
                                            <ul className="recent">
                                                <li>
                                                    <span className="blg-mbl-head">Categories</span>
                                                    <ul>
                                                        {
                                                            getcategoryList.length > 0 ?
                                                                getcategoryList.map((val_cat)=>{
                                                                    
                                                                    return(

                                                                        <li><Link to={`${global.variables.strDomainUrl}blog-category/${val_cat.blog_category_pagename}`} onClick={scrollToTop}>{val_cat.blog_category_name} ({val_cat.category_hav_actblogcount})</Link></li> 
                                                                    )
                                                                })
                                                            :
                                                                null
                                                        }
                                                    </ul>
                                                </li>
                                            </ul> 
                                        </div>
                                    </div>
                                </Col>
                                <Col lg="8" xl="9" className="order-lg-1">
                                    {
                                        qs_blog_search != "" && qs_blog_search != null ?
                                        <div><p className="fw-bold">Search for <i>'{qs_blog_search}'</i>, {getBlogList.length} article(s) found</p></div>
                                        : null
                                    }
                                    
                                    <ul className="blog-list">
                                        {
                                            getBlogList.map((blog_val,index)=>{
                                                return(
                                                    blog_val.blog_picture != null && blog_val.blog_picture != '' ?
                                                        <li key={index} className="blog-box tm-mng-bx bg-white p-3">
                                                            <Row>
                                                                <Col xl="5" className="mb-lg-0 mb-2 text-lg-left">
                                                                    <Link to={`${global.variables.strDomainUrl}blog-details/${blog_val.blog_pagename}`}>
                                                                        {/* <img src={global.variables.strApiUrl + "/blog-picture/" + blog_val.blog_picture} alt="Title Comes Here" title="Title Comes Here" className="img-fluid rounded-2 mb-3 mb-xl-0 d-none" /> */}
                                                                        <img src={global.variables.strApiUrl + "/blog-picture/" + blog_val.blog_picture} alt={blog_val.blog_heading} title={blog_val.blog_heading} className="img-fluid rounded-3 mb-3 mb-xl-0 border" />
                                                                    </Link>
                                                                </Col>
                                                                <Col>
                                                                    <h2 className="font-weight-semibold mb-1"><Link to={`${global.variables.strDomainUrl}blog-details/${blog_val.blog_pagename}`}>{blog_val.blog_heading}</Link></h2>
                                                                    <p className="mb-1" title="Continue reading">
                                                                        <Link className="wow fadeInUp" to={`${global.variables.strDomainUrl}blog-details/${blog_val.blog_pagename}`}>{blog_val.blog_caption}</Link>
                                                                    </p>
                                                                    <div className="tg-list mb-2">
                                                                        <div className="fst-italic ml-0">
                                                                            Posted on {blog_val.blog_date}<span className="d-block d-md-inline-flex">
                                                                            {
                                                                                blog_val.blog_author_name != null && blog_val.blog_author_name != ''? 
                                                                                <>
                                                                                    <span className="d-none d-md-inline-flex me-1">,</span>by&nbsp; 
                                                                                    <Link to={`${global.variables.strDomainUrl}blog-authors/${blog_val.blog_author_pagename}`} onClick={scrollToTop}>{blog_val.blog_author_name}  </Link>
                                                                                </>
                                                                                    :
                                                                                    // <Link to={`${global.variables.strDomainUrl}blog-details/${blog_val.blog_pagename}`}>Administrator  </Link>
                                                                                    null
                                                                            }
                                                                            
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="clearfix"></div>
                                                                    {
                                                                        blog_val.map_tags_count != null && blog_val.map_tags_count != '' ?
                                                                            <div className="blog-tags d-flex align-items-center flex-wrap w-100 mb-2  wow fadeInUp mt-1">
                                                                                <span className="tags"><FaTag/>&nbsp;Tags: </span> 
                                                                                <ul className="tag-list">
                                                                                    <GetblogTags _blog_id={blog_val.blog_id} _scrollToTop={scrollToTop} _navigate={navigate}/>
                                                                                    
                                                                                </ul>
                                                                                <div className="clearfix"></div>
                                                                            </div>
                                                                        :
                                                                        null
                                                                    }

                                                                    <div className="links mt-1">
                                                                        <ul className="links-list">
                                                                            <li><FaFileAlt/>&nbsp;Posted In: <Link to={`${global.variables.strDomainUrl}blog-category/${blog_val.blog_category_pagename}`} onClick={scrollToTop}>{blog_val.blog_category_name}</Link></li>
                                                                            {
                                                                                blog_val.blog_comment_count > 0 ?
                                                                                    <li><FaComment/>&nbsp;Comments ({blog_val.blog_comment_count})</li>
                                                                                :
                                                                                    null

                                                                            }
                                                                        </ul>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </li>
                                                    :
                                                        <li key={index} className="blog-box tm-mng-bx bg-white p-3">
                                                            <Row> 
                                                                <Col>
                                                                    <h2 className="font-weight-semibold mb-1"><Link to={`${global.variables.strDomainUrl}blog-details/${blog_val.blog_pagename}`}>{blog_val.blog_heading}</Link></h2>
                                                                    <p className="mb-1" title="Continue reading">
                                                                        <Link to={`${global.variables.strDomainUrl}blog-details/${blog_val.blog_pagename}`}>{blog_val.blog_caption}</Link>
                                                                    </p>
                                                                    <div className="tg-list mb-2">
                                                                        <div className="fst-italic ml-0">
                                                                            Posted on {blog_val.blog_date}<span className="d-block d-md-inline-flex">
                                                                            {
                                                                                blog_val.blog_author_name != null && blog_val.blog_author_name != ''? 
                                                                                <>
                                                                                    <span className="d-none d-md-inline-flex me-1">,</span>by&nbsp; 
                                                                                    <Link to={`${global.variables.strDomainUrl}blog-authors/${blog_val.blog_author_pagename}`} onClick={scrollToTop}>{blog_val.blog_author_name}  </Link>
                                                                                </>
                                                                                    :
                                                                                    // <Link to={`${global.variables.strDomainUrl}blog-details/${blog_val.blog_pagename}`}>Administrator  </Link>
                                                                                    null
                                                                            }
                                                                        </span></div>
                                                                    </div>
                                                                    <div className="clearfix"></div>
                                                                    {
                                                                        blog_val.map_tags_count != null && blog_val.map_tags_count != '' ?
                                                                            <div className="blog-tags d-flex align-items-center flex-wrap w-100 mb-2  wow fadeInUp mt-1">
                                                                                <span className="tags"><FaTag/>&nbsp;Tags: </span> 
                                                                                <ul className="tag-list">
                                                                                    <GetblogTags _blog_id={blog_val.blog_id} _scrollToTop={scrollToTop} _navigate={navigate}/>
                                                                                </ul>
                                                                                <div className="clearfix"></div>
                                                                            </div>
                                                                        :
                                                                        null
                                                                    }

                                                                    <div className="links mt-1">
                                                                        <ul className="links-list">
                                                                            <li><FaFileAlt/>&nbsp;Posted In: <Link to={`${global.variables.strDomainUrl}blog-category/${blog_val.blog_category_pagename}`} onClick={scrollToTop}>{blog_val.blog_category_name}</Link></li>
                                                                            {
                                                                                blog_val.blog_comment_count > 0 ?
                                                                                    <li><FaComment/>&nbsp;Comments ({blog_val.blog_comment_count})</li>
                                                                                :
                                                                                    null

                                                                            }
                                                                        </ul>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </li>
                                                )
                                            })
                                        } 
                                        
                                    </ul>
                                </Col>
                            </Row> 
                    // </Container>
                    :
                    // <Container className="p-md-5 p-4 content-bg bordr bg-white rounded-3 shadow-sm">
                        <Row>
                            <span className="text-center">Loading...</span>
                        </Row>
                    // </Container>
                }
            </Container >

            {/* Breadcrumb */}
            <Container Container fluid className="small pt-3 p-0 bg-white d-flex justify-content-center mt-auto" >
                <Breadcrumb>
                    <Breadcrumb.Item href={global.variables.strDomainUrl}>Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Blog</Breadcrumb.Item>
                </Breadcrumb>
            </Container>
            {/* Breadcrumb */}
        </>
    );
}