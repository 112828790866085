import React, { useEffect, useState } from 'react';
import WOW from 'wowjs';
import { Link } from "react-router-dom";
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { HiOutlineMenuAlt2 } from "react-icons/hi";

import globalVariables from '../../global-variables';

export default function MappedCategoryNav(props) {

 
    const submenutitle = props.submenutitle;
    const getresearchTypeList = props.getresearchTypeList
    const researchCategory = props.researchCategory
    const qs_rcategory_pagename = props.qs_rcategory_pagename
    useEffect(() => {
        // initiate the wow effect
        new WOW.WOW({
            live: false
        }).init();

    }, []);

     //variable to close left navbar on link click
     const [expanded, setExpanded] = useState(false);

    return (
        <Navbar  expanded={expanded}  collapseonselect expand="md" className='d-block p-0'>
            <Navbar.Toggle onClick={() => setExpanded(expanded ? false : "expanded")} className="offcanvas-toggle shadow-none w-100 animated fadeInUp" aria-controls={'offcanvas-menu'}>
                <Link className="btn btn-outline-secondary rounded-pill px-4 py-2 w-100 text-start d-flex justify-content-between align-items-center d-md-none">{submenutitle} <HiOutlineMenuAlt2 /></Link>
            </Navbar.Toggle>
            <Navbar.Offcanvas id={'offcanvasTop'} aria-labelledby={'offcanvasTop'} placement="start" responsive="md" className="p-md-0 p-2 d-block">
                <Offcanvas.Header closeButton onClick={() => setExpanded(false)}>
                    <Offcanvas.Title>
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <ul className='left-navbar overflow-hidden position-relative w-100 mb-3'>
                    {
                        getresearchTypeList != null ? 
                            getresearchTypeList.length > 0 ?
                                getresearchTypeList?.map((val,index)=>{
                                    return(
                                        <>
                                            {
                                                <li className='text-uppercase fw-bold'><Link to={globalVariables.variables.strDomainUrl + "research-projects/" + val.rtype_pagename}>{val.rtype_name}</Link></li>
                                            }
                                        
                                        </>

                                    )
                                })
                                :
                                // <span className="text-center"><b>No records found</b></span>
                                null
                        :
                            <span className="text-center">Loading...</span>
                    }
                    {/* <li><Link to="/studentprojects" className={props.studentprojects_class}>Student TA Projects</Link></li> */}
                </ul>
                <ul className='nav-link overflow-hidden position-relative w-100' id="v-pills-tab">
                {
                    researchCategory != null ?
                        researchCategory.length > 0 ?
                            researchCategory.map((val)=>{
                                return(
                                    <>
                                        {
                                            val.rcategory_pagename === qs_rcategory_pagename ?
                                                <li className='nav-sublink'><Link onClick={() => setExpanded(false)} to={globalVariables.variables.strDomainUrl + "research/" + val.rcategory_pagename} className='active'>{val.rcategory_name}</Link></li>
                                                :
                                                <li className='nav-sublink'><Link onClick={() => setExpanded(false)} to={globalVariables.variables.strDomainUrl + "research/" + val.rcategory_pagename} >{val.rcategory_name}</Link></li>

                                        }
                                    
                                    </>

                                )

                            })
                            :
                            <span className="text-center"><b>No records found</b></span>
                        :
                        <span className="text-center">Loading...</span>
                    }
                </ul>
            </Navbar.Offcanvas>
        </Navbar>
    )
} 