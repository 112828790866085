import React, { useState, useEffect, useRef } from "react";
import global from "../../global-variables";
import Header, { scrollToTop } from "../layout/header";
import { Link, useParams, useNavigate } from "react-router-dom";
import { FaSearch, FaTag, FaEnvelope, FaFacebookF, FaTwitter, FaLinkedinIn, FaWhatsapp, FaFileAlt, FaComments } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import $ from "jquery";
import WOW from "wowjs";
import { Container, Row, Col, Collapse, Button, CardBody, Card } from "reactstrap";
import { Modal } from 'react-bootstrap';
import axios from "axios";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import globalVariables from "../../global-variables";
import Metatag from '../../hooks/Metatag'
import GetblogTags from "./getblogTags";
import GetBlogComments from "./get-blog-comments";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FocusError } from 'focus-formik-error';
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';
import {
    Input,
    FormFeedback
} from "reactstrap";

export default function BlogDetails() {
    const [toggler, setToggler] = useState(true);
    const [index, setIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };


    const navigate = useNavigate();
    const captchaRef = useRef();

    const [getBlogPerticularList, setBlogPerticularList] = useState(null);

    useEffect(() => {

        setTimeout(() => {
            if (captchaRef.current != undefined && captchaRef.current) {
                loadCaptchaEnginge(6, 'gray', 'white');

            }

        }, 300);


    }, [getBlogPerticularList]);

    const [setting] = useState([]);

    const { blog_pagename } = useParams()

    const qs_blog_pagename = blog_pagename;

    //modal for comment submit
    const [show1, setShow1] = useState(false);
    const handleCloseSuccess = () => setShow1(false);

    useEffect(() => {
        let _obj = new Object();
        _obj.blog_status = 1;
        _obj.blog_pagename = qs_blog_pagename

        axios.post(global.variables.strApiUrl + "/BlogApi/getblogList", _obj)
            .then((response_list) => {
                if (response_list.data === 'error') {
                    navigate('/error-page')
                }
                else {
                    // pass the result in the success function of the respective page
                    setBlogPerticularList(response_list.data[0]);
                }
            });


    }, [qs_blog_pagename])

    const [getLimitedBlogList, setLimitedBlogList] = useState([]);

    //Blog list recent post 
    useEffect(() => {
        let _obj = new Object();
        _obj.blog_status = 1;

        axios.post(global.variables.strApiUrl + "/BlogApi/getblogList", _obj)
            .then((response_list) => {
                if (response_list.data === 'error') {
                    navigate('/error-page')
                }
                else {
                    let _list_blog_name = [];
                    if (response_list.data[0]?.length > 5) {
                        for (let i = 0; i < 5; i++) {
                            _list_blog_name.push({ blog_heading: response_list.data[0][i]?.blog_heading, blog_pagename: response_list.data[0][i]?.blog_pagename })
                        }
                    }
                    else {

                        for (let i = 0; i < response_list.data[0]?.length; i++) {
                            _list_blog_name.push({ blog_heading: response_list.data[0][i]?.blog_heading, blog_pagename: response_list.data[0][i]?.blog_pagename })
                        }
                    }

                    setLimitedBlogList(_list_blog_name);
                }

            });

    }, [])

    const [getYearByBlogList, setYearByBlogList] = useState([]);

    useEffect(() => {

        axios.post(global.variables.strApiUrl + "/BlogApi/getblogdistinctyear")
            .then((response_list) => {
                if (response_list.data === 'error') {
                    navigate('/error-page')
                }
                else {
                    // pass the result in the success function of the respective page
                    setYearByBlogList(response_list.data[0]);
                }
            });


    }, [])

    //serach  function
    function url_generator(url) {
        var url_generated = "";
        // For local host
        if (window.location.href.indexOf('localhost') != -1) {
            url_generated = url;
        }

        //    // For my machine Server
        else if (window.location.href.indexOf('192.168.2.202') != -1) {
            // url_generated = "/a/amnsinternationalschool" + url;
            url_generated = url;
        }

        // For Live server
        else {
            url_generated = url;
        }
        // alert(url_generated);
        return url_generated;
    }

    useEffect(() => {

        $("#btnsearch").on("click", function (e) {
            $("#searcherr").html("");
            if ($("#txtSearch").val() == "") {
                $("#searcherr").html("Please enter search string");
                return;
            }
            else {
                window.location.href = (global.variables.strDomainUrl + "blog-search/" + $("#txtSearch").val());
            }
        });


        $('#txtSearch').keypress(function (e) {
            var key = e.which;
            if (key == 13)  // the enter key code
            {
                if ($("#txtSearch").val() == "") {
                    $("#searcherr").html("Please enter search string");
                    return;
                }
                else {
                    <Link to=""></Link>
                    window.location.href = url_generator(global.variables.strDomainUrl + "blog-search/" + $("#txtSearch").val());
                }
                return false;
            }
        });

        $("#btnclickfocus").on("click", function () {

            if ($("#txtSearch").val() == "") {
                $("#searcherr").html("Please enter search string");
                return;
            }
            else {
                <Link to=""></Link>
                window.location.href = url_generator(global.variables.strDomainUrl + "blog-search/" + $("#txtSearch").val());
            }
        })
    })

    //get active category
    const [getcategoryList, setCategoryList] = useState([]);

    useEffect(() => {
        axios.post(global.variables.strApiUrl + "/BlogCategoryApi/getcategoryHavingBlogList")
            .then((response_list) => {
                if (response_list.data === 'error') {
                    navigate('/error-page')
                }
                else {
                    // pass the result in the success function of the respective page
                    setCategoryList(response_list.data[0]);
                }
            });


    }, [])

    useEffect(() => {
        // initiate the wow effect
        new WOW.WOW({
            live: false,
        }).init();
    }, [getBlogPerticularList])

    const regexEmail = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}/);
    // validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            blog_comment_name: '',
            blog_comment_email: '',
            blog_comment: '',
            blog_captcha: '',
        },

        validationSchema: Yup.object({
            //   blog_category_cat_id: Yup.string().required("Please select blog category"),
            blog_comment_name: Yup.string().required("Enter name"),
            blog_comment_email: Yup.string().matches(regexEmail, "Enter valid email address").required("Enter email"),
            blog_comment: Yup.string().required("Enter comment"),
            blog_captcha: Yup.string().required("Enter captcha code"),

        }),

        onSubmit: (values) => {

            // save the file in the constant
            if (validateCaptcha(validation.values.blog_captcha) === true) {
                const newComment = {
                    blog_comment_blog_id: getBlogPerticularList[0].blog_id,
                    blog_comment_name: values["blog_comment_name"],
                    blog_comment_email: values["blog_comment_email"],
                    blog_comment: values["blog_comment"],
                    user_id: localStorage.getItem("user_id"),
                }
                axios.post(global.variables.strApiUrl + "/BlogCommentApi/addblogcomment", newComment)
                    .then((response) => {
                        if (response.data[0].length > 0) {
                            // pass the result in the success function of the respective page
                            setShow1(true);
                        }
                    })

            }

            else {

                validation.setFieldError("blog_captcha", " Entered captcha does not match. Please carefully re-enter the characters as shown in the image and try again.")
            }
        },
    });

    const handleClick = () => {
        window.location = window.location.href;
    }
    return (
        <>
            <Header main_className="bg-header"></Header>

            {/* Metatag  */}
            {
                getBlogPerticularList != null ?
                    <Metatag title={getBlogPerticularList[0]?.blog_pagetitle}
                        keywords={getBlogPerticularList[0]?.blog_metakeywords}
                        description={getBlogPerticularList[0]?.blog_metadescription} />
                    :
                    null
            }
            {/* Visual Panel */}
            <Container fluid className="bg-visual-inside p-0" >
                <div className="visual-inside-big visual-container-inside">
                    <Container >
                        <Row className="g-md-0 rounded-4">
                            <Col md={6}>
                                <div className="d-flex align-items-end h-100 pb-md-5">
                                    <div className="d-inline-block m-0 align-self-end py-xl-3 p-2 text-white">
                                        <h1 className="fw-light text-white m-0 align-self-center wow fadeInUp">
                                            Blog
                                        </h1>
                                    </div>
                                </div>
                            </Col>
                            <Col md={6} className="mt-auto ms-auto">
                                <div className="border-bottom border-5 border-primary rounded-4">
                                    <img src={global.variables.strDomainUrl + "images/visual-blog.jpg"} className="img-fluid rounded-4" alt="" title="" />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Container>


            <Container className="my-5 pt-4 pt-xl-5">
                {
                    getBlogPerticularList != null ?
                        getBlogPerticularList.length > 0 ?
                            <Row>
                                <Col lg="4" xl="3" className="order-lg-2 mb-3">
                                    <div className="bg-primary bg-opacity-10 rounded-4 py-4 px-4 border">
                                        <div className="form-group mb-0">
                                            <div className="input-group">
                                                <input name="txtBlogsearch" type="text" id="txtSearch" className="form-control form-control-sm py-2 ps-3 pe-4 rounded-pill" placeholder="Search Blog" />

                                                <div className="search-btn">
                                                    <Link id='btnclickfocus'><FaSearch /></Link>
                                                </div>
                                            </div>
                                            <span id="searcherr" class="text-danger small mx-2"></span>
                                        </div>
                                        <div className="navigation">
                                            <ul className="recent">
                                                <li>
                                                    <span className="blg-mbl-head"><Link to="/blog">Blog Home</Link></span>
                                                </li>
                                            </ul>
                                            <ul className="recent">
                                                <li>
                                                    <span className="blg-mbl-head">Recent Posts</span>
                                                    <ul>
                                                        {

                                                            getLimitedBlogList.length > 0 ?
                                                                getLimitedBlogList.map((val_limited_blog, index) => {
                                                                    return (
                                                                        <li key={index}><Link to={`${global.variables.strDomainUrl}blog-details/${val_limited_blog.blog_pagename}`} onClick={scrollToTop}>{val_limited_blog.blog_heading}</Link></li>
                                                                    )
                                                                })
                                                                :
                                                                null
                                                        }

                                                    </ul>
                                                </li>
                                            </ul>
                                            <ul className="recent">
                                                <li>
                                                    <span className="blg-mbl-head">Archives</span>
                                                    <div>
                                                        <ul>
                                                            {
                                                                getYearByBlogList.length > 0 ?
                                                                    getYearByBlogList.map((val_year_blog, index) => {
                                                                        return (
                                                                            <li key={index}><Link to={`${global.variables.strDomainUrl}blog/${val_year_blog.blog_year}`} id="ddlBlogYear">{val_year_blog.blog_year} ({val_year_blog.blog_year_wise_count})</Link></li>
                                                                        )
                                                                    })
                                                                    :
                                                                    null
                                                            }
                                                        </ul>
                                                    </div>
                                                </li>
                                            </ul>
                                            <ul className="recent">
                                                <li>
                                                    <span className="blg-mbl-head">Categories</span>
                                                    <ul>
                                                        {
                                                            getcategoryList.length > 0 ?
                                                                getcategoryList.map((val_cat) => {

                                                                    return (

                                                                        <li><Link to={`${global.variables.strDomainUrl}blog-category/${val_cat.blog_category_pagename}`}>{val_cat.blog_category_name} ({val_cat.category_hav_actblogcount})</Link></li>
                                                                    )
                                                                })
                                                                :
                                                                null
                                                        }
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg="8" xl="9" className="order-lg-1">
                                    <div className="border rounded-4 px-lg-5 p-4 py-lg-5 h-100">
                                        <Row>
                                            {
                                                getBlogPerticularList[0].blog_picture != null && getBlogPerticularList[0].blog_picture != '' ?
                                                    <Col xl="5" className="mb-lg-0 mb-2 text-lg-left">
                                                        <img src={global.variables.strApiUrl + "/blog-picture/" + getBlogPerticularList[0].blog_picture} alt={getBlogPerticularList[0].blog_heading} title={getBlogPerticularList[0].blog_heading} className="img-fluid rounded-2 mb-3 border" />

                                                    </Col>
                                                    :
                                                    null
                                            }

                                            <Col>
                                                <h4 className="mb-1">{getBlogPerticularList[0].blog_heading}</h4>
                                                <p className="mb-3">{getBlogPerticularList[0].blog_caption} </p>
                                                <div className="tg-list mb-3">
                                                    <div className="fst-italic ml-0">
                                                        Posted on {getBlogPerticularList[0].blog_date}<span className="d-block d-md-inline-flex"><span className="d-none d-md-inline-flex me-1">,
                                                        </span>by&nbsp;{getBlogPerticularList[0].blog_author_name != null && getBlogPerticularList[0].blog_author_name != '' ? getBlogPerticularList[0].blog_author_name : "Administrator"} </span></div>
                                                </div>
                                            </Col>
                                            {
                                                getBlogPerticularList[0].blog_details != null && getBlogPerticularList[0].blog_details != '' ?
                                                    <Col sm="12">
                                                        <p className="ck-content" dangerouslySetInnerHTML={{ __html: getBlogPerticularList[0].blog_details }}></p>

                                                    </Col>
                                                    :
                                                    null
                                            }
                                            <Col sm="12">
                                                {
                                                    getBlogPerticularList[0].map_tags_count != null && getBlogPerticularList[0].map_tags_count != '' ?
                                                        <>
                                                            <div className="clearfix"></div>
                                                            <div className="blog-tags d-flex align-items-center flex-wrap w-100 mb-2  wow fadeInUp mt-1">
                                                                <span className="tags me-2"><FaTag />&nbsp;Tags:</span>
                                                                <ul className="tag-list">
                                                                    <GetblogTags _blog_id={getBlogPerticularList[0].blog_id} _navigate={navigate} />
                                                                    {/* <li><Link to="/a/amnsinternationalschool/Blog?blog_tags_id=1">By Students</Link></li>
                                                            <li><Link to="/a/amnsinternationalschool/Blog?blog_tags_id=2">Life</Link></li> */}
                                                                </ul>
                                                            </div>

                                                        </>
                                                        :
                                                        null
                                                }

                                                {/* <div className="clearfix"></div>
                                                    <div className="blog-networking my-lg-4 d-md-flex justify-content-between align-items-center w-100">
                                                        <ul className="network-list  media-links">
                                                            <li className="font-weight-semibold small text-dark">SHARE</li>
                                                            <li><a href={`mailto:?subject=Interesting%20Article%20by%20National%20Disease%20Modelling%20Consortium&body=Found%20an%20Interesting%20Article%20by%20National%20Disease%20Modelling%20Consortium,%20Check%20out%20this%20site%20${window.location.href}`} title="inbox"><FaEnvelope /></a></li>
                                                            <li><Link to={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`} title="facebook" target="_blank" rel="noopener"><FaFacebookF /></Link></li>
                                                            <li><Link to={`https://twitter.com/intent/tweet?url=${window.location.href}`} title="twitter" target="_blank" rel="noopener"><FaXTwitter /></Link></li>
                                                            <li><Link to={`https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}`} target="_blank" rel="noopener"><FaLinkedinIn /></Link></li>
                                                            <li><Link to={`https://api.whatsapp.com/send?text=${window.location.href}`} data-action="share/whatsapp/share"><FaWhatsapp /></Link></li>
                                                        </ul>
                                                        
                                                    </div> */}
                                                <div className="clearfix"></div>
                                                {
                                                    getBlogPerticularList[0].blog_comment_count > 0 ?
                                                        <div className="links">
                                                            <div className="links-list">

                                                                <Link onClick={toggle}>
                                                                    <FaComments className="me-2" />Comments ({getBlogPerticularList[0].blog_comment_count})
                                                                </Link>
                                                                <Collapse isOpen={isOpen} {...BlogDetails}>
                                                                    <Card className="bg-light border-0 mt-2">
                                                                        <CardBody className="py-0">
                                                                            <div className="reviews ">
                                                                                <GetBlogComments _blog_id={getBlogPerticularList[0].blog_id} _navigate={navigate} />
                                                                            </div>
                                                                        </CardBody>
                                                                    </Card>
                                                                </Collapse>

                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                }
                                            </Col>
                                        </Row>
                                        {
                                            getBlogPerticularList[0].blog_flag_for_comment === 1 ?
                                                <form onSubmit={(e) => {
                                                    e.preventDefault();
                                                    validation.handleSubmit();
                                                    return false;
                                                }}>
                                                    {/* formik focus error blog if error occurs in validation */}
                                                    <FocusError formik={validation} />
                                                    <hr />
                                                    <h5 className="fw-bold text-primary">Post a Comment</h5>
                                                    <p>Your email address will not be published. Required fields are marked (*)</p>
                                                    <div className="form-horizontal row">
                                                        <div className="form-group col-sm-6 col-lg-6">
                                                            <label className="form-label" for="">Commented by</label><span>*</span>
                                                            <div className="mb3">
                                                                <Input type="text"
                                                                    name="blog_comment_name"
                                                                    validate={{
                                                                        required: { value: true },
                                                                    }}
                                                                    onChange={(e) => {
                                                                        //handle change of the validation
                                                                        validation.handleChange(e);
                                                                    }}
                                                                    invalid={
                                                                        validation.touched.blog_comment_name && validation.errors.blog_comment_name ? true : false
                                                                    }
                                                                    value={validation.values.blog_comment_name}
                                                                    className="form-control" />
                                                                {validation.touched.blog_comment_name && validation.errors.blog_comment_name ? (
                                                                    <FormFeedback status="invalid">{validation.errors.blog_comment_name}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                        <div className="form-group col-sm-6 col-lg-6">
                                                            <label className="form-label" for="">Email</label><span>*</span>
                                                            <div className="mb3">
                                                                <Input type="text"
                                                                    name="blog_comment_email"
                                                                    validate={{
                                                                        required: { value: true },
                                                                    }}
                                                                    onChange={(e) => {
                                                                        //handle change of the validation
                                                                        validation.handleChange(e);
                                                                    }}
                                                                    invalid={
                                                                        validation.touched.blog_comment_email && validation.errors.blog_comment_email ? true : false
                                                                    }
                                                                    value={validation.values.blog_comment_email}
                                                                    className="form-control" />
                                                                {validation.touched.blog_comment_email && validation.errors.blog_comment_email ? (
                                                                    <FormFeedback status="invalid">{validation.errors.blog_comment_email}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                        <div className="form-group col-sm-6">
                                                            <label className="form-label" for="">Comment</label><span>*</span>
                                                            <div className="mb3">
                                                                <Input type="textarea"
                                                                    cols="20"
                                                                    rows="4"
                                                                    name="blog_comment"
                                                                    validate={{
                                                                        required: { value: true },
                                                                    }}
                                                                    onChange={(e) => {
                                                                        //handle change of the validation
                                                                        validation.handleChange(e);
                                                                    }}
                                                                    invalid={
                                                                        validation.touched.blog_comment && validation.errors.blog_comment ? true : false
                                                                    }
                                                                    value={validation.values.blog_comment}
                                                                    className="form-control" />
                                                                {validation.touched.blog_comment && validation.errors.blog_comment ? (
                                                                    <FormFeedback status="invalid">{validation.errors.blog_comment}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                        <div className="form-group px-3 col-sm-6">
                                                            <div className="row verify-box">
                                                                <div className="col-md-12">

                                                                    <label className="form-label">Type the characters you see in the picture below.</label>
                                                                    <div className="verification_box d-block border bg-white rounded">
                                                                        <div className="mvc-captcha p-3 h-100 d-flex  align-item-center">
                                                                            <Col md={6} className=" mt-1">
                                                                                <LoadCanvasTemplate ref={captchaRef} />

                                                                            </Col>
                                                                            <Col md={6}>
                                                                                <Input
                                                                                    name="blog_captcha"
                                                                                    validate={{
                                                                                        required: { value: true },
                                                                                    }}
                                                                                    onChange={validation.handleChange}
                                                                                    invalid={
                                                                                        validation.touched.blog_captcha && validation.errors.blog_captcha ? true : false
                                                                                    }
                                                                                    value={validation.values.blog_captcha}
                                                                                    id="user_captcha_input"
                                                                                    type="text" />
                                                                                {validation.touched.blog_captcha && validation.errors.blog_captcha ? (
                                                                                    <FormFeedback className='text-danger' status="invalid">{validation.errors.blog_captcha}</FormFeedback>
                                                                                ) : null}
                                                                            </Col>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="text-center">
                                                            <div>
                                                                <Button type="submit" className="btn btn-primary rounded-pill px-4 mt-2 mx-1">Post Comment <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" class="ms-2" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M313.941 216H12c-6.627 0-12 5.373-12 12v56c0 6.627 5.373 12 12 12h301.941v46.059c0 21.382 25.851 32.09 40.971 16.971l86.059-86.059c9.373-9.373 9.373-24.569 0-33.941l-86.059-86.059c-15.119-15.119-40.971-4.411-40.971 16.971V216z"></path></svg></Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                                :
                                                null
                                        } 

                                        <div class="text-md-end text-center mt-4 ml-auto">
                                            <Link to="/blog" class="btn btn-primary rounded-pill px-4 mt-2 mx-1">Back</Link>
                                        </div>
                                    </div>

                                </Col>
                            </Row>
                            :
                            // <Container className="p-md-5 p-4 content-bg bordr bg-white rounded-3 shadow-sm">.
                            <Row>
                                <span className="text-center"><b>No records found</b></span>
                            </Row>
                        // </Container>
                        :
                        // <Container className="p-md-5 p-4 content-bg bordr bg-white rounded-3 shadow-sm">
                        <Row>
                            <span className="text-center">Loading...</span>
                        </Row>
                    // </Container>
                }
            </Container >

            <Modal show={show1} scrollable centered >
                <Modal.Body>
                    <Container>
                        <div className="py-2 text-start">
                            <p>Your comment is saved, it will be displayed after admin approval</p>
                        </div>
                        {/* <div className="float-right"> */}
                        <button type="button"
                            style={{ float: 'right' }}
                            onClick={handleClick}
                            className="btn btn-default float-right border bg-secondary text-white">OK</button>
                        {/* </div> */}
                    </Container>
                </Modal.Body>
            </Modal>
            {/* Breadcrumb */}
            <Container fluid className="small pt-3 p-0 bg-white d-flex justify-content-center mt-auto" >
                <Breadcrumb>
                    <Breadcrumb.Item href={globalVariables.variables.strDomainUrl}>Home</Breadcrumb.Item>
                    <Breadcrumb.Item href={globalVariables.variables.strDomainUrl + '/blog'} >Blog</Breadcrumb.Item>
                    <Breadcrumb.Item active>{getBlogPerticularList != null ? getBlogPerticularList[0]?.blog_heading : null}</Breadcrumb.Item>
                </Breadcrumb>
            </Container>
            {/* Breadcrumb */}
        </>
    );
}