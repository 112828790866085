import React from "react";
import Modal from "react-bootstrap/Modal";
import { Row, Col } from "reactstrap";
export default function ProjectDetailsPopup({
  show,
  handleClose,
  researchList,
  getResearchid,
  // researchMapPeople
}) {

  return (
    <>
      {/* PROJECT DETAILS */}
      <Modal
        show={show}
        size="xl"
        scrollable
        centered
        onHide={handleClose}
        dialogClassName="p-2 popup"
      >
        {researchList.map((val) => {
          return (
            <>
              {val.res_id == getResearchid ? (
                <>
                  <Modal.Header
                    closeButton
                    className="border-0 align-items-start bg-primary p-3 px-4 h-auto"
                  >
                    <Col>
                      {/* <div className="bg-primary p-2 mb-4"> */}
                        <h5 className="text-white m-0 mx-3">{val.res_title}</h5>
                      {/* </div> */}
                    </Col>
                  </Modal.Header>
                  <Modal.Body className="pt-3">
                    <Row className="row-cols-1 px-md-4 px-2" key={val.res_id}>
                      <Col>
                        <ul className="list-group list-group-flush px-0 mb-3 projects list">
                        {/* {
                          researchMapPeople.length > 0 ?
                            <li className="list-group-item border-0 p-0 wow fadeInUp">
                              <b>Faculty:</b>{" "}
                              <ul>
                                {
                                  researchMapPeople?.map((mappedPeople)=>{
                                    return(
                                      <li>{mappedPeople.full_name}</li>
                                    )
                                  })
                                }
                              </ul>
                            </li>
                            :
                            null
                          } */}
                          {val.res_gesh_faculty != "" &&
                          val.res_gesh_faculty != null ? (
                            <li className="list-group-item border-0 p-0 wow fadeInUp">
                              <b>Faculty:</b>{" "}
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: val.res_gesh_faculty,
                                }}
                              ></span>
                            </li>
                          ) : null}
                          {val.res_gesh_partner != "" &&
                          val.res_gesh_partner != null ? (
                            <li className="list-group-item border-0 p-0 wow fadeInUp">
                              <b>Partner:</b>{" "}
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: val.res_gesh_partner,
                                }}
                              ></span>
                            </li>
                          ) : null}

                          {val.res_supervisor != "" &&
                          val.res_supervisor != null ? (
                            <li className="list-group-item border-0 p-0 wow fadeInUp">
                              <b>Supervisor:</b>{" "}
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: val.res_supervisor,
                                }}
                              ></span>
                            </li>
                          ) : null}
                          {val.res_co_supervisor != "" &&
                          val.res_co_supervisor != null ? (
                            <li className="list-group-item border-0 p-0 wow fadeInUp">
                              <b>Co-supervisor:</b>{" "}
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: val.res_co_supervisor,
                                }}
                              ></span>
                            </li>
                          ) : null}
                        </ul>
                        {val.res_description != "" &&
                        val.res_description != null ? (
                          <p className="list">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: val.res_description,
                              }}
                            ></span>
                          </p>
                        ) : null}
                        {/* {
                              val.project_type != "" && val.project_type != null ?
                                <b className="text-dark">Project Type: {val.project_type}</b> :
                                null
                            } */}
                      </Col>
                    </Row>
                  </Modal.Body>
                </>
              ) : null}
            </>
          );
        })}
      </Modal>
      {/* PROJECT DETAILS */}
    </>
  );
}
