import React,{useState,useEffect} from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';
import global from '../../global-variables';

export default function GetblogTags(props) {

    const [getMappedBlogTagList , setMappedBlogTagList] = useState();

    useEffect(() => {
        let _obj = new Object();
        _obj.blog_id = props._blog_id;

            axios.post(global.variables.strApiUrl + "/BlogApi/getMapBlogTagList", _obj)
                .then((response_list) => {
                    if(response_list.data === 'error')
                    {
                        props._navigate('/error-page')
                    }
                    else
                    {
                        // pass the result in the success function of the respective page
                        setMappedBlogTagList(response_list.data[0]);

                    }
                });
       

    }, [props._blog_id])
    
  return (
        
            getMappedBlogTagList?.length > 0 ?
                getMappedBlogTagList.map((val_tags,index)=>{
                    
                    return(
                        <li key={index}><Link to={`${global.variables.strDomainUrl}blog-tags/${val_tags.blog_tags_pagename}`} onClick={props._scrollToTop}>{val_tags.blog_tags_name}</Link></li>  
                    )
                })
            :
            null 
  )
}
