import React, { useState, useEffect } from "react";
import global from "../../global-variables";
import Header from "../layout/header";
import { Link } from "react-router-dom";
import $ from "jquery";
import WOW from "wowjs";

import { Container, Row, Col } from "reactstrap";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Metatag from "../../hooks/Metatag";


export default function FocusAreas() {
  const [show, setShow] = useState(false);
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  useEffect(() => {

    // remove bg-header-home 
    $(window).on("scroll", function () {
      $(".navbar").offset().top > 10 ? $(".fixed-top").removeClass("bg-header-home") : $(".fixed-top").removeClass("bg-header-home")
    });

    // initiate the wow effect
    new WOW.WOW({
      live: false,
    }).init();


  }, []);


  return (
    <>
      <Header></Header>

      <Metatag title='Green Energy Research Programs | Battery Technologies Research Programs | Bio Fuels Research Programs | Sustainability Research Programs'
        description='Sustainable Energy Research Programs like Green Energy, Green Hydrogen, Battery Technologies, Bio Fuels offered by IIT Bombay'
        keywords='Sustainability Energy Research Programs, Green Energy Research Programs, Battery Technologies Research Programs, Bio Fuels Research Programs, Sustainable Energy Research Programs at IIT Bombay'
      />

      {/* Visual Panel */}
      <Container fluid className="bg-visual-inside p-0" >
        <div className="visual-inside-big visual-container-inside">
          <Container>
            <Row className="g-md-0 rounded-4">
              <Col md={6}>
                <div className="d-flex align-items-end h-100 pb-md-5">
                  <div className="d-inline-block m-0 align-self-end py-xl-3 p-2 text-white">
                    <h1 className="fw-light text-white m-0 align-self-center wow fadeInUp">
                      Focus Areas
                    </h1>
                  </div>
                </div>
              </Col>
              <Col md={6} className="mt-auto ms-auto">
                <div className="border-bottom border-5 border-primary rounded-4">
                  <img src={global.variables.strDomainUrl + "images/visual-focus-area.jpg"} className="img-fluid rounded-4" alt="" title="" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>

      {/* Container */}
      <Container className="my-5 pt-4 pt-xl-5">
        <Row className="gx-1 row-cols-2 row-cols-md-5 row-cols-lg-5 g-4 overflow-hidden">
          <Col className="text-center">
            <div className="px-3 p-4 border rounded-4 h-100">
              <div className="px-md-4">
                <img src={global.variables.strDomainUrl + "images/icon-solar-pv-fa.png"} alt="Solar Thermal & PV" title="Solar Thermal & PV" className="img-fluid mb-3 px-xl-5 px-4 wow fadeInDown" />
              </div>
              <h6 className="wow fadeInUp">Solar Thermal & PV</h6>
            </div>
          </Col>

          <Col className="text-center">
            <div className="px-3 p-4 border rounded-4 h-100">
            <div className="px-md-4">
                <img src={global.variables.strDomainUrl + "images/icon-battery-fa.png"} alt="Batteries & Fuel cells " title="Batteries & Fuel cells " className="img-fluid mb-3 px-xl-5 px-4 wow fadeInDown" />
              </div>
              <h6 className="wow fadeInUp">Batteries & Fuel cells</h6>
            </div>
          </Col>
          <Col className="text-center">
            <div className="px-3 p-4 border rounded-4 h-100">
            <div className="px-md-4">
                <img src={global.variables.strDomainUrl + "images/icon-biofuel-fa.png"} alt="Biofuels" title="Biofuels" className="img-fluid mb-3 px-xl-5 px-4 wow fadeInDown" />
              </div>
              <h6 className="wow fadeInUp">Biofuels</h6>
            </div>
          </Col>
          <Col className="text-center">
            <div className="px-3 p-4 border rounded-4 h-100">
            <div className="px-md-4">
                <img src={global.variables.strDomainUrl + "images/icon-wind-energy-fa.png"} alt="Wind Energy" title="Wind Energy" className="img-fluid mb-3 px-xl-5 px-4 wow fadeInDown" />
              </div>
              <h6 className="wow fadeInUp">Wind Energy</h6>
            </div>
          </Col>
          <Col className="text-center">
            <div className="px-3 p-4 border rounded-4 h-100">
            <div className="px-md-4">
                <img src={global.variables.strDomainUrl + "images/icon-hydrogen-fa.png"} alt="Green Hydrogen" title="Green Hydrogen" className="img-fluid mb-3 px-xl-5 px-4 wow fadeInDown" />
              </div>
              <h6 className="wow fadeInUp">Green Hydrogen</h6>
            </div>
          </Col>
          <Col className="text-center">
            <div className="px-3 p-4 border rounded-4 h-100">
            <div className="px-md-4">
                <img src={global.variables.strDomainUrl + "images/icon-circular-economy-fa.png"} alt="Circular Economy" title="Circular Economy" className="img-fluid mb-3 px-xl-5 px-4 wow fadeInDown" />
              </div>
              <h6 className="wow fadeInUp">Circular Economy</h6>
            </div>
          </Col>
          <Col className="text-center">
            <div className="px-3 p-4 border rounded-4 h-100">
            <div className="px-md-4">
                <img src={global.variables.strDomainUrl + "images/icon-climate-change-fa.png"} alt="Climate Change" title="Climate Change" className="img-fluid mb-3 px-xl-5 px-4 wow fadeInDown" />
              </div>
              <h6 className="wow fadeInUp">Climate Change</h6>
            </div>
          </Col>
          <Col className="text-center">
            <div className="px-3 p-4 border rounded-4 h-100">
            <div className="px-md-4">
                <img src={global.variables.strDomainUrl + "images/icon-environmental-studies-fa.png"} alt="Environmental Studies" title="Environmental Studies" className="img-fluid mb-3 px-xl-5 px-4 wow fadeInDown" />
              </div>
              <h6 className="wow fadeInUp">Environmental Studies</h6>
            </div>
          </Col>
          <Col className="text-center">
            <div className="px-3 p-4 border rounded-4 h-100">
            <div className="px-md-4">
                <img src={global.variables.strDomainUrl + "images/icon-food-water-fa.png"} alt="Food & Water" title="Food & Water" className="img-fluid mb-3 px-xl-5 px-4 wow fadeInDown" />
              </div>
              <h6 className="wow fadeInUp">Food & Water</h6>
            </div>
          </Col>
          <Col className="text-center">
            <div className="px-3 p-4 border rounded-4 h-100">
            <div className="px-md-4">
                <img src={global.variables.strDomainUrl + "images/icon-caorbon-capture-fa.png"} alt="Decarbonisation" title="Decarbonisation" className="img-fluid mb-3 px-xl-5 px-4 wow fadeInDown" />
              </div>
              <h6 className="wow fadeInUp">Decarbonisation</h6>
            </div>
          </Col>
        </Row>
        {/* <Row className="row-cols-1 row-cols-md-2 row-cols-xl-4 g-4">
          <Col>
            <div class="bg-primary bg-opacity-10 p-4 p-xl-4 rounded-4 h-100 position-relative overflow-hidden">
              <figure class="position-relative bg-primary overflow-hidden p-0 rounded-4 border-4 px-4 py-4 d-flex align-items-center wow fadeInUp">
                <div className="focus-icon-75">
                <img src={global.variables.strDomainUrl + "images/icon-green-energy.png"} alt="Green Energy" title="Green Energy" className="img-fluid " />
                </div>
                <h6 class="px-md-3 m-0 text-white wow fadeInUp">Green Energy</h6>
              </figure>
              <div className="mt-4 list">
                <ul>
                  <li className="wow fadeInUp">Green Hydrogen (Generation, Storage, Transport and Utilization)</li>
                  <li className="wow fadeInUp">Solar Photovoltaics</li>
                  <li className="wow fadeInUp">Module Reliability</li>
                  <li className="wow fadeInUp">Crystalline Si Solar Cells</li>
                  <li className="wow fadeInUp">Power Electronics</li>
                  <li className="wow fadeInUp">Thin Film Materials & Devices</li>
                  <li className="wow fadeInUp">Energy Storage</li>
                </ul>
              </div>
            </div>
          </Col>
          <Col>
            <div class="bg-primary bg-opacity-10 p-4 p-xl-4 rounded-4 h-100 position-relative overflow-hidden">
              <figure class="position-relative bg-primary overflow-hidden p-0 rounded-4 border-4 px-4 py-4 d-flex align-items-center wow fadeInUp">
                <div className="focus-icon-75">
                  <img src={global.variables.strDomainUrl + "images/icon-battery.png"} alt="Batteries" title="Batteries" className="img-fluid" />
                </div>
                <h6 class="px-md-3 m-0 text-white flex-grow-1 wow fadeInUp">Battery Technologies</h6>
              </figure>
              <div className="mt-4 list">
                <ul>
                  <li className="wow fadeInUp">New Battery Chemistry, Materials and Cell Development</li>
                  <li className="wow fadeInUp">Li/Na-S battery for large scale storage</li>
                  <li className="wow fadeInUp">Charging Infrastructure</li>
                  <li className="wow fadeInUp">Charging Profiles, Battery Management System</li>
                  <li className="wow fadeInUp">Battery Reliability and Safety</li>
                </ul>
              </div>
            </div>
          </Col>
          <Col>
            <div class="bg-primary bg-opacity-10 p-4 p-xl-4 rounded-4 h-100 position-relative overflow-hidden">
              <figure class="position-relative bg-primary overflow-hidden p-0 rounded-4 border-4 px-4 py-4 d-flex align-items-center wow fadeInUp">
                <div className="focus-icon-75">
                  <img src={global.variables.strDomainUrl + "images/icon-biofuel.png"} alt="Bio Fuels" title="Bio Fuels" className="img-fluid" />
                </div>
                <h6 class="px-md-3 m-0 text-white flex-grow-1 wow fadeInUp">Bio Fuels</h6>
              </figure>
              <div className="mt-4 list">
                <ul>
                  <li className="wow fadeInUp">Cyanobacterial biofuels</li>
                  <li className="wow fadeInUp">Process engineering for Algal Bioenergy</li>
                  <li className="wow fadeInUp">Bioreactor Design</li>
                  <li className="wow fadeInUp">Engineering Enzymes for Biofuel Production</li>
                  <li className="wow fadeInUp">Techno-Economic Analysis</li>
                  <li className="wow fadeInUp">Catalytic Upgrading of Bio Oil to Transport Oil</li>
                </ul>
              </div>

            </div>
          </Col>
          <Col>
            <div class="bg-primary bg-opacity-10 p-4 p-xl-4 rounded-4 h-100 position-relative overflow-hidden">
              <figure class="position-relative bg-primary overflow-hidden p-0 rounded-4 border-4 px-4 py-4 d-flex align-items-center wow fadeInUp">
                <div className="focus-icon-75">
                  <img src={global.variables.strDomainUrl + "images/icon-sustainability.png"} alt="Sustainability" title="Sustainability" className="img-fluid" />
                </div>
                <h6 class="px-md-3 m-0 text-white wow fadeInUp">Sustainability</h6>
              </figure>
              <div className="mt-4 list">
                <ul>
                  <li className="wow fadeInUp">Forecast & Predictive Analysis</li>
                  <li className="wow fadeInUp">Environmental Monitoring</li>
                  <li className="wow fadeInUp">Climate Adaptation & Resources Management</li>
                  <li className="wow fadeInUp">Impact and Risk Assessment</li>
                  <li className="wow fadeInUp">Climate Mitigation with Net Zero Vision</li>
                </ul>
              </div>

            </div>
          </Col>
        </Row> */}
        {/* <Row className="row-cols-xl-6 g-3 research">
          <Col>
            <div class="bg-primary bg-opacity-10 p-4 p-xl-4 rounded-4 h-100 text-center position-relative overflow-hidden">
              <figure class="position-relative bg-primary overflow-hidden p-0 rounded-4 border-4 px-5 py-4 wow fadeInUp">
                <img src={global.variables.strDomainUrl + "images/icon-solar-pv.png"} alt="Solar PV" title="Solar PV" className="img-fluid" />
              </figure>
              <h6 class="px-md-3 pt-3 mb-1 text-dark wow fadeInUp">Solar PV</h6>
            </div>
          </Col>
          <Col>
            <div class="bg-primary bg-opacity-10 p-4 p-xl-4 rounded-4 h-100 text-center position-relative overflow-hidden">
              <figure class="position-relative bg-primary overflow-hidden p-0 rounded-4 border-4 px-5 py-4 wow fadeInUp">
                <img src={global.variables.strDomainUrl + "images/icon-hydrogen.png"} alt="Hydrogen" title="Hydrogen" className="img-fluid" />
              </figure>
              <h6 class="px-md-3 pt-3 mb-1 text-dark wow fadeInUp">Hydrogen</h6>
            </div>
          </Col>
          <Col>
            <div class="bg-primary bg-opacity-10 p-4 p-xl-4 rounded-4 h-100 text-center position-relative overflow-hidden">
              <figure class="position-relative bg-primary overflow-hidden p-0 rounded-4 border-4 px-5 py-4 wow fadeInUp">
                <img src={global.variables.strDomainUrl + "images/icon-biofuel.png"} alt="Bio Fuels" title="Bio Fuels" className="img-fluid" />
              </figure>
              <h6 class="px-md-3 pt-3 mb-1 text-dark wow fadeInUp">Bio Fuels</h6>
            </div>
          </Col>
          <Col>
            <div class="bg-primary bg-opacity-10 p-4 p-xl-4 rounded-4 h-100 text-center position-relative overflow-hidden">
              <figure class="position-relative bg-primary overflow-hidden p-0 rounded-4 border-4 px-5 py-4 wow fadeInUp">
                <img src={global.variables.strDomainUrl + "images/icon-battery.png"} alt="Batteries" title="Batteries" className="img-fluid" />
              </figure>
              <h6 class="px-md-3 pt-3 mb-1 text-dark wow fadeInUp">Batteries</h6>
            </div>
          </Col>
          <Col>
            <div class="bg-primary bg-opacity-10 p-4 p-xl-4 rounded-4 h-100 text-center position-relative overflow-hidden">
              <figure class="position-relative bg-primary overflow-hidden p-0 rounded-4 border-4 px-5 py-4 wow fadeInUp">
                <img src={global.variables.strDomainUrl + "images/icon-circular-economy.png"} alt="Circular Economy" title="Circular Economy" className="img-fluid" />
              </figure>
              <h6 class="px-md-3 pt-3 mb-1 text-dark wow fadeInUp">Circular Economy</h6>
            </div>
          </Col>
          <Col>
            <div class="bg-primary bg-opacity-10 p-4 p-xl-4 rounded-4 h-100 text-center position-relative overflow-hidden">
              <figure class="position-relative bg-primary overflow-hidden p-0 rounded-4 border-4 px-5 py-4 wow fadeInUp">
                <img src={global.variables.strDomainUrl + "images/icon-climate-environment.png"} alt="Climate & Environment" title="Climate & Environment" className="img-fluid" />
              </figure>
              <h6 class="px-md-3 pt-3 mb-1 text-dark wow fadeInUp">Climate & Environment</h6>
            </div>
          </Col>
        </Row> */}
      </Container>

      {/* Breadcrumb */}
      <Breadcrumb className="breadcrumb-panel mt-auto">
        <Breadcrumb.Item href={global.variables.strDomainUrl}>Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Focus Areas</Breadcrumb.Item>
      </Breadcrumb>
    </>
  );
}