import React, { useState, useEffect } from "react";
import global from "../../global-variables";
import Header from "../layout/header";
import { Link } from "react-router-dom";
import $ from "jquery";
import WOW from "wowjs";
import { FaLink, FaImage, FaRegFilePdf, FaVideo, FaChevronRight } from "react-icons/fa";

import { Container, Row, Col } from "reactstrap";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Metatag from "../../hooks/Metatag";


export default function AboutUs() {
  const [show, setShow] = useState(false);
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  useEffect(() => {
    $(window).on("scroll", function () {
      $(".navbar").offset().top > 10 ? $(".fixed-top").removeClass("bg-header-home") : $(".fixed-top").removeClass("bg-header-home")
    });

    // initiate the wow effect
    new WOW.WOW({
      live: false,
    }).init();


  }, []);


  return (
    <>
      <Header></Header>
      <Metatag title='IIT Bombay Research Hub for Green Energy and Sustainability - GESH'
        description='IIT Bombay Research Hub for Green Energy and Sustainability - GESH offering Academic Programs on Green Energy and Sustainable Energy'
        keywords='IIT Bombay Research Hub for Green Energy and Sustainability - GESH, Academic Programs on Green Energy, Academic Programs on Sustainable Energy, Degree Programs on Green Energy, Degree Programs on Sustainable Energy'
      />
      {/* Visual Panel */}
      <Container fluid className="bg-visual-inside p-0" >
        <div className="visual-inside-big visual-container-inside">
          <Container >
            <Row className="g-md-0 rounded-4">
              <Col md={6} className="">
                <div className="d-flex align-items-end h-100 pb-md-5">
                  <div className="d-inline-block m-0 align-self-end py-xl-3 p-2 text-white">
                    <h1 className="fw-light text-white m-0 align-self-center wow fadeInUp">
                      About GESH
                    </h1>
                  </div>
                </div>
              </Col>
              <Col md={6} className="ms-auto">
                <div className="border-bottom border-5 border-primary rounded-4">
                  <img src={global.variables.strDomainUrl + "images/visual-aboutus.jpg"} className="img-fluid rounded-4" alt="" title="" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>

      {/* Container */}
      <Container className="my-md-5 mt-5 mb-0 pt-4 pt-xl-5">
        <Row className="g-0">
          <Col md={8} lg={8}>
            <div className="border rounded-4 px-lg-5 p-4 py-lg-5 h-100">
              <div className="pe-md-2 col-md-11 list">
                <p className="wow fadeInUp">The Green Energy and Sustainability Research Hub at IIT Bombay is a pioneering centre dedicated to addressing the global climate crisis by facilitating technological, management & policy solutions through research in green energy and sustainability domains.
                  We envision bridging the gap between academic research and real-world application while fostering education and entrepreneurship in these critical fields. We aim to nurture the next generation of green energy and sustainability leaders to tackle complex environmental challenges from multiple angles, promoting creativity and innovation. GESH will be the first point of contact for green energy and sustainability research, education and other services at IITB.
                </p>
                <p className="fw-bold wow fadeInUp">Apart from research, we strive to provide:</p>
                <Row className="row-cols-xl-2 row-cols-1 g-5 mb-4">
                  <Col className="d-flex align-items-center overflow-hidden">
                    <div>
                      <div className="icon-100 bg-primary rounded-circle p-1 me-3 overflow-hidden">
                        <img src={global.variables.strDomainUrl + "images/icon-workshops.png"} alt="Solar PV" title="Solar PV" className="img-fluid p-3 wow fadeInUp" />
                      </div>
                    </div>
                    <div>
                      <p className="m-0 wow fadeInUp">Regular workshops, seminars, and conferences to facilitate knowledge exchange</p>
                    </div>
                  </Col>
                  <Col className="d-flex align-items-center overflow-hidden">
                    <div>
                      <div className="icon-100 bg-primary rounded-circle p-1 me-3 overflow-hidden">
                        <img src={global.variables.strDomainUrl + "images/icon-research.png"} alt="Solar PV" title="Solar PV" className="img-fluid p-3 wow fadeInUp" />
                      </div>
                    </div>
                    <div>
                      <p className="m-0 wow fadeInUp">Collaborative research opportunities with leading global universities and corporations</p>
                    </div>
                  </Col>
                  <Col className="d-flex align-items-center overflow-hidden">
                    <div>
                      <div className="icon-100 bg-primary rounded-circle p-1 me-3 overflow-hidden">
                        <img src={global.variables.strDomainUrl + "images/icon-educational-programs.png"} alt="Solar PV" title="Solar PV" className="img-fluid p-3 wow fadeInUp" />
                      </div>
                    </div>
                    <div>
                      <p className="m-0 wow fadeInUp">Industry-tailored educational programs to prepare the next generation of sustainability leaders</p>
                    </div>
                  </Col>
                  <Col className="d-flex align-items-center overflow-hidden">
                    <div>
                      <div className="icon-100 bg-primary rounded-circle p-1 me-3 overflow-hidden">
                        <img src={global.variables.strDomainUrl + "images/icon-green-entrepreneurship.png"} alt="Solar PV" title="Solar PV" className="img-fluid p-3 wow fadeInUp" />
                      </div>
                    </div>
                    <div>
                      <p className="m-0 wow fadeInUp">A nurturing environment for green entrepreneurship and start-ups</p>
                    </div>
                  </Col>
                  <Col className="d-flex align-items-center overflow-hidden">
                    <div>
                      <div className="icon-100 bg-primary rounded-circle p-1 me-3 overflow-hidden">
                        <img src={global.variables.strDomainUrl + "images/icon-laboratory.png"} alt="Solar PV" title="Solar PV" className="img-fluid p-3 wow fadeInUp" />
                      </div>
                    </div>
                    <div>
                      <p className="m-0 wow fadeInUp">State-of-the-art laboratory facilities equipped with the latest technologies</p>
                    </div>
                  </Col>
                </Row>

                <p className="wow fadeInUp">Whether you're a researcher looking to make a difference, an industry partner seeking sustainable solutions, or a student passionate about environmental issues, the Green Energy and Sustainability Research Hub offers a platform to turn your green aspirations into reality. </p>
                <p className="wow fadeInUp">The hub was established in 2024 by an unprecedented 18.6 million USD donation from a US-based alumnus, and our hub represents a significant leap forward in environmental stewardship and sustainable solutions. </p>



                {/* <h5 className="text-black">Objectives</h5>
                <ul>
                  <li className="py-2">To carry out research and development programs in the fields of green energy and sustainability, including climate services and solutions, and related areas. Such programs will initially focus on the research areas and will foster collaborative interactions between IIT Bombay and industry. Such research and development programs are expected to generate approximately 5-7 academic publications each year and approximately 3-5 patent applications each year.</li>
                  <li className="py-2">To create a talent pipeline through education and training that is tailored to industry needs. In furtherance of this objective,the HUB is expected to conduct approximately 2–3 training and education programs each year in green energy and sustainability, including climate services and solutions, for participants from industry and academia.</li>
                  <li className="py-2">To develop strong collaborations with industry and other global universities to solve cutting edge problems through various outreach activities. Outreach programs will aim to train external researchers in the research areas and will enable such external researchers to become force-multipliers that proliferate such research, create impact, and improve acceptability of developed technology. Such outreach will help the HUB sustain itself and will support other national and government efforts. The HUB will establish mutually beneficial partnerships with global universities in the fields of green energy and sustainability, which partnerships may include virtual webinars, guest lectures, joint academic programs andcollaborative research projects. The HUB is expected to develop approximately 2–3 new partnerships per year.</li>
                  <li className="py-2">To promote entrepreneurship in the fields of green energy and sustainability. The HUB will help connect its researchers with the IIT Bombay start up ecosystem, including the Society for Innovation and Entrepreneurship, the Technology Business Incubator, and the Entrepreneurship-Cell, to promote and facilitate entrepreneurship. The HUB is expected to conduct or support approximately 2- “hackathons” and research innovation projects per year.</li>
                  <li className="py-2">To set up and provide the best-in-class infrastructure in terms of labs and teaching facilities for students and researchers</li>
                </ul> */}

              </div>
            </div>
          </Col>
          <Col md={5} lg={5} className="py-5 container-end">
            <div className="ps-lg-5 ps-md-4 bg-white h-100">
              <div className="border-bottom border-4 border-primary rounded-4 h-100">
                <div className="bg-primary bg-opacity-10 rounded-4 py-4 px-4 border h-100 list">
                  <Row className="row-cols-1 g-4">
                    <Col>
                      <div className="d-xl-flex gap-4">
                        <div>
                          <img src={global.variables.strDomainUrl + "images/vision.png"} className="icon-100 wow fadeInUp" />
                        </div>
                        <div className="flex-grow-1 pt-4 pt-xl-0">
                          <h5 className="text-black">Vision</h5>
                          <p className="wow fadeInUp">
                            To catalyse global leadership in sustainability & green energy through cutting-edge research, innovation and education by collaborating with academia, industry and regulators to drive the transition to a greener future.
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className="d-xl-flex gap-4">
                        <div>
                          <img src={global.variables.strDomainUrl + "images/mission.png"} className="icon-100" />
                        </div>
                        <div className="flex-grow-1 pt-4 pt-xl-0">
                          <h5 className="text-black">Mission</h5>
                          <ul className="m-0">
                            <li className="wow fadeInUp">To collaborate with Industries to carry out R&D in the area of green energy & sustainability to accelerate sustainability in industries and also support their net zero targets</li>
                            <li className="wow fadeInUp">Support in Fundamental & Translational Research in the area of green energy and sustainability </li>
                            <li className="wow fadeInUp">Creating a talent pool in the area of green energy and sustainability through education, training and workshop</li>
                            <li className="wow fadeInUp">To initiate collaborations with global universities - virtual webinars, guest lectures, joint academic programs and collaborative research projects.</li>
                            <li className="wow fadeInUp">To support Entrepreneurship in the area of green energy and sustainability</li>
                            <li className="wow fadeInUp">To set up Research Infrastructure at IITB</li>
                          </ul>
                        </div>
                      </div>
                    </Col>

                  </Row>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container >

      <div className="bg-primary bg-opacity-10 my-md-5 my-3">
        <Container className="py-5">
          <img src={global.variables.strDomainUrl + "images/pic-chart.png"} className="img-fluid wow fadeInUp" />
          {/* <Row className="row-cols-3 g-xl-5">
            <Col className="position-relative">
              <div className="pe-5 ">
                <div className="bg-primary rounded-4 py-3 w-auto d-inline-block text-white px-5">
                  Education Training <br />& Workshops
                </div>
                <div className="clear"></div>
                <img src={global.variables.strDomainUrl + "images/arrow-tp.png"} className="img-fluid arrow-tp float-end wow fadeInUp" />
              </div>
            </Col>
            <Col className="mt-5 pt-5 d-flex justify-content-center">
              <div className="pt-5 mt-5">
                <div className="bg-chart1 rounded-4 py-3 w-auto d-inline-block text-white px-5 list-white">
                  <h3 className="m-0">Research</h3>
                  <ul className="mt-2 mb-0">
                    <li>Technology</li>
                    <li>Management</li>
                    <li>Policy</li>
                  </ul>
                </div>
                <div className="list">
                  <ul className="mt-3">
                    <li>Industry-Academia Projects</li>
                    <li>Hub Initiated Projects</li>
                    <li>Faculty Initiated Projects</li>
                    <li>Student Initiated Projects</li>
                    <li>Research Infrastructure</li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col className="list position-relative">
              <div className="ps-5">
                <div className="bg-primary rounded-4 py-3 w-auto d-inline-block text-white px-5">
                  Outreach
                </div>
                <ul className="mt-3">
                  <li>Roundtables</li>
                  <li>Seminars/Webinars</li>
                  <li>Symposium/Conclave/conference/Expos</li>
                  <li>Industry Affiliation Program</li>
                </ul>
                <div className="clear"></div>
                <img src={global.variables.strDomainUrl + "images/arrow-tp-right.png"} className="img-fluid arrow-tp-right float-end wow fadeInUp" />
              </div>
            </Col>
            <Col className="list position-relative">
              <img src={global.variables.strDomainUrl + "images/arrow-bt.png"} className="img-fluid arrow-bt float-end wow fadeInUp" />
              <div className="clear"></div>
              <div>
                <div className="bg-primary rounded-4 p-4 w-auto d-inline-block text-white">
                  Sustainability Assessment
                </div>
                <ul className="mt-3">
                  <li>Industry</li>
                  <li>Academia</li>
                </ul>
              </div>
            </Col>
            <Col className="d-flex justify-content-center">
              <div>
                <div className="bg-primary rounded-4 p-4 w-auto d-inline-block text-white">
                  Entrepreneurship
                </div>
              </div>
            </Col>
            <Col className="list position-relative">
              <img src={global.variables.strDomainUrl + "images/arrow-bt-right.png"} className="img-fluid arrow-bt-right float-start wow fadeInUp" />
              <div className="clear"></div>
              <div>
                <div className="bg-primary rounded-4 p-4 w-auto d-inline-block text-white mt-5">
                  Campus Sustainability
                </div>
                <ul className="mt-3">
                  <li>IITB</li>
                  <li>other Campus</li>
                </ul>
              </div>
            </Col>
          </Row> */}
        </Container>
      </div>

      <Container className="my-5">
        <Row className="g-0">
          <Col md={3} xs={7} className="py-md-5 container-start z-3 d-md-block d-none">
            <div className="pe-xl-5">
              <div className="border-bottom border-4 border-primary rounded-4 overflow-hidden wow fadeInUp">
                <div className="bg-primary bg-opacity-10 rounded-4 border h-100 wow fadeInUp">
                  <img src={global.variables.strDomainUrl + "images/pic-shireesh-kedare.jpg"} className="img-fluid" />
                </div>
              </div>
            </div>
          </Col>
          <Col md={10} lg={10}>
            <div className="border rounded-4 p-md-5 p-3">
              <div className="col-md-11 ms-auto">
                <div className="col-7 mb-4  d-md-none d-block border-bottom border-4 border-primary rounded-4 overflow-hidden wow fadeInUp">
                  <div className="bg-primary bg-opacity-10 rounded-4 border h-100 wow fadeInUp">
                    <img src={global.variables.strDomainUrl + "images/pic-shireesh-kedare.jpg"} className="img-fluid" />
                  </div>
                </div>
                <h2 className="text-primary wow fadeInUp">Director's Note</h2>
                <h6 className="mb-4 wow fadeInUp">Prof. Shireesh Kedare, Director, Indian Institute of Technology Bombay</h6>
                <p className="wow fadeInUp">Green energy and sustainability are the cornerstones of progress in the 21st century. As the world grapples with climate change and environmental degradation, the need for innovative solutions has never been more critical. Today, India stands at a pivotal point in its journey towards sustainable development. This vision aligns with the India Green Energy Mission, which aims to create a robust ecosystem for renewable energy and sustainability ecosystem and net zero emission target of 2070. IIT Bombay is a key player in this mission, contributing from policy development to practical implementation.</p>
                <p className="wow fadeInUp">IIT Bombay established the Research Hub for Green Energy and Sustainability Hub (GESH IITB) to integrate key resources and talent across all disciplines related to green energy and sustainability. GESH IITB aims to build a platform that propels India's ambitions towards global leadership in these critical areas. The vision of GESH IITB is to support the transition to green energy (environment-friendly and sustainable energy sources) and sustainability through research, education and capacity building, with academia, industry, regulators and other stakeholders. GESH commitment extends to advancing sustainability, climate services, solutions, and related domains.</p>
                <p className="m-0 wow fadeInUp">With our extensive network of industry partners, faculty, students, infrastructure, alumni with world-class experience, and policymakers, GESH IITB is poised to deliver transformational changes in the way research, training, incubation, management and policy development are conducted in the field of green energy and sustainability.</p>
              </div>
            </div>
          </Col>

        </Row>
      </Container >

      {/* Breadcrumb */}
      <Breadcrumb Breadcrumb className="breadcrumb-panel mt-auto" >
        <Breadcrumb.Item href={global.variables.strDomainUrl}>Home</Breadcrumb.Item>
        <Breadcrumb.Item active>About Us</Breadcrumb.Item>
      </Breadcrumb>
    </>
  );
}