import React, { useState, useEffect } from "react";
import global from "../../global-variables";
import Header from "../layout/header";
import { Link } from "react-router-dom";
import $ from "jquery";
import WOW from "wowjs";

import { Container, Row, Col } from "reactstrap";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Metatag from "../../hooks/Metatag";
import globalVariables from "../../global-variables";


export default function OURPartners() {
  const [show, setShow] = useState(false);

  useEffect(() => {
    $(window).on("scroll", function () {
      $(".navbar").offset().top > 10 ? $(".fixed-top").removeClass("bg-header-home") : $(".fixed-top").removeClass("bg-header-home")
    });

    // initiate the wow effect
    new WOW.WOW({
      live: false,
    }).init();


  }, []);


  return (
    <>
      <Header></Header>

      <Metatag title='Green Energy and Sustainability Research Partnerships by IIT Bombay'
        description='Green Energy and Sustainability Research Partnerships by IIT Bombay'
        keywords="Green Energy and Sustainability Research Partnerships by IIT Bombay"
      />
      {/* Visual Panel */}
      <Container fluid className="bg-visual-inside p-0" >
        <div className="visual-inside-big visual-container-inside">
          <Container>
            <Row className="g-md-0 rounded-4">
              <Col md={6}>
                <div className="d-flex align-items-end h-100 pb-md-5">
                  <div className="d-inline-block m-0 align-self-end py-xl-3 p-2 text-white">
                    <h1 className="fw-light text-white m-0 align-self-center wow fadeInUp">
                      Partnership Opportunities
                    </h1>
                  </div>
                </div>
              </Col>
              <Col md={6} className="mt-auto ms-auto">
                <div className="border-bottom border-5 border-primary rounded-4">
                  <img src={global.variables.strDomainUrl + "images/visual-partnership.jpg"} className="img-fluid rounded-4" alt="" title="" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>


      {/* Container */}
      <Container className="my-5 pt-md-5 pt-4">
        <Row className="g-0">
          <Col md={12} lg={12}>
            <div className=" border rounded-4 p-4">
              <h4 className="text-center mb-4 wow fadeInUp">
                GESH IITB Partnerships
              </h4>
              <div className="col-12 text-center">
                {/* <img src={global.variables.strDomainUrl + "images/pic-partnership.png"} alt="partnership" title="partnership" className="img-fluid mb-3 px-xl-5 wow fadeIn" /> */}

                <Row className="row-cols-1 row-cols-md-3 row-cols-xl-5 g-4">
                  <Col>
                    <div class="bg-primary bg-opacity-10 p-3 p-xl-3 rounded-4 h-100 text-center position-relative overflow-hidden">
                      <figure class="position-relative bg-primary overflow-hidden p-0 rounded-4 border-4 p-4 wow fadeInUp">
                        <img src={global.variables.strDomainUrl + "images/icon-global-academic.png"} alt="Global Academic & Research Institutions and Labs" title="Global Academic & Research Institutions and Labs" className="img-fluid" />
                      </figure>
                      <h6 class="px-md-3 pt-3 mb-1 lh-base text-dark wow fadeInUp">Universities/Research Partner</h6>
                      <p className="m-0 wow fadeInUp">Global Academic & Research Institutions and Labs</p>
                    </div>
                  </Col>
                  <Col>
                    <div class="bg-primary bg-opacity-10 p-3 p-xl-3 rounded-4 h-100 text-center position-relative overflow-hidden">
                      <figure class="position-relative bg-primary overflow-hidden p-0 rounded-4 border-4 p-4 wow fadeInUp">
                        <img src={global.variables.strDomainUrl + "images/icon-govt-bodies.png"} alt="Government Bodies / Ministry" title="Government Bodies / Ministry" className="img-fluid" />
                      </figure>
                      <h6 class="px-md-3 pt-3 mb-1 lh-base text-dark wow fadeInUp">Government Bodies / Ministry</h6>
                    </div>
                  </Col>
                  <Col>
                    <div class="bg-primary bg-opacity-10 p-3 p-xl-3 rounded-4 h-100 text-center position-relative overflow-hidden">
                      <figure class="position-relative bg-primary overflow-hidden p-0 rounded-4 border-4 p-4 wow fadeInUp">
                        <img src={global.variables.strDomainUrl + "images/icon-departments.png"} alt="IIT Bombay Departments, Centres, Research Labs, SINE, Admin, Student Bodies" title="IIT Bombay Departments, Centres, Research Labs, SINE, Admin, Student Bodies" className="img-fluid" />
                      </figure>
                      <h6 class="px-md-3 pt-3 mb-1 lh-base text-dark wow fadeInUp">IIT Bombay Departments, Centres, Research Labs, SINE, Admin, Student Bodies</h6>
                    </div>
                  </Col>
                  <Col>
                    <div class="bg-primary bg-opacity-10 p-3 p-xl-3 rounded-4 h-100 text-center position-relative overflow-hidden">
                      <figure class="position-relative bg-primary overflow-hidden p-0 rounded-4 border-4 p-4 wow fadeInUp">
                        <img src={global.variables.strDomainUrl + "images/icon-industries.png"} alt="Industries Private and Public Ltd." title="Industries Private and Public Ltd." className="img-fluid" />
                      </figure>
                      <h6 class="px-md-3 pt-3 mb-1 lh-base text-dark wow fadeInUp">Industry Partner</h6>
                      <p class="m-0 wow fadeInUp">Industries Private and <br/>Public Ltd.</p>
                    </div>
                  </Col>
                  <Col>
                    <div class="bg-primary bg-opacity-10 p-3 p-xl-3 rounded-4 h-100 text-center position-relative overflow-hidden">
                      <figure class="position-relative bg-primary overflow-hidden p-0 rounded-4 border-4 p-4 wow fadeInUp">
                        <img src={global.variables.strDomainUrl + "images/icon-ngos.png"} alt="NGOs / NOPs" title="NGOs / NOPs" className="img-fluid" />
                      </figure>
                      <h6 class="px-md-3 pt-3 mb-1 lh-base text-dark wow fadeInUp">NGOs / NOPs</h6>
                    </div>
                  </Col>
                </Row>

              </div>
            </div>
          </Col>
        </Row>
      </Container>


      {/* Breadcrumb */}
      <Breadcrumb className="breadcrumb-panel mt-auto">
        <Breadcrumb.Item href={globalVariables.variables.strDomainUrl}>Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Partnership Opportunities</Breadcrumb.Item>
      </Breadcrumb>
    </>
  );
}