import React,{useState,useEffect} from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';
import global from '../../global-variables';

export default function GetBlogComments(props) {

    const [getApprovedBlogCommentsList , setApprovedBlogCommentsList] = useState();

    useEffect(() => {
        let _obj = new Object();
        _obj.blog_comment_blog_id = props._blog_id;
        _obj.blog_comment_status = 1;
        _obj.blog_comment_flag = 1;
            axios.post(global.variables.strApiUrl + "/BlogCommentApi/getblogcommentList", _obj)
                .then((response_list) => {
                    if(response_list.data === 'error')
                    {
                        props._navigate('/error-page')
                    }
                    else
                    {
                        // pass the result in the success function of the respective page
                        setApprovedBlogCommentsList(response_list.data[0]);
                    }
                });

    }, [props._blog_id])
    
  return (
            getApprovedBlogCommentsList?.length > 0 ?
                getApprovedBlogCommentsList.map((val_comment,index)=>{
                return(

                    <div key={index} className="row my-3">
                        <div className="col-md-3 col-lg-3 text-primary">
                            {val_comment.blog_comment_name}
                            <span className="small d-block">{val_comment.blog_comment_date}</span>
                        </div>
                        <div className="col-md-9 col-lg-9  text-dark">
                            <p className="">{val_comment.blog_comment}</p>
                        </div>
                    </div>
                )
            })
        :
            null
  )
}
