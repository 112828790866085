import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import global from "../../global-variables";
import Header from "../layout/header";
import { Link } from "react-router-dom";
import $ from "jquery";
import WOW from "wowjs";
import { FaRegClock, FaRegCalendarAlt, FaLinkedinIn, FaInstagram, FaYoutube, FaPinterest, FaLink, FaImage, FaRegFilePdf, FaVideo, FaAngleLeft, FaCalendar } from "react-icons/fa";
import { FiMapPin } from "react-icons/fi";
import { BsPencilSquare } from "react-icons/bs";
import { BiCategoryAlt } from "react-icons/bi";
import { TbReceiptRupee } from "react-icons/tb";
import Metatag from '../../hooks/Metatag'

import { Container, Row, Col } from "reactstrap";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import FsLightbox from "fslightbox-react";

import { GetNewsList } from "../../api/news-functions";
import { GetNewsGalleryList } from "../../api/news-gallery-functions";
import globalVariables from "../../global-variables";
import { Button } from "react-bootstrap";

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Accordion from 'react-bootstrap/Accordion';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';


export default function EventDetails() {
  const [toggler, setToggler] = useState(true);
  const [videos, setvideosToggler] = useState(false);
  const [index, setIndex] = useState(0);

  const [key, setKey] = useState('Day 1');

  // constant to be used on the page
  const [news, setNewsList] = useState(null);

  const [news_gallery_toggler, setNewsGalleryToggler] = useState(false);
  const [news_gallery, setNewsGallery] = useState([]);

  const [lightbox_video, setLightBoxVideo] = useState([]);


  const [gallery_key, setGalleryKey] = useState("");

  const navigate = useNavigate();

  //Getting id from url
  let { page_name } = useParams();
  const qs_news_pagename = page_name;

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  // success function when news gallery is retrived
  const OnGetNewsGallerySuccess = (p_response) => {

    let _arrGallery = [];

    // loop and make the array for binding the gallery
    for (let i = 0; i < p_response.length; i++) {
      _arrGallery.push(global.variables.strApiUrl + "/news-gallery-image/" + p_response[i].gallery_pic);
    }

    // set the array in the constant
    setNewsGallery(_arrGallery);

    setGalleryKey(Date.now());

    // using time out for the value to get saved in state 
    setTimeout(() => {
      // trigger the link click to display the news gallery popup
      document.getElementById("lnk_trigger_news_gallery_popup_news").click();
    }, 200);

  }

  // function to invoke photo gallery
  const InvokePhotoGallery = (e, p_news_id) => {

    e.preventDefault();

    // Make an object for getting news gallery
    let _objNewsGallery = new Object();
    _objNewsGallery.gallery_status = 1;
    _objNewsGallery.gallery_news_id = p_news_id;

    // call the function to get the news gallery
    GetNewsGalleryList(_objNewsGallery, OnGetNewsGallerySuccess);

  };

  // function to invoke video popup
  const InvokeVideoPopup = (e, p_news_video_url) => {

    e.preventDefault();

    let _arrVideos = [];
    _arrVideos.push(p_news_video_url);

    // set the path to the constant for displaying
    setLightBoxVideo(_arrVideos);

    // trigger the link click to display the popup
    document.getElementById("lnk_trigger_video_popup_news").click();

  };

  // success function when the list is retrived
  const OnGetNewsSuccess = (p_response) => {
    // set the list for looping
    setNewsList(p_response);
  }

  useEffect(() => {

    // initiate the wow effect
    new WOW.WOW({
      live: false,
    }).init();


    var header = $(".rightpanel");
    $(window).scroll(function () {
      var scroll = $(window).scrollTop();
      if (scroll >= 250) {
        header.addClass('sticky');
      } else {
        header.removeClass('sticky');
      }
    });

  }, []);

  // things to be done when the page is loaded
  useEffect(() => {

    // make the object for getting the visual panel list
    let _objNews = new Object();
    _objNews._news_pagename = qs_news_pagename;
    _objNews._news_status = 1;

    // call the function to get the roadblock
    GetNewsList(_objNews, OnGetNewsSuccess);

  }, []);

  const [key2, setKey2] = useState('home');

  return (
    <>
      <Header></Header>

      {/* MetaTag */}
      <Metatag title={news != null ? news[0]?.news_pagetitle : null}
        description={news != null ? news[0]?.news_meta_description : null}
        keywords={news != null ? news[0]?.news_meta_keywords : null}
      />

      {/* News Visual Panel */}
      <Container fluid className="bg-visual-inside-eve-details p-0" >
        <div className="visual-inside-big">
          <Container>
            <Row className="g-md-0 rounded-4 justify-content-center align-items-center mt-md-5">
              <Col xs={8} md={6}>
                <h1 className="fw-light text-white d-inline-block m-0 align-self-center wow fadeInUp">
                  Events
                </h1>
              </Col>
              <Col xs={4} md={6}>
                <Button onClick={() => navigate(-1)} className="btn btn-primary rounded-pill Faicon px-3 py-1 ms-auto d-flex align-items-center wow fadeInUp">
                  <FaAngleLeft /> Back
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>

      {/* Events Container */}
      {
        news != null ?
          news.length > 0 ?
            <Container className="event-info">
              <div className="rounded-4 bg-light shadow-sm align-items-center position-relative overflow-hidden">
                <Row className="p-md-4 p-3 align-items-center">
                  <Col md={4} lg={6} className="ps-md-4 px-3 pe-md-5">
                    {
                      news[0].news_images != null && news[0].news_images != "null" && news[0].news_images != "" ?
                        <div className="position-relative">
                          <div className="event-category">Conference</div>
                          <img src={global.variables.strApiUrl + "/news-main-image/" + news[0].news_images} alt={news[0].news_headline} title={news[0].news_headline} className="img-fluid w-100 rounded-3 mb-md-0 mb-3 border" />
                        </div>
                        :
                        //default img
                        null
                    }
                  </Col>
                  <Col md={8} lg={6} className="d-flex align-items-start flex-column px-4">
                    <h5 className="mb-3 text-dark wow fadeInUp">
                      {news[0].news_headline}
                    </h5>
                    <div className="mb-2 d-flex gap-2 wow fadeInUp">
                      {/*when from and to date are diffrent  */}
                      <FaRegCalendarAlt className="icon-18" />
                      <div>
                        {
                          news[0].news_date != news[0].news_to_date ?
                            <>

                              {
                                news[0].from_mon != news[0].to_mon ?
                                  <>
                                    {` ${news[0].from_day}-${news[0].from_mon}-${news[0].from_year} to ${news[0].to_day}-${news[0].to_mon}-${news[0].to_year}`}

                                  </>
                                  :
                                  <>
                                    {` ${news[0].from_day}-${news[0].from_mon}-${news[0].from_year} to ${news[0].to_day}-${news[0].to_mon}-${news[0].to_year}`}
                                  </>
                              }
                            </>

                            :
                            // when from and to date same 
                            <>
                              {
                                //when from and to date's month is same
                                news[0].from_mon != news[0].to_mon ?
                                  <>
                                    {` ${news[0].from_day}-${news[0].from_mon}-${news[0].from_year} to ${news[0].to_day}-${news[0].to_mon}-${news[0].to_year}`}
                                  </>
                                  :
                                  <>
                                    {` ${news[0].from_day}-${news[0].from_mon}-${news[0].from_year}`}
                                  </>
                              }
                            </>

                        }
                      </div>

                    </div>

                    <div className="mb-2 d-flex gap-2 wow fadeInUp">
                      <FaRegClock className="icon-18" />
                      <div>
                        9:00 am Onwards
                      </div>
                    </div>
                    <div className="mb-2 d-flex gap-2 wow fadeInUp">
                      <FiMapPin className="icon-18" />
                      <div>
                        GESH IITB
                      </div>
                    </div>

                    <div className="mb-2 d-flex gap-2 wow fadeInUp d-none">
                      <FiMapPin className="icon-18" />
                      <div>
                        {news[0].news_caption != null && news[0].news_caption != "" && news[0].news_caption != "null" ?
                          <>Venue: {news[0].news_caption}</> : null}
                        {news[0].news_sourse != null && news[0].news_sourse != "" && news[0].news_sourse != "null" ? <><br /> Source: {news[0].news_sourse}</> : null} <br />
                      </div>
                    </div>

                    {/* Details */}
                    {
                      news[0].news_details != null && news[0].news_details != "null" && news[0].news_details != "" ?
                        <div className=" wow fadeInUp d-none"><span dangerouslySetInnerHTML={{ __html: news[0].news_details }}></span></div>
                        : null
                    }

                    <div className="d-md-flex mt-3 w-100">
                      <div className="d-flex gap-2">
                        {/* link */}
                        <Link to={news[0].news_link} target="_blank" className="btn btn-primary rounded-pill px-3 py-1 wow fadeInUp"><FaLink className=" icon-16" /></Link>

                        {/* Brochure */}
                        <Link className="btn btn-primary rounded-pill px-3 py-1 wow fadeInUp" to="#" target="_blank" ><FaRegFilePdf className="fw-bold icon-16" /></Link>

                        {/* Video */}
                        <Link className="btn btn-primary rounded-pill px-3 py-1 wow fadeInUp" to="#"><FaVideo className="fw-bold icon-16" /></Link>
                      </div>
                      <div className="ms-auto mt-3 mt-md-0">
                        {/* Brochure */}
                        <Link className="btn btn-outline-primary rounded-pill px-3 py-1 wow fadeInUp" to="#" target="_blank" ><BsPencilSquare className="fw-bold icon-16" /> Register Now</Link>
                      </div>
                    </div>

                    <div className="d-block d-md-none mt-4">
                      <p><b>Fees:</b> INR 5000</p>

                      <p className="mb-1 fw-bold">Contact Details</p>
                      <p>MR. XYZ<br />
                        99999 99999<br />
                        xyz@gmail.com<br />
                      </p>
                      <p>
                        MR. XYZ<br />
                        99999 99999<br />
                        xyz@gmail.com<br />
                      </p>
                    </div>
                    <FsLightbox toggler={toggler}
                      sources={[
                        "images/news/Digital-Health-Insights.png",
                        "https://i.imgur.com/fsyrScY.jpg",
                      ]}
                    />
                  </Col>
                </Row>
              </div>
            </Container>
            :
            <Container className="mb-5 h-100">
              <Row>
                <span className="text-center">No Records Found</span>
              </Row>
            </Container>
          :
          <Container className="mb-5 h-100">
            <Row>
              <span className="text-center">Loading...</span>
            </Row>
          </Container>
      }

      <Container>
        <Row className="g-0">
          <Col md={10} lg={10}>
            <div className="border rounded-4 my-5">
              {/* Event Details */}
              <div className="my-md-5 my-4 px-lg-5 px-4 position-relative overflow-hidden">
                <div className="pe-md-2 col-md-11">
                  <div id="AboutEvent">
                    <h4 className="mb-3 wow fadeInUp">About Event</h4>
                    <p className="wow fadeInUp">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed efficitur egestas posuere. Aliquam tristique turpis eu nibh vestibulum, placerat maximus erat dapibus. Nam vulputate pharetra ex, vitae pretium dui pharetra sed. Ut lacus mauris, ornare ac nisi sed, elementum vehicula ipsum. Vivamus dignissim eu ex vitae cursus. Praesent laoreet non elit sagittis porta. Duis pulvinar suscipit enim ac congue. Morbi nisi diam, malesuada sed turpis quis, rhoncus fermentum nisl. Integer eget leo porta, molestie nibh porttitor, aliquam nulla. Sed vitae orci nec tellus condimentum mattis imperdiet in felis. Sed blandit sapien ornare, aliquam urna ut, dignissim massa. Aenean aliquam nunc nibh, viverra dictum nisi lobortis non.</p>
                  </div>
                </div>
              </div>

              {/* Event Speakers */}
              <div className="my-md-5 my-4 px-lg-5 px-4 bg-light py-md-5 py-4 position-relative overflow-hidden">
                <div className="pe-md-2 col-md-11">
                  <div id="EventSpeakers">
                    <h4 className="mb-3 wow fadeInUp">Event Speakers</h4>
                    <Row className="row-cols-1 row-cols-md-2 row-cols-lg-4 g-4">
                      <Col>
                        <div class="h-100 border p-3 rounded-3 text-center">
                          <Link tp="" target="_blank" className="text-decoration-none">
                            <div class="col-12">
                              <img src={global.variables.strDomainUrl + "images/people/people-default.jpg"} alt="Prof. Devang Khakkar" title="Prof. Devang Khakkar" className="img-fluid rounded-circle mb-3 wow fadeInUp" />
                            </div>
                            <div class="d-flex flex-column">
                              <h6 class="text-dark mb-1 wow fadeInUp">Prof. Devang Khakkar</h6>
                              <p className="m-0 wow fadeInUp">Former Director</p>
                              <p className="m-0 wow fadeInUp">IIT Bombay</p>
                            </div>
                          </Link>
                        </div>
                      </Col>
                      <Col>
                        <div class="h-100 border p-3 rounded-3 text-center">

                          <Link tp="" target="_blank" className="text-decoration-none">
                            <div class="col-12">
                              <img src={global.variables.strDomainUrl + "images/people/people-default.jpg"} alt="Prof. Devang Khakkar" title="Prof. Devang Khakkar" className="img-fluid rounded-circle mb-3 wow fadeInUp" />
                            </div>
                            <div class="d-flex flex-column">
                              <h6 class="text-dark mb-1 wow fadeInUp">Mr. Ashish Goel</h6>
                              <p className="m-0 wow fadeInUp">President</p>
                              <p className="m-0 wow fadeInUp">InTech Energy DBA Sanalife</p>
                            </div>
                          </Link>
                        </div>
                      </Col>
                      <Col>
                        <div class="h-100 border p-3 rounded-3 text-center">

                          <Link tp="" target="_blank" className="text-decoration-none">
                            <div class="col-12">
                              <img src={global.variables.strDomainUrl + "images/people/people-default.jpg"} alt="Prof. Devang Khakkar" title="Prof. Devang Khakkar" className="img-fluid rounded-circle mb-3 wow fadeInUp" />
                            </div>
                            <div class="d-flex flex-column">
                              <h6 class="text-dark mb-1 wow fadeInUp">Prof. Ravindra Gudi</h6>
                              <p className="m-0 wow fadeInUp">Dean</p>
                              <p className="m-0 wow fadeInUp">ACR IITB</p>
                            </div>
                          </Link>
                        </div>
                      </Col>
                      <Col>
                        <div class="h-100 border p-3 rounded-3 text-center">

                          <Link tp="" target="_blank" className="text-decoration-none">
                            <div class="col-12">
                              <img src={global.variables.strDomainUrl + "images/people/people-default.jpg"} alt="Prof. Devang Khakkar" title="Prof. Devang Khakkar" className="img-fluid rounded-circle mb-3 wow fadeInUp" />
                            </div>
                            <div class="d-flex flex-column">
                              <h6 class="text-dark mb-1 wow fadeInUp">Prof. Ravindra Gudi</h6>
                              <p className="m-0 wow fadeInUp">Dean</p>
                              <p className="m-0 wow fadeInUp">ACR IITB</p>
                            </div>
                          </Link>
                        </div>
                      </Col>
                    </Row>

                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="1">
                        <Accordion.Body className="px-0 pt-4 pb-0">
                          <Row className="row-cols-1 row-cols-md-2 row-cols-lg-4 g-4">
                            <Col>
                              <div class="h-100 border p-3 rounded-3 text-center">
                                <Link tp="" target="_blank" className="text-decoration-none">
                                  <div class="col-12">
                                    <img src={global.variables.strDomainUrl + "images/people/people-default.jpg"} alt="Prof. Devang Khakkar" title="Prof. Devang Khakkar" className="img-fluid rounded-circle mb-3 wow fadeInUp" />
                                  </div>
                                  <div class="d-flex flex-column">
                                    <h6 class="text-dark mb-1 wow fadeInUp">Prof. Devang Khakkar</h6>
                                    <p className="m-0 wow fadeInUp">Former Director</p>
                                    <p className="m-0 wow fadeInUp">IIT Bombay</p>
                                  </div>
                                </Link>
                              </div>
                            </Col>
                            <Col>
                              <div class="h-100 border p-3 rounded-3 text-center">

                                <Link tp="" target="_blank" className="text-decoration-none">
                                  <div class="col-12">
                                    <img src={global.variables.strDomainUrl + "images/people/people-default.jpg"} alt="Prof. Devang Khakkar" title="Prof. Devang Khakkar" className="img-fluid rounded-circle mb-3 wow fadeInUp" />
                                  </div>
                                  <div class="d-flex flex-column">
                                    <h6 class="text-dark mb-1 wow fadeInUp">Mr. Ashish Goel</h6>
                                    <p className="m-0 wow fadeInUp">President</p>
                                    <p className="m-0 wow fadeInUp">InTech Energy DBA Sanalife</p>
                                  </div>
                                </Link>
                              </div>
                            </Col>
                            <Col>
                              <div class="h-100 border p-3 rounded-3 text-center">

                                <Link tp="" target="_blank" className="text-decoration-none">
                                  <div class="col-12">
                                    <img src={global.variables.strDomainUrl + "images/people/people-default.jpg"} alt="Prof. Devang Khakkar" title="Prof. Devang Khakkar" className="img-fluid rounded-circle mb-3 wow fadeInUp" />
                                  </div>
                                  <div class="d-flex flex-column">
                                    <h6 class="text-dark mb-1 wow fadeInUp">Prof. Ravindra Gudi</h6>
                                    <p className="m-0 wow fadeInUp">Dean</p>
                                    <p className="m-0 wow fadeInUp">ACR IITB</p>
                                  </div>
                                </Link>
                              </div>
                            </Col>
                            <Col>
                              <div class="h-100 border p-3 rounded-3 text-center">
                                <Link tp="" target="_blank" className="text-decoration-none">
                                  <div class="col-12">
                                    <img src={global.variables.strDomainUrl + "images/people/people-default.jpg"} alt="Prof. Devang Khakkar" title="Prof. Devang Khakkar" className="img-fluid rounded-circle mb-3 wow fadeInUp" />
                                  </div>
                                  <div class="d-flex flex-column">
                                    <h6 class="text-dark mb-1 wow fadeInUp">Prof. Ravindra Gudi</h6>
                                    <p className="m-0 wow fadeInUp">Dean</p>
                                    <p className="m-0 wow fadeInUp">ACR IITB</p>
                                  </div>
                                </Link>
                              </div>
                            </Col>
                          </Row>
                        </Accordion.Body>
                        <Accordion.Header className="mt-4">
                          <a class="btn btn-primary rounded-pill px-4 py-2 mx-auto" target="_blank"></a>
                        </Accordion.Header>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </div>
              </div>

              {/* Event Organising Team */}
              <div className="my-md-5 my-4 px-lg-5 px-4 position-relative overflow-hidden">
                <div className="pe-md-2 col-md-11">
                  <div id="EventSpeakers">
                    <h4 className="mb-3 wow fadeInUp">Organising Team</h4>
                    <Row className="row-cols-1 row-cols-md-2 row-cols-lg-4 g-4">
                      <Col>
                        <div class="h-100 border p-3 rounded-3 text-center">
                          <Link tp="" target="_blank" className="text-decoration-none">
                            <div class="col-12">
                              <img src={global.variables.strDomainUrl + "images/people/people-default.jpg"} alt="Prof. Devang Khakkar" title="Prof. Devang Khakkar" className="img-fluid rounded-circle mb-3 wow fadeInUp" />
                            </div>
                            <div class="d-flex flex-column">
                              <h6 class="text-dark mb-1 wow fadeInUp">Prof. Devang Khakkar</h6>
                              <p className="m-0 wow fadeInUp">Former Director</p>
                              <p className="m-0 wow fadeInUp">IIT Bombay</p>
                            </div>
                          </Link>
                        </div>
                      </Col>
                      <Col>
                        <div class="h-100 border p-3 rounded-3 text-center">

                          <Link tp="" target="_blank" className="text-decoration-none">
                            <div class="col-12">
                              <img src={global.variables.strDomainUrl + "images/people/people-default.jpg"} alt="Prof. Devang Khakkar" title="Prof. Devang Khakkar" className="img-fluid rounded-circle mb-3 wow fadeInUp" />
                            </div>
                            <div class="d-flex flex-column">
                              <h6 class="text-dark mb-1 wow fadeInUp">Mr. Ashish Goel</h6>
                              <p className="m-0 wow fadeInUp">President</p>
                              <p className="m-0 wow fadeInUp">InTech Energy DBA Sanalife</p>
                            </div>
                          </Link>
                        </div>
                      </Col>
                      <Col>
                        <div class="h-100 border p-3 rounded-3 text-center">

                          <Link tp="" target="_blank" className="text-decoration-none">
                            <div class="col-12">
                              <img src={global.variables.strDomainUrl + "images/people/people-default.jpg"} alt="Prof. Devang Khakkar" title="Prof. Devang Khakkar" className="img-fluid rounded-circle mb-3 wow fadeInUp" />
                            </div>
                            <div class="d-flex flex-column">
                              <h6 class="text-dark mb-1 wow fadeInUp">Prof. Ravindra Gudi</h6>
                              <p className="m-0 wow fadeInUp">Dean</p>
                              <p className="m-0 wow fadeInUp">ACR IITB</p>
                            </div>
                          </Link>
                        </div>
                      </Col>
                      <Col>
                        <div class="h-100 border p-3 rounded-3 text-center">

                          <Link tp="" target="_blank" className="text-decoration-none">
                            <div class="col-12">
                              <img src={global.variables.strDomainUrl + "images/people/people-default.jpg"} alt="Prof. Devang Khakkar" title="Prof. Devang Khakkar" className="img-fluid rounded-circle mb-3 wow fadeInUp" />
                            </div>
                            <div class="d-flex flex-column">
                              <h6 class="text-dark mb-1 wow fadeInUp">Prof. Ravindra Gudi</h6>
                              <p className="m-0 wow fadeInUp">Dean</p>
                              <p className="m-0 wow fadeInUp">ACR IITB</p>
                            </div>
                          </Link>
                        </div>
                      </Col>
                    </Row>

                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="1">
                        <Accordion.Body className="px-0 pt-4 pb-0">
                          <Row className="row-cols-1 row-cols-md-2 row-cols-lg-4 g-4">
                            <Col>
                              <div class="h-100 border p-3 rounded-3 text-center">
                                <Link tp="" target="_blank" className="text-decoration-none">
                                  <div class="col-12">
                                    <img src={global.variables.strDomainUrl + "images/people/people-default.jpg"} alt="Prof. Devang Khakkar" title="Prof. Devang Khakkar" className="img-fluid rounded-circle mb-3 wow fadeInUp" />
                                  </div>
                                  <div class="d-flex flex-column">
                                    <h6 class="text-dark mb-1 wow fadeInUp">Prof. Devang Khakkar</h6>
                                    <p className="m-0 wow fadeInUp">Former Director</p>
                                    <p className="m-0 wow fadeInUp">IIT Bombay</p>
                                  </div>
                                </Link>
                              </div>
                            </Col>
                            <Col>
                              <div class="h-100 border p-3 rounded-3 text-center">

                                <Link tp="" target="_blank" className="text-decoration-none">
                                  <div class="col-12">
                                    <img src={global.variables.strDomainUrl + "images/people/people-default.jpg"} alt="Prof. Devang Khakkar" title="Prof. Devang Khakkar" className="img-fluid rounded-circle mb-3 wow fadeInUp" />
                                  </div>
                                  <div class="d-flex flex-column">
                                    <h6 class="text-dark mb-1 wow fadeInUp">Mr. Ashish Goel</h6>
                                    <p className="m-0 wow fadeInUp">President</p>
                                    <p className="m-0 wow fadeInUp">InTech Energy DBA Sanalife</p>
                                  </div>
                                </Link>
                              </div>
                            </Col>
                            <Col>
                              <div class="h-100 border p-3 rounded-3 text-center">

                                <Link tp="" target="_blank" className="text-decoration-none">
                                  <div class="col-12">
                                    <img src={global.variables.strDomainUrl + "images/people/people-default.jpg"} alt="Prof. Devang Khakkar" title="Prof. Devang Khakkar" className="img-fluid rounded-circle mb-3 wow fadeInUp" />
                                  </div>
                                  <div class="d-flex flex-column">
                                    <h6 class="text-dark mb-1 wow fadeInUp">Prof. Ravindra Gudi</h6>
                                    <p className="m-0 wow fadeInUp">Dean</p>
                                    <p className="m-0 wow fadeInUp">ACR IITB</p>
                                  </div>
                                </Link>
                              </div>
                            </Col>
                            <Col>
                              <div class="h-100 border p-3 rounded-3 text-center">
                                <Link tp="" target="_blank" className="text-decoration-none">
                                  <div class="col-12">
                                    <img src={global.variables.strDomainUrl + "images/people/people-default.jpg"} alt="Prof. Devang Khakkar" title="Prof. Devang Khakkar" className="img-fluid rounded-circle mb-3 wow fadeInUp" />
                                  </div>
                                  <div class="d-flex flex-column">
                                    <h6 class="text-dark mb-1 wow fadeInUp">Prof. Ravindra Gudi</h6>
                                    <p className="m-0 wow fadeInUp">Dean</p>
                                    <p className="m-0 wow fadeInUp">ACR IITB</p>
                                  </div>
                                </Link>
                              </div>
                            </Col>
                          </Row>
                        </Accordion.Body>
                        <Accordion.Header className="mt-4">
                          <a class="btn btn-primary rounded-pill px-4 py-2 mx-auto" target="_blank"></a>
                        </Accordion.Header>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </div>
              </div>

              {/* Event Schedule */}
              <div className="my-md-5 my-4 px-lg-5 px-4 bg-light py-md-5 py-4 position-relative overflow-hidden">
                <div className="pe-md-2 col-md-11">
                  <div id="EventSchedule">
                    <div className="mb-4">
                      <h4 className="wow fadeInUp">Event Schedule</h4>
                      <div className="mb-2 d-flex align-items-center fw-bold text-primary wow fadeInUp">04-Oct-2024 to 06-Oct-2024 </div>
                    </div>
                    <div className="">
                      <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)} onOverflow={"scroll"}>
                        <Tab eventKey="Day 1" title="Day 1">
                          <Accordion defaultActiveKey="0" className="p-md-3 p-2 rounded-3 bg-everight">
                            <Accordion.Item eventKey="0" className="mb-2 overflow-hidden">
                              <Accordion.Header>
                                <Row className="w-100">
                                  <Col className="col-md-3 col-12"><h5 className="m-0">09:30 - 10:30 AM</h5></Col>
                                  <Col className="col-md-9 col-12"><h5 className="m-0">Registration & Breakfast</h5></Col>
                                </Row>
                              </Accordion.Header>
                              <Accordion.Body>
                                How you transform your business as technology, consumer, habits industry dynamics change? Find out from those in 2020.
                              </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="1" className="mb-2 overflow-hidden">
                              <Accordion.Header>
                                <Row className="w-100">
                                  <Col className="col-md-3 col-12"><h5 className="m-0">10:30 - 12:30 PM</h5></Col>
                                  <Col className="col-md-9 col-12"><h5 className="m-0">Speech: Marketing Matters!</h5></Col>
                                </Row>
                              </Accordion.Header>
                              <Accordion.Body>
                                How you transform your business as technology, consumer, habits industry dynamics change? Find out from those in 2020.
                              </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="2" className="mb-2 overflow-hidden">
                              <Accordion.Header>
                                <Row className="w-100">
                                  <Col className="col-md-3 col-12"><h5 className="m-0">12:30 - 02:00 pm</h5></Col>
                                  <Col className="col-md-9 col-12"><h5 className="m-0">Lunch Break</h5></Col>
                                </Row>
                              </Accordion.Header>
                              <Accordion.Body>
                                How you transform your business as technology, consumer, habits industry dynamics change? Find out from those in 2020.
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        </Tab>
                        <Tab eventKey="Day 2" title="Day 2">
                          <Accordion defaultActiveKey="0" className="p-md-3 p-2 rounded-3 bg-everight">
                            <Accordion.Item eventKey="0" className="mb-2 overflow-hidden">
                              <Accordion.Header>
                                <Row className="w-100">
                                  <Col className="col-md-3 col-12"><h5 className="m-0">09:30 - 10:30 AM</h5></Col>
                                  <Col className="col-md-9 col-12"><h5 className="m-0">Registration & Breakfast</h5></Col>
                                </Row>
                              </Accordion.Header>
                              <Accordion.Body>
                                How you transform your business as technology, consumer, habits industry dynamics change? Find out from those in 2020.
                              </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="1" className="mb-2 overflow-hidden">
                              <Accordion.Header>
                                <Row className="w-100">
                                  <Col className="col-md-3 col-12"><h5 className="m-0">10:30 - 12:30 PM</h5></Col>
                                  <Col className="col-md-9 col-12"><h5 className="m-0">Speech: Marketing Matters!</h5></Col>
                                </Row>
                              </Accordion.Header>
                              <Accordion.Body>
                                How you transform your business as technology, consumer, habits industry dynamics change? Find out from those in 2020.
                              </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="2" className="mb-2 overflow-hidden">
                              <Accordion.Header>
                                <Row className="w-100">
                                  <Col className="col-md-3 col-12"><h5 className="m-0">12:30 - 02:00 pm</h5></Col>
                                  <Col className="col-md-9 col-12"><h5 className="m-0">Lunch Break</h5></Col>
                                </Row>
                              </Accordion.Header>
                              <Accordion.Body>
                                How you transform your business as technology, consumer, habits industry dynamics change? Find out from those in 2020.
                              </Accordion.Body>
                            </Accordion.Item>

                          </Accordion>
                        </Tab>
                        <Tab eventKey="Day 3" title="Day 3">
                          <Accordion defaultActiveKey="0" className="p-md-3 p-2 rounded-3 bg-everight">
                            <Accordion.Item eventKey="0" className="mb-2 overflow-hidden">
                              <Accordion.Header>
                                <Row className="w-100">
                                  <Col className="col-md-3 col-12"><h5 className="m-0">09:30 - 10:30 AM</h5></Col>
                                  <Col className="col-md-9 col-12"><h5 className="m-0">Registration & Breakfast</h5></Col>
                                </Row>
                              </Accordion.Header>
                              <Accordion.Body>

                                How you transform your business as technology, consumer, habits industry dynamics change? Find out from those in 2020.
                              </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="1" className="mb-2 overflow-hidden">
                              <Accordion.Header>
                                <Row className="w-100">
                                  <Col className="col-md-3 col-12"><h5 className="m-0">10:30 - 12:30 PM</h5></Col>
                                  <Col className="col-md-9 col-12"><h5 className="m-0">Speech: Marketing Matters!</h5></Col>
                                </Row>
                              </Accordion.Header>
                              <Accordion.Body>

                                How you transform your business as technology, consumer, habits industry dynamics change? Find out from those in 2020.
                              </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="2" className="mb-2 overflow-hidden">
                              <Accordion.Header>
                                <Row className="w-100">
                                  <Col className="col-md-3 col-12"><h5 className="m-0">12:30 - 02:00 pm</h5></Col>
                                  <Col className="col-md-9 col-12"><h5 className="m-0">Lunch Break</h5></Col>
                                </Row>
                              </Accordion.Header>
                              <Accordion.Body>
                                How you transform your business as technology, consumer, habits industry dynamics change? Find out from those in 2020.
                              </Accordion.Body>
                            </Accordion.Item>

                          </Accordion>
                        </Tab>
                      </Tabs>
                      <Tabs
                      id="controlled-tab-example"
                      activeKey={key2}
                      onSelect={(k) => setKey2(k)}
                      className="mb-3"
                    >
                      <Tab eventKey="home" title="Home">
                        Tab content for Home
                      </Tab>
                      <Tab eventKey="profile" title="Profile">
                        Tab content for Profile
                      </Tab>
                      <Tab eventKey="contact" title="Contact" disabled>
                        Tab content for Contact
                      </Tab>
                    </Tabs>
                    </div>
                  </div>
                </div>
              </div>

              {/* Event Partners */}
              <div className="my-md-5 my-4 px-lg-5 px-4 position-relative overflow-hidden">
                <div className="pe-md-2 col-md-11">
                  <div className="mb-4">
                    <h4 className="wow fadeInUp">Event Sponsors</h4>
                  </div>
                  <Row className="row-cols-1 gy-4">
                    <Col>
                      <h5 className="text-primary fw-bold mb-2 wow fadeInUp">Our Platinum Sponsors</h5>
                      <Row className="row-cols-2 row-cols-xl-5 g-4">
                        <Col>
                          <div className="border bg-white text-center rounded-3 px-md-5 px-3 py-2 wow fadeInUp">
                            <img src={global.variables.strDomainUrl + "images/logo-iitb.png"} alt="IITB" title="IITB" className="img-fluid p-0" />
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <div className="text-dark opacity-50">
                      <hr className="m-0" />
                    </div>
                    <Col>
                      <h5 className="text-primary fw-bold mb-2 wow fadeInUp">Our Diamond Sponsors</h5>
                      <Row className="row-cols-2 row-cols-xl-5 g-4">
                        <Col>
                          <div className="border bg-white text-center rounded-3 px-md-5 px-3 py-2 wow fadeInUp">
                            <img src={global.variables.strDomainUrl + "images/logo-gesh-iitb.png"} alt="GESH IITB" title="GESH IITB" className="img-fluid p-0" />
                          </div>
                        </Col>
                        <Col>
                          <div className="border bg-white text-center rounded-3 px-md-5 px-3 py-2 wow fadeInUp">
                            <img src={global.variables.strDomainUrl + "images/logo-abc.png"} alt="Advanced Batteries Ceramics" title="Advanced Batteries Ceramics" className="img-fluid p-0" />
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <div className="text-dark opacity-50">
                      <hr className="m-0" />
                    </div>
                    <Col>
                      <h5 className="text-primary fw-bold mb-2 wow fadeInUp">Our Gold Sponsors</h5>
                      <Row className="row-cols-2 row-cols-xl-5 g-4">
                        <Col>
                          <div className="border bg-white text-center rounded-3 px-md-5 px-3 py-2 wow fadeInUp">
                            <img src={global.variables.strDomainUrl + "images/logo-brsi.png"} alt="Battery Research Society" title="Battery Research Society" className="img-fluid p-0" />
                          </div>
                        </Col>
                        <Col>
                          <div className="border bg-white text-center rounded-3 px-md-5 px-3 py-2 wow fadeInUp">
                            <img src={global.variables.strDomainUrl + "images/logo-esi.png"} alt="The Electrochemical Society of India" title="The Electrochemical Society of India" className="img-fluid p-0" />
                          </div>
                        </Col>
                        <Col>
                          <div className="border bg-white text-center rounded-3 px-md-5 px-3 py-2 wow fadeInUp">
                            <img src={global.variables.strDomainUrl + "images/logo-brsi.png"} alt="Battery Research Society" title="Battery Research Society" className="img-fluid p-0" />
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </div>

              {/* Event Venue */}
              <div className="my-md-5 my-4 px-lg-5 px-4 bg-light py-md-5 py-4 position-relative overflow-hidden">
                <div className="pe-md-2 col-md-11">
                  <Row>
                    <Col md={4}>
                      <div className="mb-4">
                        <h3 className="wow fadeInUp">How To Reach</h3>
                      </div>
                      <div className="mb-3">
                        <h6 className="fw-medium d-block">Venue</h6>
                        GESH IITB
                      </div>
                      <div className="mb-3">
                        <h6 className="fw-medium d-block">Address</h6>
                        IIT Area, Powai, Mumbai, Maharashtra 400076
                      </div>
                      <div className="mb-3 d-block d-md-none">
                        <h6 className="fw-medium d-block">We’re in Social</h6>
                        <ul className="social_media flex-grow-0 w-auto wow fadeInUp">
                          <li className="linkedin bg-primary bg-opacity-10">
                            <Link to="#" target="_blank" rel="noreferrer noopener" >
                              <FaLinkedinIn className="icon-20" />
                            </Link>
                          </li>
                          <li className="instagram bg-primary bg-opacity-10">
                            <Link to="#" target="_blank" rel="noreferrer noopener" >
                              <FaInstagram className="icon-20" />
                            </Link>
                          </li>
                          <li className="youtube bg-primary bg-opacity-10">
                            <Link to="#" target="_blank" rel="noreferrer noopener" >
                              <FaYoutube className="icon-20" />
                            </Link>
                          </li>
                          <li className="pinterest bg-primary bg-opacity-10">
                            <Link to="#" target="_blank" rel="noreferrer noopener" >
                              <FaPinterest className="icon-20" />
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </Col>
                    <Col md={8}>
                      <div className="ratio ratio-21x9 rounded-4 overflow-hidden">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3769.4315575938404!2d72.9173959!3d19.132577700000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c7f5c5583633%3A0x892b212b62b3b237!2sVictor%20Menezes%20Convention%20Centre!5e0!3m2!1sen!2sin!4v1725886183918!5m2!1sen!2sin" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>

              {/* Event Archive */}
              <div className="my-md-5 my-4 px-lg-5 px-4 position-relative overflow-hidden">
                <div className="pe-md-2 col-md-11">
                  <div className="mb-4">
                    <h3 className="wow fadeInUp">Event Photo Gallery</h3>
                  </div>
                  <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 1280: 3 }}>
                    <Masonry gutter="1.5rem">

                      <div>
                        <Link className="pointer" onClick={() => setToggler(!toggler)}>
                          <img src={global.variables.strDomainUrl + "images/pic-sustainability-assessment.jpg"} alt="" title="" className="img-fluid p-0 rounded-3 border bg-white wow fadeInUp" />
                        </Link>
                      </div>
                      <div>
                        <img src={global.variables.strDomainUrl + "images/bg-academics.jpg"} alt="" title="" className="img-fluid p-0 rounded-3 border bg-white wow fadeInUp" />
                      </div>
                      <div>
                        <img src={global.variables.strDomainUrl + "images/pic-event.jpg"} alt="" title="" className="img-fluid p-0 rounded-3 border bg-white wow fadeInUp" />
                      </div>

                      <div>
                        <img src={global.variables.strDomainUrl + "images/news/director.jpg"} alt="" title="" className="img-fluid p-0 rounded-3 border bg-white wow fadeInUp" />
                      </div>
                      <div>
                        <img src={global.variables.strDomainUrl + "images/pic-sustainability-assessment.jpg"} alt="" title="" className="img-fluid p-0 rounded-3 border bg-white wow fadeInUp" />
                      </div>

                      <div>
                        <img src={global.variables.strDomainUrl + "images/pic-icsr.jpg"} alt="" title="" className="img-fluid p-0 rounded-3 border bg-white wow fadeInUp" />
                      </div>
                      <div>
                        <img src={global.variables.strDomainUrl + "images/news/director.jpg"} alt="" title="" className="img-fluid p-0 rounded-3 border bg-white wow fadeInUp" />
                      </div>
                    </Masonry>
                  </ResponsiveMasonry >
                </div>
              </div>
            </div>
          </Col>
          <Col md={3} lg={3} className="py-md-5 mt-md-5 mb-5 mb-md-4 container-end position-relative z-3 d-none d-md-block">
            <div className="ps-lg-5 ps-md-4 rightpanel">
              <div className="border-bottom border-4 border-primary rounded-4 h-100 mb-3">
                <div className="bg-everight rounded-4 py-3 px-4 border h-100">
                  <div>
                    <p className="fw-bold mb-1 wow fadeInUp">Event Details</p>
                    <div className="mb-1 d-flex gap-2 wow fadeInUp">
                      <FaRegCalendarAlt className="icon-16" />
                      <div>
                        04-Oct-2024
                      </div>
                    </div>
                    <div className="mb-1 d-flex gap-2 wow fadeInUp">
                      <FaRegClock className="icon-16" />
                      <div>
                        9:00 am Onwards
                      </div>
                    </div>
                    <div className="mb-1 d-flex gap-2 wow fadeInUp">
                      <FiMapPin className="icon-16" />
                      <div>
                        GESH IITB
                      </div>
                    </div>
                    <div className="mb-1 d-flex gap-2 wow fadeInUp">
                      <BsPencilSquare className="icon-16" />
                      <div>
                        <Link to="#" target="_blank" className="text-black">Click here to Register</Link>
                      </div>
                    </div>
                  </div>
                  <div className="text-dark opacity-50">
                    <hr className="my-3" />
                  </div>
                  <div>
                    <b className="fw-bold">Fees:</b> INR 5000
                  </div>
                  <div className="text-dark opacity-50">
                    <hr className="my-3" />
                  </div>
                  <div>
                    <p className="fw-bold mb-1 wow fadeInUp">Contact Details</p>
                    <p>MR. XYZ<br />
                      99999 99999<br />
                      xyz@gmail.com<br />
                    </p>
                    <p>
                      MR. XYZ<br />
                      99999 99999<br />
                      xyz@gmail.com<br />
                    </p>
                  </div>
                  <div className="text-dark opacity-50">
                    <hr className="my-3" />
                  </div>
                  <div>
                    <p className="fw-bold mb-1 wow fadeInUp">We’re in Social</p>
                    <ul className="social_media flex-grow-0 w-auto wow fadeInUp">
                      <li className="linkedin">
                        <Link to="#" target="_blank" rel="noreferrer noopener" >
                          <FaLinkedinIn className="icon-20" />
                        </Link>
                      </li>
                      <li className="instagram">
                        <Link to="#" target="_blank" rel="noreferrer noopener" >
                          <FaInstagram className="icon-20" />
                        </Link>
                      </li>
                      <li className="youtube">
                        <Link to="#" target="_blank" rel="noreferrer noopener" >
                          <FaYoutube className="icon-20" />
                        </Link>
                      </li>
                      <li className="pinterest">
                        <Link to="#" target="_blank" rel="noreferrer noopener" >
                          <FaPinterest className="icon-20" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* <div className="border-bottom border-4 border-primary rounded-4 h-100 mb-3">
                <div className="bg-everight rounded-4 py-3 px-4 border h-100">
                  <div>
                    <h6 className="fw-bold wow fadeInUp">Fees</h6>
                    INR 5000
                  </div>
                </div>
              </div>

              <div className="border-bottom border-4 border-primary rounded-4 h-100 mb-3">
                <div className="bg-everight rounded-4 py-3 px-4 border h-100">
                  <div>
                    <h6 className="fw-bold wow fadeInUp">Registration</h6>
                    <Link to="#" target="_blank" className="btn btn-outline-primary rounded-pill px-4 py-2 wow fadeInUp"><BsPencilSquare className=" icon-16" /> Click here to Register</Link>
                  </div>
                </div>
              </div>

              <div className="border-bottom border-4 border-primary rounded-4 h-100">
                <div className="bg-everight rounded-4 py-3 px-4 border h-100">
                  <div>
                    <h6 className="fw-bold">We’re in Social</h6>
                    <ul className="social_media flex-grow-0 w-auto wow fadeInUp">
                      <li className="linkedin">
                        <Link to="#" target="_blank" rel="noreferrer noopener" >
                          <FaLinkedinIn className="icon-20" />
                        </Link>
                      </li>
                      <li className="instagram">
                        <Link to="#" target="_blank" rel="noreferrer noopener" >
                          <FaInstagram className="icon-20" />
                        </Link>
                      </li>
                      <li className="youtube">
                        <Link to="#" target="_blank" rel="noreferrer noopener" >
                          <FaYoutube className="icon-20" />
                        </Link>
                      </li>
                      <li className="pinterest">
                        <Link to="#" target="_blank" rel="noreferrer noopener" >
                          <FaPinterest className="icon-20" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div> */}
            </div>
          </Col>
        </Row >
      </Container >

      {/* Video popup */}
      {/* this link will open the video popup which will be hidden because the button from the grid is causing issue */}
      <a onClick={(e) => {
        e.preventDefault();
        setvideosToggler(!videos);
      }}
        className="d-none"
        id="lnk_trigger_video_popup_news"></a>

      <FsLightbox
        toggler={videos}
        sources={lightbox_video}
      />


      {/* Breadcrumb */}
      <Breadcrumb className="breadcrumb-panel mt-auto">
        <Breadcrumb.Item href={globalVariables.variables.strDomainUrl}>Home</Breadcrumb.Item>
        <Breadcrumb.Item active>News & Events</Breadcrumb.Item>
        {
          news !== null ?
            <Breadcrumb.Item href={globalVariables.variables.strDomainUrl + "news-events/" + news[0].news_type_pagename}>{news[0].news_type_name}</Breadcrumb.Item>
            : ''
        }
        <Breadcrumb.Item active>{news != null ? news[0]?.news_headline : null}</Breadcrumb.Item>
      </Breadcrumb>
    </>
  );
}