import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Image from 'react-image-webp';
import global from "../../global-variables";
import { Link, useNavigate } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top"
import axios from "axios";
import * as FaIcons6 from "react-icons/fa6";
import $ from 'jquery'
import WOW from 'wowjs';
import { scrollToTop } from './header';

export default function Footer() {

    const navigate = useNavigate;
    const [socialMediaList, setSocialMediaList] = useState([])


    const [setting, setSettingList] = useState([]);


    useEffect(() => {
        let _obj = new Object();
        _obj.setting_id = global.variables.strSetting_id;
        axios.post(global.variables.strApiUrl + "/settingApi/getCompanySettingList", _obj)
            .then((response_list) => {
                if (response_list === 'error') {
                    navigate('/error-page')
                }
                else {
                    // pass the result in the success function of the respective page
                    setSettingList(response_list.data[0]);
                }
            });
    }, [])

    useEffect(() => {
        let _obj = new Object();
        _obj.social_status = 1;
        setTimeout(() => {

            axios.post(global.variables.strApiUrl + "/socialApi/getSocialList", _obj)
                .then((response_list) => {
                    // pass the result in the success function of the respective page
                    setSocialMediaList(response_list.data[0]);
                });
        }, 300);

    }, [])

    useEffect(() => {

        // initiate the wow effect
        new WOW.WOW({
            live: false
        }).init();


    }, []);

    useEffect(() => {

        window.initializeFancybox();

    }, [socialMediaList, setting])

    const [peopleCategory, setPeopleCategoryList] = useState([]);

    const [newsTypeList, setNewsTypeList] = useState([]);

    useEffect(() => {
        let _obj = new Object();
        _obj.pcategory_status = 1;
        // setTimeout(() => {

        axios.post(global.variables.strApiUrl + "/peoplecategoryApi/getcategoryHavingPeopleList", _obj)
            .then((response_list) => {
                // pass the result in the success function of the respective page
                setPeopleCategoryList(response_list.data[0]);
            });
        // }, 300);

        // axios.post(global.variables.strApiUrl + "/newstypeApi/getcategoryHavingNewsList")
        // .then((response_list) => {
        //     // pass the result in the success function of the respective page
        //     setNewsTypeList(response_list.data[0]);
        // });

    }, [])

    const SocialMediaIcons = ({ socialLinks }) => {
        return (
          <div>
            {socialLinks.map((val, index) => {
              const IconComponent = FaIcons6[val.social_img_name]; // Get the icon dynamically
              return IconComponent ? (
                <li className={val.social_name.toLowerCase()} key={val.social_id}>
                    <Link key={index} to={val.social_url} target="_blank" rel="noreferrer noopener">
                        <IconComponent className="icon-20" />
                    </Link>
                </li>
              ) : null;
            })}
          </div>
        );
      };

      
    return (
        <footer className="mt-auto footer-common" id='contact'>
            <Container className="py-md-5 py-4">
                <Row>
                    {/* <Col xl={7} md={5} id="address" className="text-md-start text-center">
                        <h5 className="wow fadeInUp">IIT Bombay Research Hub for Green Energy and Sustainability</h5>
                        <div>
                            <ul className="list-inline fw-light flex-grow-1 m-0 me-3">
                                <li className="d-md-inline-block px-0 py-0 mb-1 bg-transparent border-0 wow fadeInUp">+91-94479 93898 / 98201 27442</li>
                                <li className="d-lg-inline-block d-none px-0 py-0 mb-1 bg-transparent border-0 mx-md-3 wow fadeInUp">|</li>
                                <li className="d-md-inline-block px-0 py-0 mb-1 bg-transparent border-0 wow fadeInUp"><a href="mailto:office.gesh@iitb.ac.in">office.gesh@iitb.ac.in</a></li>
                            </ul>
                            <ul className="social_media flex-grow-0 w-auto wow fadeInUp">
                                <li className="linkedin">
                                    <a rel="noreferrer noopener" href="https://www.linkedin.com/company/iit-bombay-research-hub-for-green-energy-and-sustainability/" target="_blank">
                                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" className="icon-20" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path></svg>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </Col> */}
                    <Col xl={7} md={5} id="address" className="text-md-start text-center">
                        {
                            setting?.map((val) => {
                                return (<>
                                <Image className="img-fluid wow fadeInUp mb-4" loading="lazy" alt="IIT Bombay Research Hub for Green Energy and Sustainability" title="IIT Bombay Research Hub for Green Energy and Sustainability" width={300} height={80}
                                            src={(global.variables.strDomainUrl + "images/logo-footer.png")}
                                            webp={(global.variables.strDomainUrl + "images/logo-footer.webp")}
                                        />
                                    <h5 className="wow fadeInUp d-none">{val.setting_company}</h5>
                                    <p className="wow fadeInUp">{val.setting_address}<br />
                                        {val.setting_city} {val.setting_pin}
                                        {val.setting_state != null && val.setting_state != "" ? <>{" " + val.setting_state}</> : null}{
                                            val.setting_country != null && val.setting_country != "" ? <>{" " + val.setting_country}</> : null}.</p>

                                    <ul className="list-inline flex-grow-1 m-0 me-3">
                                        {
                                            val.setting_phone != null && val.setting_phone != "" ?
                                                <li className="d-md-inline-block px-0 py-0 mb-1 bg-transparent border-0 wow fadeInUp">
                                                    {val.setting_phone}
                                                </li>
                                                :
                                                null
                                        }
                                        {
                                            val.setting_mobile != null && val.setting_mobile != "" ?
                                                <li className="d-lg-inline-block px-0 py-0 mb-1 bg-transparent border-0 me-lg-4 wow fadeInUp">
                                                    {val.setting_mobile}
                                                </li>
                                                :
                                                null
                                        }

                                        <li className="d-lg-inline-block d-none px-0 py-0 mb-1 bg-transparent border-0 mx-md-2 wow fadeInUp">|</li>
                                        <li className="d-md-inline-block px-0 py-0 mb-1 bg-transparent border-0 wow fadeInUp">
                                            <a href={`mailto:${val.setting_contact_email}`}>{val.setting_contact_email}</a>
                                        </li>
                                        {/* <li className='wow fadeInUp'><Link to={""} className="map-frame">View Location on Map</Link></li> */}
                                    </ul>
                                    <ul className="social_media flex-grow-0 w-auto wow fadeInUp">
                                        <SocialMediaIcons socialLinks={socialMediaList} />
                                    </ul>
                                </>)
                            })
                        }
                    </Col>
                    <Col xl={5} md={7} className="pe-xl-2 px-lg-0">
                        <div className='d-flex justify-content-md-between justify-content-center'>
                            <div className='d-md-block d-none'>
                                <ul className="list-inline mt-lg-0">
                                    <li className="list-item p-1 wow fadeInUp">
                                        <Link to="/aboutus" onClick={scrollToTop}>About Us</Link>
                                    </li>
                                    <li className="list-item p-1 wow fadeInUp">
                                        <Link to={`/people/${peopleCategory[0]?.pcategory_pagename}`} onClick={scrollToTop}>People</Link>
                                    </li>
                                    <li className="list-item p-1 wow fadeInUp">
                                        <Link to="/focus-areas" onClick={scrollToTop}>Focus Areas</Link>
                                    </li>
                                    <li className="list-item p-1 wow fadeInUp">
                                        <Link to="/services" onClick={scrollToTop}>Services</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className='d-md-block d-none'>
                                <ul className="list-inline m-0">
                                    <li className="list-item p-1 wow fadeInUp">
                                        <Link to="/research" onClick={scrollToTop}>Research</Link>
                                    </li>
                                    <li className="list-item p-1 wow fadeInUp">
                                        <Link to="/campus-sustainability" onClick={scrollToTop}>Campus Sustainability</Link>
                                    </li>
                                    <li className="list-item p-1 wow fadeInUp">
                                        <Link to="/ourpartners" onClick={scrollToTop}>Partnerships</Link>
                                    </li>
                                    <li className="list-item p-1 wow fadeInUp">
                                        <Link to={'/news-events'} onClick={scrollToTop}>News & Events</Link>
                                    </li>
                                    {/* <li className="list-item p-1 wow fadeInUp">
                                        <Link to={'/events'} onClick={scrollToTop}>Events</Link>
                                    </li> */}
                                </ul>
                            </div>
                            <div>
                                <ul className="list-inline m-0 d-flex flex-md-column justify-content-between">
                                    <li className="list-item p-1 wow fadeInUp">
                                        <Link to="/" onClick={scrollToTop}>Home</Link>
                                    </li>
                                    <li className="list-item p-1 d-md-none d-block wow fadeInUp">
                                        <Link to="/aboutus" onClick={scrollToTop}>About Us</Link>
                                    </li>
                                    <li className="list-item p-1 d-md-block d-none wow fadeInUp">
                                        <Link to="/careers" onClick={scrollToTop}>Careers</Link>
                                    </li> 
                                    <li className="list-item p-1 wow fadeInUp">
                                        <Link to="/sitemap">Sitemap</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>


                    </Col>
                </Row>
                <hr />
                <Row className='align-items-center'>
                    <Col xl={12}>
                        <div className="text-center wow fadeInUp">
                            © {new Date().getFullYear()}. IIT Bombay Research Hub for Green Energy and Sustainability.<br className="d-md-none d-block" /> All rights reserved.
                            Site Created by <Link to="https://www.designscape.co.in/" rel="noreferrer noopener" target="_blank">Designscape</Link>
                        </div>
                    </Col>
                </Row>
            </Container>


            <ScrollToTop smooth className='animated fadeInRight'
                viewBox="0 0 24 24"
                svgPath="m12 6.879-7.061 7.06 2.122 2.122L12 11.121l4.939 4.94 2.122-2.122z"
            />
        </footer>
    )
} 