import React, { useState, useEffect } from "react";
import global from "../../global-variables";
import Header from "../layout/header";
import { Link, useNavigate } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import $ from "jquery";
import WOW from "wowjs";
import { Container, Row, Col } from "reactstrap";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Form from 'react-bootstrap/Form';
import globalVariables from "../../global-variables";
import Metatag from "../../hooks/Metatag";
import Accordion from 'react-bootstrap/Accordion';
import Pagination from 'react-bootstrap/Pagination';
import Button from "react-bootstrap/Pagination";
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';


export default function GESHProject1() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();
  const [getrcategoryList, setRcategoryList] = useState(null);

  const [expanded, setExpanded] = useState(false);


  useEffect(() => {
    // remove bg-header-home 
    $(window).on("scroll", function () {
      $(".navbar").offset().top > 10 ? $(".fixed-top").removeClass("bg-header-home") : $(".fixed-top").removeClass("bg-header-home")
    });

    // initiate the wow effect
    new WOW.WOW({
      live: false,
    }).init();
  }, [getrcategoryList])

  return (
    <>
      <Header></Header>
      {/* MetaTag */}

      <Metatag title='Sustainability Research Projects by IIT Bombay'
        description='Sustainability Research Projects by IIT Bombay'
        keywords="Sustainability Research Projects by IIT Bombay"
      />

      {/* Visual Panel */}
      <Container fluid className="bg-visual-inside p-0" >
        <div className="visual-inside-big visual-inside-big1 visual-container-inside">
          <Container>
            <Row className="g-md-0 rounded-4">
              <Col md={6}>
                <div className="d-flex align-items-end h-100 pb-md-5">
                  <div className="d-inline-block m-0 align-self-end pb-xl-3 px-2 text-white d-md-flex align-items-end wow fadeInUp">
                    <h1 className="fw-light text-white m-0 align-self-center me-2">
                      GESH Projects
                    </h1>
                    <div className="mb-1">Showing 1-5 of 15 projects</div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>

      {/* Content Panel */}
      <Container className="my-5 position-relative">
        <Row className="g-4 gx-xl-5">
          <Col expanded={expanded} md={4} lg={3} className="z-1" collapseonselect expand="xl">
            <div className="bg-white h-100">
              <div className="border-bottom border-4 border-primary rounded-4 h-100">
                <div className="bg-primary bg-opacity-10 rounded-4 py-md-4 py-3 px-4 border h-100 filter">
                  <Navbar.Toggle onClick={handleShow} className="offcanvas-toggle shadow-none px-1 d-flex fw-medium w-100 d-md-none d-block" aria-controls={'offcanvas-filtermenu'}>
                    <div className="me-3">
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                    </div>
                    Filter Projects by
                  </Navbar.Toggle>

                  <Offcanvas show={show} onHide={handleClose} id={'offcanvas-filtermenu'} aria-labelledby={'offcanvas-filtermenu'} placement="start" responsive="md" className="px-md-0 px-4 mt-auto">
                    <Offcanvas.Header closeButton className="px-0">
                      <Offcanvas.Title>
                      </Offcanvas.Title>
                    </Offcanvas.Header>
                    <div className="form-group mb-0">
                      <div className="input-group">
                        <input name="txtBlogsearch" type="text" id="txtSearch" className="form-control form-control-sm py-2 ps-3 pe-4 rounded-pill" placeholder="Search" />
                        <div className="search-btn">
                          <Link id='btnclickfocus'><FaSearch /></Link>
                        </div>
                      </div>
                      <span id="searcherr" class="text-danger small mx-2"></span>
                    </div>

                    <h6 className="text-primary">Filter Projects by</h6>
                    <Accordion defaultActiveKey={['0', '1']} alwaysOpen>
                      <Accordion.Item eventKey="0" className="filterheader">
                        <Accordion.Header className="p-0 border-bottom">Department</Accordion.Header>
                        <Accordion.Body className="border-0 px-md-0 p-1 pt-2">
                          <Form>
                            {['checkbox'].map((type) => (
                              <div key={'default'} className="mb-3">
                                <Form.Check type={type} id={'Physics'} label={'Physics'} className="mb-1" />
                                <Form.Check type={type} id={'Chemical Engineering'} label={'Chemical Engineering'} className="mb-1" />
                                <Form.Check type={type} id={'IDPCS'} label={'IDPCS'} className="mb-1" />
                                <Form.Check type={type} id={'Environment Science and Engineering'} label={'Environment Science and Engineering'} className="mb-1" />
                                <Form.Check type={type} id={'CUSE'} label={'CUSE'} className="mb-1" />
                              </div>
                            ))}
                          </Form>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1" className="filterheader">
                        <Accordion.Header className="p-0 border-bottom">Focus Areas</Accordion.Header>
                        <Accordion.Body className="border-0 p-0 pt-2">
                          <Form>
                            {['checkbox'].map((type) => (
                              <div key={'default'} className="mb-3">
                                <Form.Check type={type} id={'Solar Thermal & PV'} label={'Solar Thermal & PV'} className="mb-1" />
                                <Form.Check type={type} id={'Batteries & Fuel cells'} label={'Batteries & Fuel cells'} className="mb-1" />
                                <Form.Check type={type} id={'Biofuels'} label={'Biofuels'} className="mb-1" />
                                <Form.Check type={type} id={'Wind Energy'} label={'Wind Energy'} className="mb-1" />
                                <Form.Check type={type} id={'Green Hydrogen'} label={'Green Hydrogen'} className="mb-1" />
                                <Form.Check type={type} id={'Circular Economy'} label={'Circular Economy'} className="mb-1" />
                                <Form.Check type={type} id={'Climate Change'} label={'Climate Change'} className="mb-1" />
                                <Form.Check type={type} id={'Environmental Studies'} label={'Environmental Studies'} className="mb-1" />
                                <Form.Check type={type} id={'Food & Water'} label={'Food & Water'} className="mb-1" />
                                <Form.Check type={type} id={'Decarbonisation'} label={'Decarbonisation'} className="mb-1" />
                              </div>
                            ))}
                          </Form>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <div className="mx-auto d-md-none d-block mt-auto">
                      <Button className="btn btn-primary rounded-pill px-4">Submit</Button>
                    </div>
                  </Offcanvas>
                </div>
              </div>
            </div>
          </Col>
          <Col md={8} lg={9} className="projectslist">
            <div className="border rounded-4 p-lg-5 p-4 h-100">
              <div className="d-flex flex-column h-100">
                {/* <div className="d-flex align-items-end mb-4 wow fadeInUp">
                  <h2 className="m-0 me-2 text-primary">Research Projects</h2> <small>Showing 1-5 of 15</small>
                </div> */}
                <Row className="position-relative g-3 overflow-hidden">
                  <Col md={6} lg={3} className="pe-lg-4">
                    <Link to="/research/gesh-project-details1">
                      <img src={global.variables.strDomainUrl + "images/pic-event.jpg"} alt="IITB" title="IITB" className="img-fluid rounded-2 bg-white wow fadeInUp" />
                    </Link>
                  </Col>
                  <Col md={12} lg={9}>
                    <Link to="/research/gesh-project-details1" className="text-decoration-none">
                      <h6 className="lh-base text-black mb-2 wow fadeInUp">Towards a unified physics-based theory for opposing trends of seasonal and extreme monsoon rainfall for their reliable projections</h6>
                    </Link>

                    <ul className="list-group wow fadeInUp">
                      <li className="list-group-item border-0 px-0 py-0"><span className="text-black fw-medium">Faculty:</span> <Link to="#">Dinesh Kabra</Link>, <Link to="#">Karthikeyan Lanka</Link></li>
                      <li className="list-group-item border-0 px-0 py-1"><span className="text-black fw-medium">Focus Areas:</span> Solar Thermal & PV, Biofuels, Green Hydrogen</li>
                    </ul>
                  </Col>
                </Row>
                <hr className="my-4" />
                <Row className="position-relative g-3 overflow-hidden">
                  <Col md={6} lg={3} className="pe-lg-4">
                    <img src={global.variables.strDomainUrl + "images/pic-event.jpg"} alt="IITB" title="IITB" className="img-fluid rounded-2 bg-white wow fadeInUp" />
                  </Col>
                  <Col md={12} lg={9}>
                    <h6 className="lh-base mb-2 wow fadeInUp">Diverse Sectors and Multiple Approaches Process / Technology Development</h6>

                    <ul className="list-group wow fadeInUp">
                      <li className="list-group-item border-0 px-0 py-0"><span className="text-black fw-medium">Faculty:</span> <Link to="#">Anjali Sharma</Link>, <Link to="#">Yogendra Shastri</Link></li>
                      <li className="list-group-item border-0 px-0 py-1"><span className="text-black fw-medium">Focus Areas:</span> Batteries & Fuel cells, Wind Energy</li>
                    </ul>
                  </Col>
                </Row>
                <hr className="my-4" />
                <Row className="position-relative g-3 overflow-hidden">
                  <Col md={6} lg={3} className="pe-lg-4">
                    <img src={global.variables.strDomainUrl + "images/pic-event.jpg"} alt="IITB" title="IITB" className="img-fluid rounded-2 bg-white wow fadeInUp" />
                  </Col>
                  <Col md={12} lg={9}>
                    <h6 className="lh-base mb-2 wow fadeInUp">Solar Photovoltaics</h6>

                    <ul className="list-group wow fadeInUp">
                      <li className="list-group-item border-0 px-0 py-0"><span className="text-black fw-medium">Faculty:</span> <Link to="#">Guruswamy</Link>, <Link to="#">Dinesh Kabra</Link>, <Link to="#">Karthikeyan Lanka</Link></li>
                      <li className="list-group-item border-0 px-0 py-1"><span className="text-black fw-medium">Focus Areas:</span> Solar Thermal & PV, Green Hydrogen, Food & Water</li>
                    </ul>
                  </Col>
                </Row>


                <Pagination className="mt-auto justify-content-center pt-4">
                  <Pagination.First />
                  <Pagination.Item active>{1}</Pagination.Item>
                  <Pagination.Item>{2}</Pagination.Item>
                  <Pagination.Item>{3}</Pagination.Item>
                  <Pagination.Item>{4}</Pagination.Item>
                  <Pagination.Item>{5}</Pagination.Item>
                  <Pagination.Last />
                </Pagination>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      {/* Breadcrumb */}
      <Breadcrumb className="breadcrumb-panel mt-auto">
        <Breadcrumb.Item href={globalVariables.variables.strDomainUrl}>Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Research</Breadcrumb.Item>
        <Breadcrumb.Item active>Overview</Breadcrumb.Item>
      </Breadcrumb>
    </>
  );
}