import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import global from "../../global-variables";
import Header from "../layout/header";
import { Link } from "react-router-dom";
import $ from "jquery";
import WOW from "wowjs";
import { FaRegClock, FaRegCalendarAlt,FaLink,FaRegFilePdf, FaVideo, FaAngleLeft } from "react-icons/fa";
import { FiMapPin } from "react-icons/fi";
import { BsPencilSquare } from "react-icons/bs";
import Metatag from '../../hooks/Metatag'
import { Container, Row, Col } from "reactstrap";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import FsLightbox from "fslightbox-react";
import { GetEventGalleryList } from "../../api/event-gallery-function";
import globalVariables from "../../global-variables";
import { Button } from "react-bootstrap";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Accordion from 'react-bootstrap/Accordion';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { GetEventList } from "../../api/event-function";
import axios from "axios";
import * as FaIcons6 from "react-icons/fa6";

export default function EventDetails() {

  //constant for event schedule tab
  const [key, setKey] = useState(null);
  
  // constant to be used on the page
  const [event, setEventList] = useState([]);
  const [eventId, setEventId] = useState(null);
  
  const [eventSpeakerList,setEventSpeakerList] = useState([]);
  const [eventOrganiserList,setEventOrganiserList] = useState([]);
  const [eventDaysList,setEventDaysList] = useState([]);
  const [eventScheduleTimingList,setEventScheduleTimingList] = useState([]);
  const [eventSponsorList,setEventSponsorList] = useState([]);
  const [eventSocialList,setEventSocialList] = useState([]);
  const [eventGalleryList,setEventGalleryList] = useState([]);
  const [getSponsorCategory , setSponsorCategory] = useState([]);
  
  const [event_gallery_toggler, setEventGalleryToggler] = useState(false);
  const [event_gallery, setEventGallery] = useState([]);
  
  const [lightbox_video, setLightBoxVideo] = useState([]);
  const [videos, setvideosToggler] = useState(false);

  const [gallery_key, setGalleryKey] = useState("");
  const [gallerySlide, setGallerySlide] = useState("");

  const navigate = useNavigate();

  //Getting id from url
  let { event_pagename } = useParams();
  const qs_event_pagename = event_pagename;

  // success function when event gallery is retrived
  const OnGetEventGallerySuccess = (p_response) => {
    // set the array in the constant
    setEventGallery(p_response);

    setGalleryKey(Date.now());
    // using time out for the value to get saved in state 
    setTimeout(() => {
      // trigger the link click to display the event gallery popup
      document.getElementById("lnk_trigger_event_gallery_popup_home").click();
    }, 200);

  }

  // function to invoke photo gallery
  const InvokePhotoGallery = (e, p_event_id , index) => {
    
    e.preventDefault();
    setGallerySlide(index)
    // Make an object for getting event gallery
    let _objEventGallery = new Object();
    _objEventGallery.egallery_status = 1;
    _objEventGallery.egallery_event_id = p_event_id;

    // call the function to get the event gallery
    GetEventGalleryList(_objEventGallery, OnGetEventGallerySuccess);

  };

  // function to invoke video popup
  const InvokeVideoPopup = (e, p_event_video_url) => {

    e.preventDefault();

    let _arrVideos = [];
    _arrVideos.push(p_event_video_url);

    // set the path to the constant for displaying
    setLightBoxVideo(_arrVideos);

    // trigger the link click to display the popup
    document.getElementById("lnk_trigger_video_popup_home").click();

  };

  useEffect(() => {

    // initiate the wow effect
    new WOW.WOW({
      live: false,
    }).init();


    var header = $(".rightpanel");
    $(window).scroll(function () {
      var scroll = $(window).scrollTop();
      if (scroll >= 250) {
        header.addClass('sticky');
      } else {
        header.removeClass('sticky');
      }
    });

  }, [event,eventSpeakerList,eventDaysList,eventOrganiserList,eventGalleryList,eventSponsorList]);
  
  // things to be done when the page is loaded
  useEffect(() => {

    // make the object for getting the event list
    let _objEvent = new Object();
    _objEvent.event_pagename = qs_event_pagename;
    _objEvent.event_status = 1;

      axios.post(global.variables.strApiUrl + "/eventApi/geteventList", _objEvent)
      .then((response_list) => {
          if(response_list.data === 'error')
          {
              navigate('/error-page')
          }
          else
          {
            setEventList(response_list.data[0]);
            setEventId(response_list.data[0][0]?.event_id)
          }
      });

  }, [qs_event_pagename]);

  
  useEffect(()=>{
    
    // getting speakers list
    let _objSpeakers = new Object();
    _objSpeakers.so_te_status = 1;
    _objSpeakers.so_te_event_id = eventId;
    _objSpeakers.so_te_type = 'Speaker';

      axios.post(global.variables.strApiUrl + "/speakerOrgTeamApi/getSpeakerOrgTeamList", _objSpeakers)
      .then((response_list) => {
          if(response_list.data === 'error')
          {
              navigate('/error-page')
          }
          else
          {
            setEventSpeakerList(response_list.data[0]);
          }
      });

      // getting organising team list
      let _objOrganiser = new Object();
      _objOrganiser.so_te_status = 1;
      _objOrganiser.so_te_event_id = eventId;
      _objOrganiser.so_te_type = 'Organising Team';
  
        axios.post(global.variables.strApiUrl + "/speakerOrgTeamApi/getSpeakerOrgTeamList", _objOrganiser)
        .then((response_list) => {
            if(response_list.data === 'error')
            {
                navigate('/error-page')
            }
            else
            {
              setEventOrganiserList(response_list.data[0]);
            }
        });

      // getting event days list
      let _objEventDays = new Object();
      _objEventDays.ev_day_event_id = eventId;
  
        axios.post(global.variables.strApiUrl + "/eventDaysApi/getEventDaysHavingEventScheduleList", _objEventDays)
        .then((response_list) => {
            if(response_list.data === 'error')
            {
                navigate('/error-page')
            }
            else
            {
              setEventDaysList(response_list.data[0]);
              setKey(response_list.data[0][0]?.ev_day_id)
            }
        });

      // getting event sponsor list
      let _objSponsor = new Object();
      _objSponsor.sponsor_status = 1;
      _objSponsor.sponsor_event_id = eventId;
  
        axios.post(global.variables.strApiUrl + "/sponsorApi/getSponsorList", _objSponsor)
        .then((response_list) => {
            if(response_list.data === 'error')
            {
                navigate('/error-page')
            }
            else
            {
              setEventSponsorList(response_list.data[0]);
            }
        });

      //getting event social list
      let _objEventSocial = new Object();
      _objEventSocial.evt_soc_status = 1;
      _objEventSocial.evt_soc_event_id = eventId;
  
        axios.post(global.variables.strApiUrl + "/eventSocialApi/getEventSocialList", _objEventSocial)
        .then((response_list) => {
            if(response_list.data === 'error')
            {
                navigate('/error-page')
            }
            else
            {
              setEventSocialList(response_list.data[0]);
            }
        });

        //getting event gallery list
      let _objEventGallery = new Object();
      _objEventGallery.egallery_status = 1;
      _objEventGallery.egallery_event_id = eventId;

         //Get event gallery List
          axios.post(globalVariables.variables.strApiUrl + '/eventgalleryApi/geteventgalleryList', _objEventGallery)
          .then((response_list) => {
                if(response_list.data === 'error')
            {
              navigate('/error-page')
            }
            else
            {
                // pass the result in the success function of the respective page
                setEventGalleryList(response_list.data[0])
            }
          });
        

        //getting sponsor category list
      let _objSponsorCategory = new Object();
      _objSponsorCategory.sponsor_event_id = eventId;

         //Get event gallery List
          axios.post(globalVariables.variables.strApiUrl + '/sponsorCategoryApi/getsponsorcategoryHavingSponsorList', _objSponsorCategory)
          .then((response_list) => {
                if(response_list.data === 'error')
            {
              navigate('/error-page')
            }
            else
            {
                // pass the result in the success function of the respective page
                setSponsorCategory(response_list.data[0])
            }
          });

  },[eventId])
  
   // manage visibility of speaker cards
   const [showAllSpeakers, setShowAllSpeakers] = useState(false);

   // manage visibility of organising team cards
   const [showAllOrganiser, setShowAllOrganiser] = useState(false);

   // show fist four data using slice
   const visibleSpeakers = eventSpeakerList.slice(0, 4);
   const visibleOrganiser = eventOrganiserList.slice(0, 4);
   
   useEffect(()=>{

     // getting schedule timing list
     let _obj = new Object();
     _obj.evt_sched_ev_day_id = key;
     _obj.evt_sched_status = 1;
 
       axios.post(global.variables.strApiUrl + "/eventScheduleApi/getEventScheduleList", _obj)
       .then((response_list) => {
        
           if(response_list.data === 'error')
           {
               navigate('/error-page')
           }
           else
           {
            setEventScheduleTimingList(response_list.data[0]);
           }
       });
   },[key])

   const SocialMediaIcons = ({ eventSocialList }) => {
    return (
      <div>
        {eventSocialList.map((val, index) => {
          const IconComponent = FaIcons6[val.evt_soc_icon]; // Get the icon dynamically
          return IconComponent ? (
            <li className={val.evt_soc_name.toLowerCase()} key={val.evt_soc_id}>
                <Link key={index} to={val.evt_soc_url} target="_blank" rel="noreferrer noopener">
                    <IconComponent className="icon-20" />
                </Link>
            </li>
          ) : null;
        })}
      </div>
    );
  };

  return (
    <>
      <Header></Header>

      {/* MetaTag */}
      <Metatag title={event != null ? event[0]?.event_pagetitle : null}
        description={event != null ? event[0]?.event_meta_description : null}
        keywords={event != null ? event[0]?.event_meta_keywords : null}
      />

      {/* Event Visual Panel */}
      <Container fluid className="bg-visual-inside-eve-details p-0" >
        <div className="visual-inside-big">
          <Container>
            <Row className="g-md-0 rounded-4 justify-content-center align-items-center mt-md-5">
              <Col xs={8} md={6}>
                <h1 className="fw-light text-white d-inline-block m-0 align-self-center wow fadeInUp">
                  Events
                </h1>
              </Col>
              <Col xs={4} md={6}>
                <Button onClick={() => navigate(-1)} className="btn btn-primary rounded-pill Faicon px-3 py-1 ms-auto d-flex align-items-center wow fadeInUp">
                  <FaAngleLeft /> Back
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>

      {/* Events Container */}
      {
          event.length > 0 ?
          <>
            <Container className="event-info">
              <div className="rounded-4 bg-light shadow-sm align-items-center position-relative overflow-hidden">
                <Row className="p-md-4 p-3 align-items-center">
                  <Col md={4} lg={6} className="ps-md-4 px-3 pe-md-5">
                    {
                      event[0].event_banner != null && event[0].event_banner != "" ?
                        <div className="position-relative">
                          <div className="event-category">{event[0].etype_name}</div>
                          <img src={global.variables.strApiUrl + "/event-banner/" + event[0].event_banner} alt={event[0].event_name} title={event[0].event_name} className="img-fluid w-100 rounded-3 mb-md-0 mb-3 border" />
                        </div>
                        :
                        //default img
                        <div className="position-relative">
                          <div className="event-category">{event[0].etype_name}</div>
                          <img src={global.variables.strDomainUrl + "images/event_default_banner.png"} alt={event[0].event_name} title={event[0].event_name} className="img-fluid w-100 rounded-3 mb-md-0 mb-3 border" />
                        </div>
                    }
                  </Col>
                  <Col md={8} lg={6} className="d-flex align-items-start flex-column px-4">
                    <h5 className="mb-3 text-dark wow fadeInUp">
                      {event[0].event_name}
                    </h5>
                    <div className="mb-2 d-flex gap-2 wow fadeInUp">
                      {/*when from and to date are diffrent  */}
                      <FaRegCalendarAlt className="icon-18" />
                      <div>
                        {
                          event[0].event_from_date != event[0].event_to_date ?
                            <>

                              {
                                event[0].from_mon != event[0].to_mon ?
                                  <>
                                    {` ${event[0].from_day}-${event[0].from_mon}-${event[0].from_year} to ${event[0].to_day}-${event[0].to_mon}-${event[0].to_year}`}

                                  </>
                                  :
                                  <>
                                    {` ${event[0].from_day}-${event[0].from_mon}-${event[0].from_year} to ${event[0].to_day}-${event[0].to_mon}-${event[0].to_year}`}
                                  </>
                              }
                            </>

                            :
                            // when from and to date same 
                            <>
                              {
                                //when from and to date's month is same
                                event[0].from_mon != event[0].to_mon ?
                                  <>
                                    {` ${event[0].from_day}-${event[0].from_mon}-${event[0].from_year} to ${event[0].to_day}-${event[0].to_mon}-${event[0].to_year}`}
                                  </>
                                  :
                                  <>
                                    {` ${event[0].from_day}-${event[0].from_mon}-${event[0].from_year}`}
                                  </>
                              }
                            </>

                        }
                      </div>

                    </div>
                    {
                      event[0].event_time !== null && event[0].event_time !== "" ?
                        <div className="mb-2 d-flex gap-2 wow fadeInUp">
                          <FaRegClock className="icon-18" />
                          <div>
                          {event[0].event_time}
                          </div>
                        </div>
                      :
                      null
                    }
                    {
                      event[0].event_venue !== null && event[0].event_venue !== "" ?
                        <div className="mb-2 d-flex gap-2 wow fadeInUp">
                          <FiMapPin className="icon-18" />
                          <div>
                          {event[0].event_venue}
                          </div>
                        </div>
                      :
                      null
                    }
                    <div className="d-md-flex mt-3 w-100">
                      <div className="d-flex gap-2">
                        {/* link */}
                        {
                          event[0].event_url !== null && event[0].event_url !== "" ?
                          <Link to={event[0].event_url} target="_blank" className="btn btn-primary rounded-pill px-3 py-1 wow fadeInUp"><FaLink className=" icon-16" /></Link>
                          :
                          null
                        }

                        {/* Brochure */}
                        {
                          event[0].event_brochure !== null && event[0].event_brochure !== "" ?
                          <Link to={global.variables.strApiUrl + "/event-brochure/" + event[0].event_brochure} className="btn btn-primary rounded-pill px-3 py-1 wow fadeInUp" target="_blank" ><FaRegFilePdf className="fw-bold icon-16" /></Link>
                          :
                          null
                        }
                        {/* {/ Video /} */}
                        {
                        event[0].event_video_link != null && event[0].event_video_link != "" ?
                          <Link className="btn btn-primary rounded-pill px-3 py-1 wow fadeInUp" onClick={e => InvokeVideoPopup(e, event[0].event_video_link)}>    <FaVideo className="fw-bold icon-16"/></Link>
                          :
                          null
                      }
                      </div>
                      {
                      event[0].event_reg_url != null && event[0].event_reg_url != "" ?
                        <div className="ms-auto mt-3 mt-md-0">
                          {/* registration url */}
                          <Link className="btn btn-primary rounded-pill px-3 py-1 wow fadeInUp" to={event[0].event_reg_url} target="_blank" ><BsPencilSquare className="fw-bold icon-16" /> Register Now</Link>
                        </div>
                      :
                      null
                      }
                    </div>

                    <div className="d-block d-md-none mt-4">
                      {
                        event[0].event_fees != null && event[0].event_fees != "" ?
                        <p><b>Fees:</b> INR {event[0].event_fees}</p>
                        :
                        null
                      }
                      {
                        event[0].event_contact != null && event[0].event_contact != "" ?
                        <>
                          <p className="mb-1 fw-bold">Contact Details</p>
                          <p>
                            <span dangerouslySetInnerHTML={{ __html : event[0].event_contact }}></span>
                          </p>
                        </>
                      :
                      null

                      }
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
            <Container>
              <Row className="g-0">
                <Col md={10} lg={10}>
                  <div className="border rounded-4 my-5">
                    {/* Event Details */}
                    <div className="my-md-5 my-4 px-lg-5 px-4 position-relative overflow-hidden">
                      <div className="pe-md-2 col-md-11">
                        {
                          event[0]?.event_desc !== null && event[0]?.event_desc !== "" ?
                            <div id="AboutEvent">
                              <h4 className="mb-3 wow fadeInUp">About Event</h4>
                              <p className="wow fadeInUp ck-content"><span dangerouslySetInnerHTML={{ __html : event[0]?.event_desc }} /></p>
                            </div>
                          :
                          null
                        }
                      </div>
                    </div>

                    {/* Event Speakers */}
                    {
                      eventSpeakerList.length > 0 ?
                        <div className="my-md-5 my-4 px-lg-5 px-4 bg-light py-md-5 py-4 position-relative overflow-hidden">
                          <div className="pe-md-2 col-md-11">
                            <div id="EventSpeakers">
                              <h4 className="mb-3 wow fadeInUp">Event Speakers</h4>
                              <Row className="row-cols-1 row-cols-md-2 row-cols-lg-4 g-4">
                                  {visibleSpeakers.map((val, index) => {
                                    
                                    return(
                                      val.so_te_event_id === event[0]?.event_id ?
                                      <Col key={index}>
                                        <div className="h-100 border p-3 rounded-3 text-center">
                                        {
                                          val.so_te_url !== null && val.so_te_url !== "" ?
                                          <Link to={val.so_te_url} target="_blank" className="text-decoration-none">
                                            {
                                              val.so_te_pic !== null && val.so_te_pic !== "" ?
                                              <div className="col-12">
                                                <img
                                                  src={`${global.variables.strApiUrl}/speaker-org-team-picture/${val.so_te_pic}`}
                                                  alt={val.so_te_name}
                                                  title={val.so_te_name}
                                                  className="img-fluid rounded-circle mb-3 wow fadeInUp"
                                                />
                                              </div>
                                              :
                                              <div className="col-12">
                                                <img
                                                  src={`${global.variables.strDomainUrl}images/people/people-default.jpg`}
                                                  alt={val.so_te_name}
                                                  title={val.so_te_name}
                                                  className="img-fluid rounded-circle mb-3 wow fadeInUp"
                                                />
                                              </div>
                                            }
                                            <div className="d-flex flex-column">
                                              <h6 className="text-dark mb-1 wow fadeInUp">{val.so_te_name}</h6>
                                              {val.so_te_designation && <p className="m-0 wow fadeInUp">{val.so_te_designation}</p>}
                                              {val.so_te_company && <p className="m-0 wow fadeInUp">{val.so_te_company}</p>}
                                            </div>
                                          </Link>
                                          :
                                          <div className="text-decoration-none">
                                            {
                                              val.so_te_pic !== null && val.so_te_pic !== "" ?
                                              <div className="col-12">
                                                <img
                                                  src={`${global.variables.strApiUrl}/speaker-org-team-picture/${val.so_te_pic}`}
                                                  alt={val.so_te_name}
                                                  title={val.so_te_name}
                                                  className="img-fluid rounded-circle mb-3 wow fadeInUp"
                                                />
                                              </div>
                                              :
                                              <div className="col-12">
                                                <img
                                                  src={`${global.variables.strDomainUrl}images/people/people-default.jpg`}
                                                  alt={val.so_te_name}
                                                  title={val.so_te_name}
                                                  className="img-fluid rounded-circle mb-3 wow fadeInUp"
                                                />
                                              </div>
                                            }
                                            <div className="d-flex flex-column">
                                              <h6 className="text-dark mb-1 wow fadeInUp">{val.so_te_name}</h6>
                                              {val.so_te_designation && <p className="m-0 wow fadeInUp">{val.so_te_designation}</p>}
                                              {val.so_te_company && <p className="m-0 wow fadeInUp">{val.so_te_company}</p>}
                                            </div>
                                          </div>
                                        }
                                        </div>
                                      </Col>
                                      :
                                      null
                                    )
                                  })}
                                </Row>

                                {eventSpeakerList.length > 4 && (
                                  <Accordion defaultActiveKey="0" className="">
                                    <Accordion.Item eventKey="1">
                                      <Accordion.Body className="px-0 pt-4 pb-0">
                                        <Row className="row-cols-1 row-cols-md-2 row-cols-lg-4 g-4">
                                          {eventSpeakerList.slice(4).map((val, index) =>{
                                            
                                              return(
                                                val.so_te_event_id === event[0]?.event_id ?
                                              <Col key={index + 4}>
                                                <div className="h-100 border p-3 rounded-3 text-center">
                                                  {
                                                    val.so_te_url !== null && val.so_te_url !== "" ?
                                                    <Link to={val.so_te_url} target="_blank" className="text-decoration-none">
                                                      {
                                                        val.so_te_pic !== null && val.so_te_pic !== "" ?
                                                        <div className="col-12">
                                                          <img
                                                            src={`${global.variables.strApiUrl}/speaker-org-team-picture/${val.so_te_pic}`}
                                                            alt={val.so_te_name}
                                                            title={val.so_te_name}
                                                            className="img-fluid rounded-circle mb-3 wow fadeInUp"
                                                          />
                                                        </div>
                                                        :
                                                        <div className="col-12">
                                                          <img
                                                            src={`${global.variables.strDomainUrl}images/people/people-default.jpg`}
                                                            alt={val.so_te_name}
                                                            title={val.so_te_name}
                                                            className="img-fluid rounded-circle mb-3 wow fadeInUp"
                                                          />
                                                        </div>
                                                      }
                                                      <div className="d-flex flex-column">
                                                        <h6 className="text-dark mb-1 wow fadeInUp">{val.so_te_name}</h6>
                                                        {val.so_te_designation && <p className="m-0 wow fadeInUp">{val.so_te_designation}</p>}
                                                        {val.so_te_company && <p className="m-0 wow fadeInUp">{val.so_te_company}</p>}
                                                      </div>
                                                    </Link>
                                                    :
                                                    <div className="text-decoration-none">
                                                      {
                                                        val.so_te_pic !== null && val.so_te_pic !== "" ?
                                                        <div className="col-12">
                                                          <img
                                                            src={`${global.variables.strApiUrl}/speaker-org-team-picture/${val.so_te_pic}`}
                                                            alt={val.so_te_name}
                                                            title={val.so_te_name}
                                                            className="img-fluid rounded-circle mb-3 wow fadeInUp"
                                                          />
                                                        </div>
                                                        :
                                                        <div className="col-12">
                                                          <img
                                                            src={`${global.variables.strDomainUrl}images/people/people-default.jpg`}
                                                            alt={val.so_te_name}
                                                            title={val.so_te_name}
                                                            className="img-fluid rounded-circle mb-3 wow fadeInUp"
                                                          />
                                                        </div>
                                                      }
                                                      <div className="d-flex flex-column">
                                                        <h6 className="text-dark mb-1 wow fadeInUp">{val.so_te_name}</h6>
                                                        {val.so_te_designation && <p className="m-0 wow fadeInUp">{val.so_te_designation}</p>}
                                                        {val.so_te_company && <p className="m-0 wow fadeInUp">{val.so_te_company}</p>}
                                                      </div>
                                                    </div>
                                                  }
                                                </div>
                                              </Col>
                                              :
                                              null
                                              )
                                            })}
                                        </Row>
                                      </Accordion.Body>
                                      <Accordion.Header className="mt-4">
                                        <button
                                          className={`btn btn-primary rounded-pill px-4 py-2 mx-auto`}
                                          onClick={() => setShowAllSpeakers(!showAllSpeakers)}
                                        >
                                          {showAllSpeakers ? 'View Less' : 'View More'}
                                        </button>
                                      </Accordion.Header>
                                    </Accordion.Item>
                                  </Accordion>
                                )}
                            </div>
                          </div>
                        </div>
                      :
                        null
                    }

                    {/* Event Organising Team */}
                    {
                      eventOrganiserList.length > 0 ?
                      <div className="my-md-5 my-4 px-lg-5 px-4 position-relative overflow-hidden">
                        <div className="pe-md-2 col-md-11">
                          <div id="EventSpeakers">
                            <h4 className="mb-3 wow fadeInUp">Organising Team</h4>
                            <Row className="row-cols-1 row-cols-md-2 row-cols-lg-4 g-4">
                              {visibleOrganiser.map((val, index) => (
                                val.so_te_event_id === event[0]?.event_id ?
                                <Col key={index}>
                                  <div className="h-100 border p-3 rounded-3 text-center">
                                  {
                                    val.so_te_url !== null && val.so_te_url !== "" ?
                                    <Link to={val.so_te_url} target="_blank" className="text-decoration-none">
                                            {
                                              val.so_te_pic !== null && val.so_te_pic !== "" ?
                                              <div className="col-12">
                                                <img
                                                  src={`${global.variables.strApiUrl}/speaker-org-team-picture/${val.so_te_pic}`}
                                                  alt={val.so_te_name}
                                                  title={val.so_te_name}
                                                  className="img-fluid rounded-circle mb-3 wow fadeInUp"
                                                />
                                              </div>
                                              :
                                              <div className="col-12">
                                                <img
                                                  src={`${global.variables.strDomainUrl}images/people/people-default.jpg`}
                                                  alt={val.so_te_name}
                                                  title={val.so_te_name}
                                                  className="img-fluid rounded-circle mb-3 wow fadeInUp"
                                                />
                                              </div>
                                            }
                                      <div className="d-flex flex-column">
                                        <h6 className="text-dark mb-1 wow fadeInUp">{val.so_te_name}</h6>
                                        {val.so_te_designation && <p className="m-0 wow fadeInUp">{val.so_te_designation}</p>}
                                        {val.so_te_company && <p className="m-0 wow fadeInUp">{val.so_te_company}</p>}
                                      </div>
                                    </Link>
                                    :
                                    <div className="text-decoration-none">
                                            {
                                              val.so_te_pic !== null && val.so_te_pic !== "" ?
                                              <div className="col-12">
                                                <img
                                                  src={`${global.variables.strApiUrl}/speaker-org-team-picture/${val.so_te_pic}`}
                                                  alt={val.so_te_name}
                                                  title={val.so_te_name}
                                                  className="img-fluid rounded-circle mb-3 wow fadeInUp"
                                                />
                                              </div>
                                              :
                                              <div className="col-12">
                                                <img
                                                  src={`${global.variables.strDomainUrl}images/people/people-default.jpg`}
                                                  alt={val.so_te_name}
                                                  title={val.so_te_name}
                                                  className="img-fluid rounded-circle mb-3 wow fadeInUp"
                                                />
                                              </div>
                                            }
                                      <div className="d-flex flex-column">
                                        <h6 className="text-dark mb-1 wow fadeInUp">{val.so_te_name}</h6>
                                        {val.so_te_designation && <p className="m-0 wow fadeInUp">{val.so_te_designation}</p>}
                                        {val.so_te_company && <p className="m-0 wow fadeInUp">{val.so_te_company}</p>}
                                      </div>
                                    </div>
                                  }
                                  </div>
                                </Col>
                                :
                                null
                            ))}
                            </Row>
                            
                            {eventOrganiserList.length > 4 && (
                              <Accordion defaultActiveKey="0" className="">
                                <Accordion.Item eventKey="1">
                                  <Accordion.Body className="px-0 pt-4 pb-0">
                                    <Row className="row-cols-1 row-cols-md-2 row-cols-lg-4 g-4">
                                      {eventOrganiserList.slice(4).map((val, index) =>{
                                          return(
                                            val.so_te_event_id === event[0]?.event_id ? 
                                          <Col key={index + 4}>
                                            <div className="h-100 border p-3 rounded-3 text-center">
                                            {
                                              val.so_te_url !== null && val.so_te_url !== "" ?
                                              <Link to={val.so_te_url} target="_blank" className="text-decoration-none">
                                                  {
                                                    val.so_te_pic !== null && val.so_te_pic !== "" ?
                                                    <div className="col-12">
                                                      <img
                                                        src={`${global.variables.strApiUrl}/speaker-org-team-picture/${val.so_te_pic}`}
                                                        alt={val.so_te_name}
                                                        title={val.so_te_name}
                                                        className="img-fluid rounded-circle mb-3 wow fadeInUp"
                                                      />
                                                    </div>
                                                    :
                                                    <div className="col-12">
                                                      <img
                                                        src={`${global.variables.strDomainUrl}images/people/people-default.jpg`}
                                                        alt={val.so_te_name}
                                                        title={val.so_te_name}
                                                        className="img-fluid rounded-circle mb-3 wow fadeInUp"
                                                      />
                                                    </div>
                                                  }
                                                <div className="d-flex flex-column">
                                                  <h6 className="text-dark mb-1 wow fadeInUp">{val.so_te_name}</h6>
                                                  {val.so_te_designation && <p className="m-0 wow fadeInUp">{val.so_te_designation}</p>}
                                                  {val.so_te_company && <p className="m-0 wow fadeInUp">{val.so_te_company}</p>}
                                                </div>
                                              </Link>
                                              :
                                              <div className="text-decoration-none">
                                                <div className="col-12">
                                                  <img
                                                    src={`${global.variables.strApiUrl}/speaker-org-team-picture/${val.so_te_pic}`}
                                                    alt={val.so_te_name}
                                                    title={val.so_te_name}
                                                    className="img-fluid rounded-circle mb-3 wow fadeInUp"
                                                  />
                                                </div>
                                                <div className="d-flex flex-column">
                                                  <h6 className="text-dark mb-1 wow fadeInUp">{val.so_te_name}</h6>
                                                  {val.so_te_designation && <p className="m-0 wow fadeInUp">{val.so_te_designation}</p>}
                                                  {val.so_te_company && <p className="m-0 wow fadeInUp">{val.so_te_company}</p>}
                                                </div>
                                              </div>
                                            }
                                            </div>
                                          </Col>
                                          :
                                          null
                                          )
                                        })}
                                    </Row>
                                  </Accordion.Body>
                                  <Accordion.Header className="mt-4">
                                    <button
                                      className={`btn btn-primary rounded-pill px-4 py-2 mx-auto`}
                                      onClick={() => setShowAllOrganiser(!showAllOrganiser)}
                                    >
                                      {showAllOrganiser ? 'View Less' : 'View More'}
                                    </button>
                                  </Accordion.Header>
                                </Accordion.Item>
                              </Accordion>
                            )}
                          </div>
                        </div>
                      </div>
                      :
                      null
                    }

                    {/* Event Schedule */}
                    {
                            eventDaysList.length > 0 ?
                    <div className="my-md-5 my-4 px-lg-5 px-4 bg-light py-md-5 py-4 position-relative overflow-hidden">
                      <div className="pe-md-2 col-md-11">
                        <div id="EventSchedule">
                          <div className="mb-4">
                            <h4 className="wow fadeInUp">Event Schedule</h4>
                            <div className="mb-2 d-flex align-items-center fw-bold text-primary wow fadeInUp">
                              {
                                event[0].event_from_date != event[0].event_to_date ?
                                  <>

                                    {
                                      event[0].from_mon != event[0].to_mon ?
                                        <>
                                          {` ${event[0].from_day}-${event[0].from_mon}-${event[0].from_year} to ${event[0].to_day}-${event[0].to_mon}-${event[0].to_year}`}

                                        </>
                                        :
                                        <>
                                          {` ${event[0].from_day}-${event[0].from_mon}-${event[0].from_year} to ${event[0].to_day}-${event[0].to_mon}-${event[0].to_year}`}
                                        </>
                                    }
                                  </>

                                  :
                                  // when from and to date same 
                                  <>
                                    {
                                      //when from and to date's month is same
                                      event[0].from_mon != event[0].to_mon ?
                                        <>
                                          {` ${event[0].from_day}-${event[0].from_mon}-${event[0].from_year} to ${event[0].to_day}-${event[0].to_mon}-${event[0].to_year}`}
                                        </>
                                        :
                                        <>
                                          {` ${event[0].from_day}-${event[0].from_mon}-${event[0].from_year}`}
                                        </>
                                    }
                                  </>
                              }
                              </div>
                          </div>
                              <div className="">
                                <Tabs id="controlled-tab" defaultActiveKey={key} activeKey={key}
                                 onSelect={(k) => {
                                  setKey(k);
                                }}
                                   onOverflow={"scroll"}>
                                  {
                                    eventDaysList.map((val_day , index)=>{
                                      return(
                                          <Tab key={val_day.ev_day_id} eventKey={val_day.ev_day_id} title={val_day.ev_day_title}>
                                            <Accordion defaultActiveKey="0" className="p-md-3 p-2 rounded-3 bg-everight">
                                              {
                                                eventScheduleTimingList.map((val , index)=>{
                                                  return(
                                                      <Accordion.Item eventKey={index.toString()} key={`sched-${index}`} className="mb-2 overflow-hidden">
                                                        <Accordion.Header>
                                                          <Row className="w-100">
                                                            <Col className="col-md-3 col-12"><h5 className="m-0">{val.evt_sched_time}</h5></Col>
                                                            <Col className="col-md-9 col-12"><h5 className="m-0">{val.evt_sched_title}</h5></Col>
                                                          </Row>
                                                        </Accordion.Header>
                                                        <Accordion.Body>
                                                          <span dangerouslySetInnerHTML={{__html : val.evt_sched_desc}}></span>
                                                        </Accordion.Body>
                                                      </Accordion.Item>
                                                  )
                                                })
                                              }
                                            </Accordion>
                                          </Tab>
                                      )
                                    })
                                  }
                                </Tabs>
                              </div>
                            
                        </div>
                      </div>
                    </div>
                    :
                    null
                  }
                    {/* Event Partners */}
                    {
                      getSponsorCategory.length > 0 ?
                        <div className="my-md-5 my-4 px-lg-5 px-4 position-relative overflow-hidden">
                          <div className="pe-md-2 col-md-11">
                            <div className="mb-4">
                              <h4 className="wow fadeInUp">Event Sponsors</h4>
                            </div>
                            <Row className="row-cols-1 gy-4">
                              {
                                getSponsorCategory.map((val_sponsor_cat , index)=>{
                                  return(
                                    <>
                                      <Col>
                                        <h5 className="text-primary fw-bold mb-2 wow fadeInUp">{val_sponsor_cat.spons_cat_name}</h5>
                                          <Row className="row-cols-2 row-cols-xl-4 g-4">
                                            {
                                              eventSponsorList.map((val , index)=>{
                                                return(
                                                  val_sponsor_cat.spons_cat_id === val.sponsor_spons_cat_id ?
                                                  <Col>
                                                    <div className="border bg-white text-center rounded-3 px-3 py-2 wow fadeInUp">
                                                      <img src={global.variables.strApiUrl + "/sponsor-logo/" + val.sponsor_logo} alt={val.sponsor_name} title={val.sponsor_name} className="img-fluid p-0" />
                                                    </div>
                                                    <div className="text-center wow fadeInUp">
                                                    <p className="text-capitalize font-weight-bold m-0 mt-2">{val.sponsor_name}</p>
                                                    </div>
                                                  </Col>
                                                :
                                                null
                                              )
                                            })
                                            }
                                        </Row>
                                      </Col>
                                      <div className="text-dark opacity-50">
                                        <hr className="m-0" />
                                      </div>
                                    </>
                                  )
                                })
                              }
                            </Row>
                          </div>
                        </div>
                      :
                      null
                    }

                    {/* Event Venue */}
                    <div className="my-md-5 my-4 px-lg-5 px-4 bg-light py-md-5 py-4 position-relative overflow-hidden">
                      <div className="pe-md-2 col-md-11">
                        <Row>
                          <Col md={4}>
                            <div className="mb-4">
                              <h3 className="wow fadeInUp">How To Reach</h3>
                            </div>
                            <div className="mb-3">
                              <h6 className="fw-medium d-block">Venue</h6>
                              {event[0].event_venue}
                            </div>
                            {
                              event[0].event_address !== null && event[0].event_address !== "" ?
                                <div className="mb-3">
                                  <h6 className="fw-medium d-block">Address</h6>
                                  {event[0].event_address}
                                </div>
                              :
                                null
                            }
                            {
                              eventSocialList.length > 0 ?
                                <div className="mb-3 d-block d-md-none">
                                  <h6 className="fw-medium d-block">We’re in Social</h6>
                                  <ul className="social_media flex-grow-0 w-auto wow fadeInUp">
                                    <SocialMediaIcons eventSocialList={eventSocialList}/>
                                  </ul>
                                </div>
                              :
                              null
                            }
                          </Col>
                          {
                            event[0].event_directionmap !== null && event[0].event_directionmap !== "" ?
                              <Col md={8}>
                                <div className="ratio ratio-21x9 rounded-4 overflow-hidden" dangerouslySetInnerHTML={{ __html : event[0].event_directionmap}}>
                                </div>
                              </Col>
                            :
                            null
                          }
                        </Row>
                      </div>
                    </div>

                    {/* Event gallery */}
                    {
                      eventGalleryList.length > 0 ?
                        <div className="my-md-5 my-4 px-lg-5 px-4 position-relative overflow-hidden">
                          <div className="pe-md-2 col-md-11">
                            <div className="mb-4">
                              <h3 className="wow fadeInUp">Event Photo Gallery</h3>
                            </div>
                            <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 1280: 3 }}>
                              <Masonry gutter="1.5rem">
                                {
                                  eventGalleryList.map((val, index)=>{
                                    return(
                                      <div>
                                        <Link className="pointer" onClick={(event) => InvokePhotoGallery(event, val.egallery_event_id ,++index)}>
                                          <img src={global.variables.strApiUrl + "/events-gallery-image/" + val.egallery_pic} alt={val.egallery_title} title={val.egallery_title} className="img-fluid p-0 rounded-3 border bg-white wow fadeInUp" />
                                        </Link>
                                      </div>
                                    )
                                  })
                                }
                              </Masonry>
                            </ResponsiveMasonry >
                          </div>
                        </div>
                      :
                      null

                    }
                  </div>
                </Col>
                <Col md={3} lg={3} className="py-md-5 mt-md-5 mb-5 mb-md-4 container-end position-relative z-3 d-none d-md-block">
                  <div className="ps-lg-5 ps-md-4 rightpanel">
                    <div className="border-bottom border-4 border-primary rounded-4 h-100 mb-3">
                      <div className="bg-everight rounded-4 py-3 px-4 border h-100">
                        <div>
                          <p className="fw-bold mb-1 wow fadeInUp">Event Details</p>
                          <div className="mb-1 d-flex gap-2 wow fadeInUp">
                            <FaRegCalendarAlt className="icon-16" />
                            <div>
                            {
                          event[0].event_from_date != event[0].event_to_date ?
                            <>

                              {
                                event[0].from_mon != event[0].to_mon ?
                                  <>
                                    {` ${event[0].from_day}-${event[0].from_mon}-${event[0].from_year} to ${event[0].to_day}-${event[0].to_mon}-${event[0].to_year}`}

                                  </>
                                  :
                                  <>
                                    {` ${event[0].from_day}-${event[0].from_mon}-${event[0].from_year} to ${event[0].to_day}-${event[0].to_mon}-${event[0].to_year}`}
                                  </>
                              }
                            </>

                            :
                            // when from and to date same 
                            <>
                              {
                                //when from and to date's month is same
                                event[0].from_mon != event[0].to_mon ?
                                  <>
                                    {` ${event[0].from_day}-${event[0].from_mon}-${event[0].from_year} to ${event[0].to_day}-${event[0].to_mon}-${event[0].to_year}`}
                                  </>
                                  :
                                  <>
                                    {` ${event[0].from_day}-${event[0].from_mon}-${event[0].from_year}`}
                                  </>
                              }
                            </>

                        }
                            </div>
                          </div>
                          {
                            event[0].event_time !== null && event[0].event_time !== "" ?
                              <div className="mb-1 d-flex gap-2 wow fadeInUp">
                                <FaRegClock className="icon-16" />
                                <div>
                                  {event[0].event_time}
                                </div>
                              </div>
                            :
                            null
                          }
                            <div className="mb-1 d-flex gap-2 wow fadeInUp">
                              <FiMapPin className="icon-16" />
                              <div>
                               {event[0].event_venue}
                              </div>
                            </div>
                          {
                            event[0].event_reg_url !== null && event[0].event_reg_url !== "" ?
                              <div className="mb-1 d-flex gap-2 wow fadeInUp">
                                <BsPencilSquare className="icon-16" />
                                <div>
                                  <Link to={event[0].event_reg_url} target="_blank" className="text-black">Click here to Register</Link>
                                </div>
                              </div>
                            :
                            null
                          }
                        </div>
                        {
                          event[0].event_fees !== null && event[0].event_fees !== "" ?
                          <>
                            <div className="text-dark opacity-50">
                              <hr className="my-3" />
                            </div>
                            <div>
                              <b className="fw-bold">Fees:</b> INR {event[0].event_fees}
                            </div>
                            
                          </>
                          :
                          null
                        }
                        {
                          event[0].event_contact !== null && event[0].event_contact !== "" ?
                          <>
                            <div className="text-dark opacity-50">
                              <hr className="my-3" />
                            </div>
                            <div>
                              <p className="fw-bold mb-1 wow fadeInUp">Contact Details</p>
                              <p dangerouslySetInnerHTML={{ __html : event[0].event_contact }}></p>
                            </div>
                          </>
                          :
                          null
                        }
                        {
                          eventSocialList.length > 0 ?
                            <>
                            <div className="text-dark opacity-50">
                              <hr className="my-3" />
                            </div>
                            <div>
                              <p className="fw-bold mb-1 wow fadeInUp">We’re in Social</p>
                              <ul className="social_media flex-grow-0 w-auto wow fadeInUp">
                                <SocialMediaIcons eventSocialList={eventSocialList}/>
                              </ul>
                            </div>
                            </>
                          :
                          null
                        }
                      </div>
                    </div>
                  </div>
                </Col>
              </Row >
            </Container >

          </>
            

            :
            null
      }
     {/* {/ Video popup /}
        {/ this link will open the video popup which will be hidden because the button from the grid is causing issue /} */}
      <a onClick={(e) => {
        e.preventDefault();
        setvideosToggler(!videos);
      }}
        className="d-none"
        id="lnk_trigger_video_popup_home"></a>

      <FsLightbox
        toggler={videos}
        sources={lightbox_video}
      />

      {/* {/ event Gallery popup /}
        {/ this link will open the event gallery popup which will be hidden because the button from the grid is causing issue /} */}
      <a onClick={(e) => {
        e.preventDefault();
        setEventGalleryToggler(!event_gallery_toggler);
      }}
        className="d-none"
        id="lnk_trigger_event_gallery_popup_home"></a>
        <FsLightbox toggler={event_gallery_toggler}
        sources={event_gallery?.map(item => {
					return(
						<div style={{
							display: 'flex', 
							flexDirection: 'column', 
							alignItems: 'center', 
							justifyContent: 'center'
						}} key={item.egallery_id} className="fsDiv">
						<img className="img-fluid" src={`${globalVariables.variables.strApiUrl}/events-gallery-image/${item.egallery_pic}`} alt={item.egallery_title} style={{ objectFit: 'contain' }} />
						<p style={{ textAlign: 'center', color: 'white', fontSize: '16px' }}>{item.egallery_title}</p>
						</div>
					)
				})}
        key={gallery_key}
        slide={gallerySlide}
      />

      {/* Breadcrumb */}
      <Breadcrumb className="breadcrumb-panel mt-auto">
        <Breadcrumb.Item href={globalVariables.variables.strDomainUrl}>Home</Breadcrumb.Item>
        <Breadcrumb.Item href={globalVariables.variables.strDomainUrl + "events"}>Events</Breadcrumb.Item>
        <Breadcrumb.Item active>{event != null ? event[0]?.event_name : null}</Breadcrumb.Item>
      </Breadcrumb>
    </>
  );
}