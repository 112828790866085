import React, { useState, useEffect } from "react";
import global from "../../global-variables";
import Header from "../layout/header";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import WOW from "wowjs";
import { GetNewsOnHomePageList } from "../../api/news-functions";
import { Container, Row, Col } from "reactstrap";
import Image from 'react-image-webp';
import Slider from 'react-slick';
import { Parallax } from 'react-parallax';
import VisualpanelBlock from "./visualpanel-block";
import globalVariables from "../../global-variables";
import Roadblock_Popup from './roadblock-popup'
import Metatag from '../../hooks/Metatag';
import { GetResearchCategoryList } from "../../api/research-category-functions";
import CookieConsent from "react-cookie-consent";
import ShowMoreText from "react-show-more-text";
import { FaLink, FaImage, FaRegFilePdf, FaVideo, FaEllipsisH, FaRegCalendarAlt } from "react-icons/fa";
import { GetNewsGalleryList } from "../../api/news-gallery-functions";
import FsLightbox from "fslightbox-react";

export default function Home() {
  // const [toggler, setToggler] = useState(true);

  const navigate = useNavigate()
  const [newsOnHomePageList, setNewsOnHomePageList] = useState();
  const [getrcategoryList, setRcategoryList] = useState(null);


  const OnGetResearchCategorySuccess = (p_response) => {

    // set the list for looping
    setRcategoryList(p_response);
  }
  // things to be done when the page is loaded
  useEffect(() => {

    // make the object for getting the research category list
    let _obj = new Object();
    _obj.rcategory_status = 1;

    // call the function to get the research category
    GetResearchCategoryList(_obj, OnGetResearchCategorySuccess, navigate);

  }, []);

  useEffect(() => {
    // add class for home header and remove inner class
    $(".fixed-top").removeClass("bg-white");
    $(".fixed-top").addClass("bg-header-home");

    $(window).on("scroll", function () {
      $(".navbar").offset().top > 10 ? $(".fixed-top").removeClass("bg-header-home") : $(".fixed-top").addClass("bg-header-home")
    });

    // initiate the wow effect
    new WOW.WOW({
      live: false,
    }).init();

  }, [newsOnHomePageList, getrcategoryList]);

  var settings = {
    fade: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    autoplay: true,
    autoplaySpeed: 10000,
  };

  const [videos, setvideosToggler] = useState(false);

  const [news_gallery_toggler, setNewsGalleryToggler] = useState(false);
  const [news_gallery, setNewsGallery] = useState([]);
  const [news_gallery_titel, setNewsGalleryTitel] = useState([]);

  const [gallery_key, setGalleryKey] = useState("");
  const [lightbox_video, setLightBoxVideo] = useState([]);

  // success function when news gallery is retrived
  const OnGetNewsGallerySuccess = (p_response) => {


    // set the array in the constant
    setNewsGallery(p_response);

    setGalleryKey(Date.now());

    // using time out for the value to get saved in state 
    setTimeout(() => {
      // trigger the link click to display the news gallery popup
      document.getElementById("lnk_trigger_news_gallery_popup_home").click();
    }, 200);

  }


  // function to invoke video popup
  const InvokeVideoPopup = (e, p_news_video_url) => {

    e.preventDefault();

    let _arrVideos = [];
    _arrVideos.push(p_news_video_url);

    // set the path to the constant for displaying
    setLightBoxVideo(_arrVideos);

    // trigger the link click to display the popup
    document.getElementById("lnk_trigger_video_popup_home").click();

  };


  // function to invoke photo gallery
  const InvokePhotoGallery = (e, p_news_id) => {

    e.preventDefault();

    // Make an object for getting news gallery
    let _objNewsGallery = new Object();
    _objNewsGallery.gallery_status = 1;
    _objNewsGallery.gallery_news_id = p_news_id;

    // call the function to get the news gallery
    GetNewsGalleryList(_objNewsGallery, OnGetNewsGallerySuccess, navigate);

  };

  // success function when the list is retrived
  const OnGetNewsHomeSuccess = (p_response) => {
    // set the list for looping
    setNewsOnHomePageList(p_response);
  }

  // things to be done when the page is loaded
  useEffect(() => {

    // call the function to get the news
    GetNewsOnHomePageList(OnGetNewsHomeSuccess);

  }, []);

  return (
    <>
      <Header main_className="bg-header-home"></Header>
      <Metatag title='Research and Development Programs for Green Energy, Environment and Sustainability'
        description='Research Programs for Green Energy, Battery Technologies, Bio Fuels and Other Green Energy Sustainability Programs offered by IIT Bombay Research Hub'
        keywords='Research Programs for Green Energy by IIT Bombay, Research Programs for Green Hydrogen by IIT Bombay, Research Programs for Battery Technologies by IIT Bombay, Research Programs for Bio Fuels by IIT Bombay, Research Programs for Sustainability by IIT Bombay, IIT Bombay Research Hub for Green Energy, IIT Bombay Research Hub for Sustainability'
      />

      {/* Slider Container */}
      <VisualpanelBlock />

      {/* Focus Areas */}
      <Container className="focusareas-container">
        <div className="bg-white rounded-4 position-relative overflow-hidden">
          <div className="bg-primary bg-opacity-25">
            <h2 className="h1 fw-light py-3 m-0 text-center text-primary wow fadeInUp">Focus Areas</h2>
            <Row className="gx-1 row-cols-2 row-cols-md-5 row-cols-lg-5 overflow-hidden">
              <Col className="bg-focusareas1 text-center border-bottom border-10 border-primary">
                <div className="px-3 p-4">
                  <div className="px-md-4 px-2">
                    <Image className="img-fluid mb-3 wow fadeInDown" loading="lazy" alt="Solar Thermal & PV" title="Solar Thermal & PV" width={100} height={100}
                      src={(global.variables.strDomainUrl + "images/icon-solar-pv.png")}
                      webp={(global.variables.strDomainUrl + "images/icon-solar-pv.webp")}
                    />
                  </div>
                  <h6 className="wow fadeInUp">Solar Thermal & PV</h6>
                </div>
              </Col>

              <Col className="bg-focusareas2 text-center border-bottom border-10 border-primary">
                <div className="px-3 p-4">
                  <div className="px-md-4 px-2">
                    <Image className="img-fluid mb-3 wow fadeInDown" loading="lazy" alt="Batteries & Fuel cells" title="Batteries & Fuel cells" width={100} height={100}
                      src={(global.variables.strDomainUrl + "images/icon-battery.png")}
                      webp={(global.variables.strDomainUrl + "images/icon-battery.webp")}
                    />
                  </div>
                  <h6 className="wow fadeInUp">Batteries & Fuel cells</h6>
                </div>
              </Col>
              <Col className="bg-focusareas3 text-center border-bottom border-10 border-primary">
                <div className="px-3 p-4">
                  <div className="px-md-4 px-2">
                    <Image className="img-fluid mb-3 wow fadeInDown" loading="lazy" alt="Biofuels" title="Biofuels" width={100} height={100}
                      src={(global.variables.strDomainUrl + "images/icon-biofuel.png")}
                      webp={(global.variables.strDomainUrl + "images/icon-biofuel.webp")}
                    />
                  </div>
                  <h6 className="wow fadeInUp">Biofuels</h6>
                </div>
              </Col>
              <Col className="bg-focusareas4 text-center border-bottom border-10 border-primary">
                <div className="px-3 p-4">
                  <div className="px-md-4 px-2">
                    <Image className="img-fluid mb-3 wow fadeInDown" loading="lazy" alt="Wind Energy" title="Wind Energy" width={100} height={100}
                      src={(global.variables.strDomainUrl + "images/icon-wind-energy.png")}
                      webp={(global.variables.strDomainUrl + "images/icon-wind-energy.webp")}
                    />
                  </div>
                  <h6 className="wow fadeInUp">Wind Energy</h6>
                </div>

              </Col>
              <Col className="bg-focusareas5 text-center border-bottom border-10 border-primary">

                <div className="px-3 p-4">
                  <div className="px-md-4 px-2">
                    <Image className="img-fluid mb-3 wow fadeInDown" loading="lazy" alt="Green Hydrogen" title="Green Hydrogen" width={100} height={100}
                      src={(global.variables.strDomainUrl + "images/icon-hydrogen.png")}
                      webp={(global.variables.strDomainUrl + "images/icon-hydrogen.webp")}
                    />
                  </div>
                  <h6 className="wow fadeInUp">Green Hydrogen</h6>
                </div>

              </Col>
              <Col className="bg-focusareas5 text-center border-bottom border-10 border-primary">
                <div className="px-3 p-4">
                  <div className="px-md-4 px-2">
                    <Image className="img-fluid mb-3 wow fadeInDown" loading="lazy" alt="Circular Economy" title="Circular Economy" width={100} height={100}
                      src={(global.variables.strDomainUrl + "images/icon-circular-economy.png")}
                      webp={(global.variables.strDomainUrl + "images/icon-circular-economy.webp")}
                    />
                  </div>
                  <h6 className="wow fadeInUp">Circular Economy</h6>
                </div>

              </Col>
              <Col className="bg-focusareas4 text-center border-bottom border-10 border-primary">
                <div className="px-3 p-4">
                  <div className="px-md-4 px-2">
                    <Image className="img-fluid mb-3 wow fadeInDown" loading="lazy" alt="Climate Change" title="Climate Change" width={100} height={100}
                      src={(global.variables.strDomainUrl + "images/icon-climate-change.png")}
                      webp={(global.variables.strDomainUrl + "images/icon-climate-change.webp")}
                    />
                  </div>
                  <h6 className="wow fadeInUp">Climate Change</h6>
                </div>

              </Col>
              <Col className="bg-focusareas3 text-center border-bottom border-10 border-primary">
                <div className="px-3 p-4">
                  <div className="px-md-4 px-2">
                    <Image className="img-fluid mb-3 wow fadeInDown" loading="lazy" alt="Environmental Studies" title="Environmental Studies" width={100} height={100}
                      src={(global.variables.strDomainUrl + "images/icon-environmental-studies.png")}
                      webp={(global.variables.strDomainUrl + "images/icon-environmental-studies.webp")}
                    />
                  </div>
                  <h6 className="wow fadeInUp">Environmental Studies</h6>
                </div>
              </Col>
              <Col className="bg-focusareas2 text-center border-bottom border-10 border-primary">
                <div className="px-3 p-4">
                  <div className="px-md-4 px-2">
                    <Image className="img-fluid mb-3 wow fadeInDown" loading="lazy" alt="Food & Water" title="Food & Water" width={100} height={100}
                      src={(global.variables.strDomainUrl + "images/icon-food-water.png")}
                      webp={(global.variables.strDomainUrl + "images/icon-food-water.webp")}
                    />
                  </div>
                  <h6 className="wow fadeInUp">Food & Water</h6>
                </div>
              </Col>
              <Col className="bg-focusareas1 text-center border-bottom border-10 border-primary">
                <div className="px-3 p-4">
                  <div className="px-md-4 px-2">
                    <Image className="img-fluid mb-3 wow fadeInDown" loading="lazy" alt="Decarbonisation" title="Decarbonisation" width={100} height={100}
                      src={(global.variables.strDomainUrl + "images/icon-caorbon-capture.png")}
                      webp={(global.variables.strDomainUrl + "images/icon-caorbon-capture.webp")}
                    />
                  </div>
                  <h6 className="wow fadeInUp">Decarbonisation</h6>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Container>

      {/* News & Events Container */}
      <Container>
        <Row>
          <Col lg="12" className="my-md-5 my-3 py-5">
            <h2 className="h1 text-primary fw-light text-center mb-4 wow fadeInUp">News & Events</h2>
            <Row className="row-cols-1 row-cols-md-2 row-cols-xl-4 g-4">
              {
                newsOnHomePageList?.map((val, index) => {

                  return (
                    <Col key={index}>
                      <div className="border-bottom border-4 border-primary rounded-4 h-100">
                        <div className="rounded-4 overflow-hidden position-relative border h-100 d-flex flex-column">
                          {
                            val.news_images !== null && val.news_images !== '' ?
                              <img loading="lazy" src={global.variables.strApiUrl + "/news-main-image/" + val.news_images} alt={val.news_headline} title={val.news_headline} className="img-fluid rounded-4 wow fadeIn" width={325} height={200} />
                              :
                              <img loading="lazy" src={global.variables.strDomainUrl + "images/news/eve3.jpg"} alt="" title="" className="img-fluid rounded-4 wow fadeIn" width={325} height={200} />
                          }
                          <div className="px-3 py-3 d-flex flex-column align-items-start h-100">
                            <h5 className="mb-3 wow fadeInUp">{val.news_headline}</h5>
                            <Row className="row-cols-1 g-2 mb-4">
                              <Col className="d-flex wow fadeInUp">
                                <div className="icon-w30 flex-grow-0"><FaRegCalendarAlt className="icon-20" /></div>
                                <div className="flex-grow-1">{val.news_date === val.news_to_date ? val.news_date : `${val.news_date} to ${val.news_to_date}`}</div>
                              </Col>
                            </Row>
                            <div className="d-flex gap-2 mt-auto">
                              {/* {/ link /} */}
                              {
                                val.news_link != null && val.news_link != "null" && val.news_link != "" ?
                                  <Link to={val.news_link} target="_blank" className="btn btn-primary rounded-pill Faicon px-3 py-1 ms-auto mt-auto wow fadeInUp"><FaLink /></Link>
                                  :
                                  null
                              }

                              {/* {/ Photo Gallery /} */}
                              {
                                parseInt(val.news_gallery_count) > 0 ?
                                  <a className="btn btn-primary rounded-pill Faicon px-3 py-1 ms-auto mt-auto wow fadeInUp" onClick={event => InvokePhotoGallery(event, val.news_id)}><FaImage /></a>
                                  :
                                  null
                              }

                              {/* {/ Document /} */}
                              {
                                val.news_document != null && val.news_document != "null" && val.news_document != "" ?
                                  <Link to={global.variables.strApiUrl + "/news-documents/" + val.news_document} target="_blank" className="btn btn-primary rounded-pill Faicon px-3 py-1 ms-auto mt-auto wow fadeInUp"><FaRegFilePdf /></Link>
                                  :
                                  null
                              }

                              {/* {/ Video /} */}
                              {
                                val.news_video_link != null && val.news_video_link != "null" && val.news_video_link != "" ?
                                  <a className="btn btn-primary rounded-pill Faicon px-3 py-1 ms-auto mt-auto wow fadeInUp" onClick={event => InvokeVideoPopup(event, val.news_video_link)}>    <FaVideo /></a>
                                  :
                                  null
                              }
                              {/* {
                                val.news_details != null && val.news_details != "" ?
                                  <Link to={global.variables.strDomainUrl + "news/" + val.news_type_pagename + "/" + val.news_pagename} className="btn btn-primary rounded-pill Faicon px-3 py-1 ms-auto mt-auto wow fadeInUp">
                                    <FaEllipsisH />
                                  </Link>
                                  :
                                  null

                              } */}
                              {
                                val.news_details != null && val.news_details != "" ?
                                  <Link to={global.variables.strDomainUrl + "news-details/" + val.news_pagename} className="btn btn-primary rounded-pill Faicon px-3 py-1 ms-auto mt-auto wow fadeInUp">
                                    <FaEllipsisH />
                                  </Link>
                                  :
                                  null

                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  )
                })
              }
              {/* <Col>
                <div className="border-bottom border-4 border-primary rounded-4 h-100">
                  <div className="rounded-4 overflow-hidden position-relative border h-100 d-flex flex-column">
                    <img src={global.variables.strDomainUrl + "images/news/eve3.jpg"} alt="" title="" className="img-fluid rounded-4 wow fadeIn" />
                    <div className="px-3 py-3">
                      <h5 className="mb-3 wow fadeInUp">Sustainable Chemical Manufacturing - Seminar</h5>
                      <Row className="row-cols-1 g-2">
                        <Col className="d-flex wow fadeInUp">
                          <div className="icon-w30 flex-grow-0"><FaRegCalendarAlt className="icon-20" /></div>
                          <div className="flex-grow-1">27-May-2024</div>
                        </Col>
                      </Row>
                    </div>
                    <div className="mt-auto p-3">
                      <Link to="" className="btn btn-primary px-4 rounded-pill wow fadeInUp">Read More</Link>
                    </div>
                  </div>
                </div>
              </Col> */}
            </Row>
          </Col>
        </Row>
      </Container>


      <Container fluid className="bg-light px-0">
        <Parallax blur={0} bgImage={global.variables.strDomainUrl + "images/bg-academics.jpg"} bgImageAlt="Service Offering" strength={600}>
          {<div className="bg-dark-green bg-opacity-75 position-absolute top-0 bottom-0 start-0 end-0"></div>}
          <Container className="parallax-banner-info py-5 position-relative z-3">
            <Row className="py-lg-5">
              <Col xl={10} className="m-auto">
                <h2 className="h1 fw-light mb-5 text-center text-white wow fadeInUp">Service Offerings</h2>
                <Row className="row-cols-1 row-cols-md-3 gxl-5 g-4 pb-3 justify-content-center">
                  <Col>
                    <div className="border-bottom border-4 border-primary rounded-4 h-100 text-center">
                      <div className="bg-white p-lg-4 p-4 rounded-4">
                        <h5 className="mb-0 wow fadeInUp">Training & Workshops</h5>
                        <Link to="/services" className="btn btn-primary px-4 rounded-pill mt-3 wow fadeInUp"><span className="h5">+</span></Link>
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="border-bottom border-4 border-primary rounded-4 h-100 text-center">
                      <div className="bg-white p-lg-4 p-4 rounded-4">
                        <h5 className="mb-0 wow fadeInUp">Sustainability Assessment</h5>
                        <Link to="/services" className="btn btn-primary px-4 rounded-pill mt-3 wow fadeInUp"><span className="h5">+</span></Link>
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="border-bottom border-4 border-primary rounded-4 h-100 text-center">
                      <div className="bg-white p-lg-4 p-4 rounded-4">
                        <h5 className="mb-0 wow fadeInUp">Research Consultancy</h5>
                        <Link to="/services" className="btn btn-primary px-4 rounded-pill mt-3 wow fadeInUp"><span className="h5">+</span></Link>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Parallax>
      </Container>

      <Container className="my-md-5 my-4 pt-4">
        <div className="text-center mb-lg-5 mb-4">
          <div className="col-md-8 mx-auto">
            <h2 className="h1 fw-light text-primary wow fadeInUp">Research</h2>
            <p className="wow fadeInUp">The research activities and interests of the IIT Bombay Research Hub for Green Energy and Sustainability can be broadly classified into the following areas:</p>
          </div>
        </div>
        <Row className="row-cols-1 row-cols-md-2 row-cols-lg-3 g-3 g-xl-4 g-xxl-5 pb-3 research justify-content-center">
          {
            getrcategoryList != null ?
              getrcategoryList.length > 0 ?
                getrcategoryList.map((val) => {
                  return (
                    <Col className="researchlink">
                      <div className="border-bottom border-4 border-primary rounded-4 h-100">
                        <Link to={globalVariables.variables.strDomainUrl + "research/" + val.rcategory_pagename}>
                          <div className="bg-primary bg-opacity-10 p-4 p-xl-4 rounded-4 h-100 text-center position-relative overflow-hidden">
                            <figure className="position-relative overflow-hidden p-0 rounded-4 border border-white border-4 wow fadeInUp">
                              <img loading="lazy" src={global.variables.strApiUrl + "/researchcategory-image/" + val.rcategory_image} width="380" height="175" alt={val.rcategory_name} title={val.rcategory_name} className="img-fluid" />
                            </figure>
                            <h6 className="px-3 pt-3 m-0 text-dark text-center wow fadeInUp"> {val.rcategory_name} </h6>
                            <div className="mt-2">
                              <Link to={globalVariables.variables.strDomainUrl + "research/" + val.rcategory_pagename}
                                className="btn btn-primary rounded-pill px-4 mt-2 mx-1">View
                                Projects</Link>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </Col>
                  )
                })
                :
                <Col>
                  <span className="text-center">No Records Found</span>
                </Col>
              :
              <Col>
                <span className="text-center">Loading...</span>
              </Col>
          }
        </Row>
      </Container>


      <Container fluid className="px-0 my-md-5 my-4">
        <Container>
          <Row className="g-0">
            <Col md={8} lg={8}>
              <div className="border rounded-4 px-lg-5 p-4 py-lg-5 h-100">
                <div className="pe-md-2 col-md-11">
                  <h2 className="h1 fw-light text-primary mb-3 wow fadeInUp">About GESH</h2>
                  <p className="wow fadeInUp">The Green Energy and Sustainability Research Hub at IIT Bombay addresses the global climate crisis through research in green energy and sustainability. We bridge the gap between academic research and real-world applications, fostering education and entrepreneurship. Our goal is to nurture future leaders to tackle environmental challenges creatively and innovatively. GESH will be the primary contact for green energy and sustainability research, education, and services at IITB.</p>
                  <p className="wow fadeInUp">Apart from research, we strive to provide:</p>

                  <Row className="row-cols-xl-2 row-cols-1 g-5 mb-4">
                    <Col className="d-flex align-items-center overflow-hidden">
                      <div>
                        <div className="icon-100  bg-primary rounded-circle p-1 me-3 overflow-hidden">
                          <Image className="img-fluid p-3 wow fadeInUp" loading="lazy" alt="Regular workshops, seminars, and conferences to facilitate knowledge exchange" title="Regular workshops, seminars, and conferences to facilitate knowledge exchange" width={100} height={100}
                            src={(global.variables.strDomainUrl + "images/icon-workshops.png")}
                            webp={(global.variables.strDomainUrl + "images/icon-workshops.webp")}
                          />
                        </div>
                      </div>
                      <div>
                        <p className="m-0 wow fadeInUp">Regular workshops, seminars, and conferences to facilitate knowledge exchange</p>
                      </div>
                    </Col>
                    <Col className="d-flex align-items-center overflow-hidden">
                      <div>
                        <div className="icon-100 bg-primary rounded-circle p-1 me-3 overflow-hidden">
                          <Image className="img-fluid p-3 wow fadeInUp" loading="lazy" alt="Collaborative research opportunities with leading global universities and corporations" title="Collaborative research opportunities with leading global universities and corporations" width={100} height={100}
                            src={(global.variables.strDomainUrl + "images/icon-research.png")}
                            webp={(global.variables.strDomainUrl + "images/icon-research.webp")}
                          />
                        </div>
                      </div>
                      <div>
                        <p className="m-0 wow fadeInUp">Collaborative research opportunities with leading global universities and corporations</p>
                      </div>
                    </Col>
                    <Col className="d-flex align-items-center overflow-hidden">
                      <div>
                        <div className="icon-100 bg-primary rounded-circle p-1 me-3 overflow-hidden">
                          <Image className="img-fluid p-3 wow fadeInUp" loading="lazy" alt="Industry-tailored educational programs to prepare the next generation of sustainability leaders" title="Industry-tailored educational programs to prepare the next generation of sustainability leaders" width={100} height={100}
                            src={(global.variables.strDomainUrl + "images/icon-educational-programs.png")}
                            webp={(global.variables.strDomainUrl + "images/icon-educational-programs.webp")}
                          />
                        </div>
                      </div>
                      <div>
                        <p className="m-0 wow fadeInUp">Industry-tailored educational programs to prepare the next generation of sustainability leaders</p>
                      </div>
                    </Col>
                    <Col className="d-flex align-items-center overflow-hidden">
                      <div>
                        <div className="icon-100 bg-primary rounded-circle p-1 me-3 overflow-hidden">
                          <Image className="img-fluid p-3 wow fadeInUp" loading="lazy" alt="A nurturing environment for green entrepreneurship and start-ups" title="A nurturing environment for green entrepreneurship and start-ups" width={100} height={100}
                            src={(global.variables.strDomainUrl + "images/icon-green-entrepreneurship.png")}
                            webp={(global.variables.strDomainUrl + "images/icon-green-entrepreneurship.webp")}
                          />
                        </div>
                      </div>
                      <div>
                        <p className="m-0 wow fadeInUp">A nurturing environment for green entrepreneurship and start-ups</p>
                      </div>
                    </Col>
                    <Col className="d-flex align-items-center overflow-hidden">
                      <div>
                        <div className="icon-100 bg-primary rounded-circle p-1 me-3 overflow-hidden">
                          <Image className="img-fluid p-3 wow fadeInUp" loading="lazy" alt="State-of-the-art laboratory facilities equipped with the latest technologies" title="State-of-the-art laboratory facilities equipped with the latest technologies" width={100} height={100}
                            src={(global.variables.strDomainUrl + "images/icon-laboratory.png")}
                            webp={(global.variables.strDomainUrl + "images/icon-laboratory.webp")}
                          />
                        </div>
                      </div>
                      <div>
                        <p className="m-0 wow fadeInUp">State-of-the-art laboratory facilities equipped with the latest technologies</p>
                      </div>
                    </Col>
                  </Row>

                  <p className="wow fadeInUp">Whether you're a researcher looking to make a difference, an industry partner seeking sustainable solutions, or a student passionate about environmental issues, the Green Energy and Sustainability Research Hub offers a platform to turn your green aspirations into reality.</p>
                  <p className="wow fadeInUp">The hub was established in 2024 by an unprecedented 18.6 million USD donation from a US-based alumnus, and our hub represents a significant leap forward in environmental stewardship and sustainable solutions.</p>

                </div>
              </div>
            </Col>
            <Col md={5} lg={5} className="py-md-5 professor-message">
              <div className="ps-lg-5 ps-md-4 mt-5 bg-white">
                <div className="border-bottom border-4 border-primary rounded-4 h-100">
                  <div className="bg-primary bg-opacity-10 rounded-4 py-4 px-lg-5 px-4 border">

                    <div className="mx-auto text-center pic-professor mb-3">
                      <Image className="img-fluid rounded-circle border border-white border-5 wow fadeInUp" loading="lazy" alt="Prof. Sanjay M Mahajani" title="Prof. Sanjay M Mahajani" width={200} height={200}
                        src={(global.variables.strDomainUrl + "images/pic-sanjay-m-mahajani.png")}
                        webp={(global.variables.strDomainUrl + "images/pic-sanjay-m-mahajani.webp")}
                      />
                    </div>
                    <h5 className="fw-bold text-center mb-3 wow fadeInUp">Message from the Professor In-Charge,<br /> Prof. Sanjay M Mahajani</h5>
                    <p className="fw-bold wow fadeInUp">Core Faculty, Dept of Chemical Engineering, IIT Bombay</p>
                    <p className="wow fadeInUp">As the Professor in Charge of the IITB Research Hub for Green Energy and Sustainability (GESH IITB), I am honoured to share our mission to support the transition to green energy and sustainability through research, education, innovation and capacity building with academia, industry, regulators and other stakeholders.</p>
                    <p className="wow fadeInUp">In response to the pressing challenges of climate change and environmental degradation, IIT Bombay has consistently led the way in pioneering sustainable technologies and solutions. Our team operates across several key verticals, each essential to our mission for a greener future. Our research includes faculty-initiated projects, hub-initiated projects, industry-academia projects, and projects in green energy and sustainability fields.</p>
                    <ShowMoreText
                      /* Default options */
                      lines={6}
                      more="Show more"
                      less="Show less"
                      className="content-css"
                      anchorClass="show-more-less-clickable btn btn-primary px-4 rounded-pill"
                      // onClick={this.executeOnClick}
                      expanded={false}
                      truncatedEndingComponent={"..."}
                    >
                      <p className="wow fadeInUp">We also focus on developing world-class research infrastructure in this domain. Education, training, and workshops are pivotal, ensuring all stakeholders are equipped with cutting-edge knowledge and skills and building a focused talent pipeline for the present and future. Outreach activities, including roundtables, seminars, webinars, symposiums, conclaves, conferences, and expos, facilitate robust dialogue and knowledge dissemination. This will help understand the need, enhance industry-academia collaborations, and identify more problems for industry-academia research and hub-initiated projects. GESH also works on the sustainability of the IITB campus.</p>
                      <p className="wow fadeInUp">Our commitment to sustainability is evident in our plan to enter into sustainability assessment for industry and academia. Our entrepreneurial initiatives empower innovators to transform research outcomes into viable enterprises, bridging the gap between academia and industry while promoting sustainable development.</p>
                      <p className="wow fadeInUp">Each vertical at GESH operates synergistically, advancing the organisation's mission and goals with precision and efficacy. GESH will serve as a support hand to accelerate the net zero targets of the nation.</p>
                    </ShowMoreText>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>



      {/* <FsLightbox
        toggler={toggler}
        sources={[global.variables.strDomainUrl + "/images/visualpanel/mob-visual.jpg"]}
      /> */}

      <Roadblock_Popup />
      <CookieConsent
        location="bottom"
        buttonText="GOT IT"
        buttonClasses="btn rounded-0 text-white px-3"
        containerClasses="cookie-wrapper"
        contentClasses="cookie-content"
        cookieName="cookiesAccepted"
        expires={150}
        className="cookie-popup-inner"
      >We use cookies to ensure that we give you the best experience and to analyze our website traffic and performance; we never collect any personal data.</CookieConsent>

      {/* {/ Video popup /}
        {/ this link will open the video popup which will be hidden because the button from the grid is causing issue /} */}
      <a onClick={(e) => {
        e.preventDefault();
        setvideosToggler(!videos);
      }}
        className="d-none"
        id="lnk_trigger_video_popup_home"></a>

      <FsLightbox
        toggler={videos}
        sources={lightbox_video}
      />

      {/* {/ News Gallery popup /}
      {/ this link will open the news gallery popup which will be hidden because the button from the grid is causing issue /} */}
      <a onClick={(e) => {
        e.preventDefault();
        setNewsGalleryToggler(!news_gallery_toggler);
      }}
        className="d-none"
        id="lnk_trigger_news_gallery_popup_home"></a>

      <FsLightbox
        toggler={news_gallery_toggler}
        sources={news_gallery?.map(item => {
					return(
						<div style={{
							display: 'flex', 
							flexDirection: 'column', 
							alignItems: 'center', 
							justifyContent: 'center'
						}} key={item.gallery_id} className="fsDiv">
						<img className="img-fluid" src={`${globalVariables.variables.strApiUrl}/news-gallery-image/${item.gallery_pic}`} alt={item.gallery_title} style={{ objectFit: 'contain' }} />
						<p style={{ textAlign: 'center', color: 'white', fontSize: '16px' }}>{item.gallery_title}</p>
						</div>
					)
				})}
        key={gallery_key}
      />
    </>
  );
}