import React, { useState, useEffect } from "react";
import global from "../../global-variables";
import Header from "../layout/header";
import { Link } from "react-router-dom";
import $ from "jquery";
import WOW from "wowjs";
import { FaLink, FaImage, FaRegFilePdf, FaVideo, FaChevronRight } from "react-icons/fa";

import { Container, Row, Col } from "reactstrap";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Metatag from "../../hooks/Metatag";


export default function CampusSustainability() {
  const [show, setShow] = useState(false);
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  useEffect(() => {
    $(window).on("scroll", function () {
      $(".navbar").offset().top > 10 ? $(".fixed-top").removeClass("bg-header-home") : $(".fixed-top").removeClass("bg-header-home")
    });

    // initiate the wow effect
    new WOW.WOW({
      live: false,
    }).init();


  }, []);


  return (
    <>
      <Header></Header>

      <Metatag title='IIT Bombay Campus Sustainability Assessment'
        description='IIT Bombay Campus Sustainability Assessment and Implementation Plan'
        keywords='IIT Bombay Campus Sustainability Assessment, IIT Bombay Campus Sustainability Plan, IIT Bombay Sustainability Tracking Assessment & Rating System - STARS'
      />

      {/* Visual Panel */}
      <Container fluid className="bg-visual-inside p-0" >
        <div className="visual-inside-big visual-container-inside">
          <Container >
            <Row className="g-md-0 rounded-4">
              <Col md={6}>
                <div className="d-flex align-items-end h-100 pb-md-5">
                  <div className="d-inline-block m-0 align-self-end py-xl-3 p-2 text-white">
                    <h1 className="fw-light text-white m-0 align-self-center wow fadeInUp">
                      Campus Sustainability
                    </h1>
                  </div>
                </div>
              </Col>
              <Col md={6} className="mt-auto ms-auto">
                <div className="border-bottom border-5 border-primary rounded-4">
                  <img src={global.variables.strDomainUrl + "images/visual-campus-sustainability.jpg"} className="img-fluid rounded-4" alt="" title="" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>

      {/* Container */}
      <Container className="my-5 pt-4 pt-xl-5">
        <Row className="g-0">
          
            <div className="border rounded-4 p-xl-5 p-3 h-100">
            <Col md={12} lg={10}>
              <Row>
                <Col md={5} lg={3}>
                <Link to="../images/pdf/sustainability-policy-IITB.pdf" target="_blank" className="nav-link">
                  <img src={global.variables.strDomainUrl + "images/pic-campus-sustainability.png"} className="img-fluid border rounded-4 mb-md-0 mb-4" alt="" title="" /> 
                  </Link>
                </Col>
                <Col md={7} lg={9} className="list ps-lg-5">
                  <h4 className="mb-3">What is happening inside IITB towards Sustainability?</h4>
                  <p>IIT Bombay Campus Sustainability Plan</p>
                  <ul>
                    <li>Campus Sustainability Assessment (Sustainability Tracking Assessment & Rating System (STARS) as reference)</li>
                    <li>Implement Sustainable Practices (STARS as reference)</li>
                    <li>Innovate Sustainable Practices</li>
                    <li>CO2 Emissions / Carbon Footprint all events at IITB</li>
                    <li>App with gamification for all students of IITB for recording</li>
                    <li>Net Zero Campus</li>
                  </ul>
                </Col>
              </Row>
              </Col>
            </div>
        </Row>
      </Container>

      {/* Breadcrumb */}
      <Breadcrumb className="breadcrumb-panel mt-auto">
        <Breadcrumb.Item href={global.variables.strDomainUrl}>Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Campus Sustainability</Breadcrumb.Item>
      </Breadcrumb>
    </>
  );
}