import React from "react";
// import { Navigate } from "react-router-dom";

import Home from "../pages/home";
import Home1 from "../pages/home/index1";
import NewsEvents from "../pages/news";
import NewsDetails from "../pages/news/news-details";
import Events from "../pages/events/index";
import EventDetails from "../pages/events/event-details";
import EventDetailsStatic from "../pages/events/event-details-final-backup";
import Careers from "../pages/careers/";
import People from "../pages/people";
import PeopleDetails from "../pages/people/people-details";
import Research from "../pages/research";
import GESHProjects from "../pages/research/gesh-projects";
import Publications from "../pages/research/publications";
import Publications2022 from "../pages/research/publications-2022";
import GESHProjectDetails from "../pages/research/geshproject-details";
import AboutUs from "../pages/aboutus";
import OURPartners from "../pages/partnerships/our-partners";
import MappedResearch from "../pages/research/mapped-category-deatils";
import PeopleDepartment from "../pages/people/people-department";
import Search from "../pages/layout/searchresults";
import Sitemap from "../pages/sitemap";
import PageNotFound from "../pages/errorDocs/pageNotFound";
import ErrorPage from "../pages/layout/errorPage";
import CampusSustainability from "../pages/campus-sustainability";
import Services from "../pages/services";
import FocusAreas from "../pages/focus-areas";
import Blog from "../pages/blog";
import BlogDetails from "../pages/blog/details"; 

import GESHProject1 from "../pages/research/gesh-project1";
import GESHProjectdestailsStatic from "../pages/research/gesh-project-details1";

const websiteRoutes = [
  { path: "/", component: <Home /> },
  { path: "/home", component: <Home /> },
  { path: "/home1", component: <Home1 /> },
  { path: "/events", component: <Events /> },
  { path: "/events/:event_pagename", component: <EventDetails /> },
  { path: "/event-details-static", component: <EventDetailsStatic /> },
  // { path: "/news/:news_type_pagename", component: <NewsEvents /> },
  // { path: "/news/:news_type_pagename/:page_name", component: <NewsDetails /> },
  { path: "/news-events", component: <NewsEvents /> },
  { path: "/news-details/:page_name", component: <NewsDetails /> },
  { path: "/careers", component: <Careers /> },
  { path: "/people/:pcategory_pagename", component: <People /> },
  {
    path: "/people/:pcategory_pagename/:ps_category_pagename",
    component: <PeopleDetails />,
  },
  {
    path: "/people/:pcategory_pagename/:ps_category_pagename/:department_pagename",
    component: <PeopleDepartment />,
  },
  { path: "/research", component: <Research /> },
  { path: "/research-projects", component: <GESHProjects /> },
  {
    path: "/research-projects/:rtype_pagename",
    component: <GESHProjectDetails />,
  },
  { path: "/research/:rcategory_pagename", component: <MappedResearch /> },

  { path: "/publications", component: <Publications /> },
  { path: "/publications-2022", component: <Publications2022 /> },
  { path: "/aboutus", component: <AboutUs /> },
  { path: "/ourpartners", component: <OURPartners /> },

  { path: "/campus-sustainability", component: <CampusSustainability /> },
  { path: "/services", component: <Services /> },
  { path: "/focus-areas", component: <FocusAreas /> },

  { path: "/search-result/:str", component: <Search /> },

  { path: "/blog", component: <Blog /> },
  { path: "/blog/:blog_year", component: <Blog /> },
  { path: "/blog-category/:blog_category_pagename", component: <Blog /> },
  { path: "/blog-authors/:blog_author_pagename", component: <Blog /> },
  { path: "/blog-tags/:blog_tags_pagename", component: <Blog /> },
  { path: "/blog-search/:blog_search", component: <Blog /> },
  { path: "/blog-details/:blog_pagename", component: <BlogDetails /> },

  { path: "/sitemap", component: <Sitemap /> },

  { path: "/error-page", component: <ErrorPage /> },
  { path: "*", component: <PageNotFound /> },

  { path: "/research/gesh-project1", component: <GESHProject1 /> },
  { path: "/research/gesh-project-details1", component: <GESHProjectdestailsStatic /> },
];

export { websiteRoutes };
