import React, { useState, useEffect } from "react";
import global from "../../global-variables";
import Header from "../layout/header";
import { Link ,useNavigate} from "react-router-dom";
import $ from "jquery";
import WOW from "wowjs";
import { FaLink, FaImage, FaRegFilePdf, FaVideo, FaChevronRight } from "react-icons/fa";
import axios from "axios";
import { Container, Row, Col } from "reactstrap";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Metatag from "../../hooks/Metatag";
import { HashLink } from "react-router-hash-link";

export default function Sitemap() {

  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  useEffect(() => {
    
    // initiate the wow effect
    new WOW.WOW({
      live: false,
    }).init();


  }, []);

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -134;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
}

const [peopleCategory, setPeopleCategoryList] = useState([]);

const [newsTypeList, setNewsTypeList] = useState([]);

useEffect(() => {
    let _obj = new Object();
    _obj.pcategory_status = 1;
    // setTimeout(() => {

        axios.post(global.variables.strApiUrl + "/peoplecategoryApi/getcategoryHavingPeopleList", _obj)
            .then((response_list) => {
              if(response_list.data === 'error')
              {
                navigate('/error-page')
              }
              else
              {
                // pass the result in the success function of the respective page
                setPeopleCategoryList(response_list.data[0]);
              }
            });
    // }, 300);

    // axios.post(global.variables.strApiUrl + "/newstypeApi/getcategoryHavingNewsList")
    //     .then((response_list) => {
    //         // pass the result in the success function of the respective page
    //         setNewsTypeList(response_list.data[0]);
    //     });

}, [])

  return (
    <>
      <Header></Header>
      <Metatag title='Research and Development Programs for Green Energy, Environment and Sustainability' keywords='Research Programs for Green Energy by IIT Bombay, Research Programs for Green Hydrogen by IIT Bombay, Research Programs for Battery Technologies by IIT Bombay, Research Programs for Bio Fuels by IIT Bombay, Research Programs for Sustainability by IIT Bombay, IIT Bombay Research Hub for Green Energy, IIT Bombay Research Hub for Sustainability' description='Research Programs for Green Energy, Battery Technologies, Bio Fuels and Other Green Energy Sustainability Programs offered by IIT Bombay Research Hub' />

      {/* Visual Panel */}
      <Container fluid className="bg-visual-inside p-0" >
        <div className="visual-inside-big visual-container-inside">
          <Container>
            <Row className="g-md-0 rounded-4">
              <Col md={6}>
                <div className="d-flex align-items-end h-100 pb-md-5">
                  <div className="d-inline-block m-0 align-self-end py-xl-3 p-2 text-white">
                    <h1 className="fw-light text-white m-0 align-self-center wow fadeInUp">
                      Focus Areas
                    </h1>
                  </div>
                </div>
              </Col>
              <Col md={6} className="mt-auto ms-auto">
                <div className="border-bottom border-5 border-primary rounded-4">
                  <img src={global.variables.strDomainUrl + "images/visual-sitemap.jpg"} className="img-fluid rounded-4" alt="" title="" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>
       

      {/* Container */}
      <Container className="my-5 pt-4 pt-xl-5 list">
        <Row className="row-cols-lg-4 row-cols-2 g-md-5 g-3">
            <Col>
              <ul>
                <li><Link to={global.variables.strDomainUrl}>Home</Link></li>
                <li><Link to={global.variables.strDomainUrl +"aboutus"}>About Us</Link></li>
                <li><Link to={global.variables.strDomainUrl +"focus-areas"}>Focus Areas</Link></li>
                <li><Link to={global.variables.strDomainUrl +"services"}>Services</Link></li>
                <li><Link to={global.variables.strDomainUrl +"campus-sustainability"}>Campus Sustainability</Link></li> 
              </ul>
            </Col>
            <Col>
              <ul> 
                <li><Link to={global.variables.strDomainUrl +"ourpartners"}>Partnerships</Link></li>
                <li><Link to={'/news-events'}>News & Events</Link></li>
                {/* <li><Link to={'/events'}>Events</Link></li> */}
                <li><Link to={global.variables.strDomainUrl +"careers"}>Careers</Link></li>
                <li><HashLink to="#contact" className="nav-link" scroll={el => scrollWithOffset(el)}>Contact Us</HashLink></li>
              </ul>
            </Col>
            <Col>
              <b>People</b>
              <ul>
                {
                  peopleCategory.map((val)=>{
                    return(

                      <li><Link to={global.variables.strDomainUrl + 'people/' + val.pcategory_pagename}>{val.pcategory_name}</Link></li>
                    )
                  })
                } 
              </ul>
              
            </Col>
            <Col>
            <b>Research</b>
              <ul>
                <li><Link to={global.variables.strDomainUrl +"research"}>Overview</Link></li>
                <li><Link to={global.variables.strDomainUrl +"research-projects"}>GESH Projects</Link></li>
                <li><Link to={global.variables.strDomainUrl +"publications"}>Publications</Link></li>
              </ul>
            </Col>
        </Row>
      </Container>

      {/* Breadcrumb */}
      <Breadcrumb className="breadcrumb-panel mt-auto">
        <Breadcrumb.Item href={global.variables.strDomainUrl}>Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Site Map</Breadcrumb.Item>
      </Breadcrumb>
    </>
  );
}