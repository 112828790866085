import React, { useState, useEffect } from "react";
import global from "../../global-variables";
import Header from "../layout/header";
import { Link, useParams, useNavigate } from "react-router-dom";
import $ from "jquery";
import WOW from "wowjs";
import { Container, Row, Col } from "reactstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Modal from "react-bootstrap/Modal";
import GESHProjectDeatilsNav from "../layout/geshprojectdetail-nav";
import axios from "axios";
import globalVariables from "../../global-variables";
import Metatag from "../../hooks/Metatag";
import ProjectDetailsPopup from "./project-details-popup";

export default function GESHProjectDetails() {

  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [getResearchid, setResearchid] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = (e, p_res_id) => {
    e.preventDefault();
    setShow(true);
    //get faculty id
    setResearchid(p_res_id);
  };

  let { rtype_pagename } = useParams();
  const qs_rtype_pagename = rtype_pagename;

  const [getresearchTypeList, setResearchTypeList] = useState(null);

  const [researchType_id, setRtype_id] = useState();

  // things to be done when the page is loaded
  useEffect(() => {
    // make the object for getting the research type list
    let _obj = new Object();
    _obj.rtype_status = 1;
    axios
      .post(
        globalVariables.variables.strApiUrl + "/researchApi/researchTypeList",
        _obj
      )
      .then((response) => {
        if (response.data === 'error') {
          navigate('/error-page')
        }
        else {
          setResearchTypeList(response.data[0]);
        }
      });
  }, []);

  const [perticularRTypeList, setPerticularRTypeList] = useState()
  // things to be done when the page is loaded
  useEffect(() => {
    // make the object for getting the research type list
    let _obj = new Object();
    _obj.rtype_status = 1;
    _obj.rtype_pagename = qs_rtype_pagename;
    axios
      .post(
        globalVariables.variables.strApiUrl + "/researchApi/researchTypeList",
        _obj
      )
      .then((response) => {
        if (response.data === 'error') {
          navigate('/error-page')
        }
        else {
          setPerticularRTypeList(response.data[0]);
          setRtype_id(response.data[0][0]?.rtype_id);
        }
      });
  }, [qs_rtype_pagename]);

  const [researchList, setResearchList] = useState([]);

  useEffect(() => {
    let _obj = new Object();
    _obj.res_rtype_id = researchType_id;
    _obj.res_status = 1;
    axios
      .post(
        globalVariables.variables.strApiUrl + "/researchApi/getresearchList",
        _obj
      )
      .then((response_researchList) => {
        if (response_researchList.data === 'error') {
          navigate('/error-page')
        }
        else {
          if (researchType_id === response_researchList.data[0][0].res_rtype_id && researchType_id != undefined)


            setResearchList(response_researchList.data[0]);
        }

      });
  }, [researchType_id]);

  const [researchCategory, setResearchCategoryList] = useState(null);
  useEffect(() => {
    let obj = new Object();
    obj.rcategory_status = 1;

    axios
      .post(
        globalVariables.variables.strApiUrl + "/rcategoryApi/getrcategoryList",
        obj
      )
      .then((response) => {
        if (response.data === 'error') {
          navigate('/error-page')
        }
        else {
          setResearchCategoryList(response.data[0]);
        }
      });
  }, []);

  // const [researchMapPeople, setResearchMapPeopleList] = useState(null);
  
  // useEffect(()=>{

  //   //get research map people list
  // let _objresmap_people= {res_id : getResearchid};
  
  // //get research category list
  // axios.post(global.variables.strApiUrl + '/researchApi/getresearchMapPeopleList',_objresmap_people)
  //     .then((response_research_m_ppl) => {
          
  //         if(response_research_m_ppl.data === "error")
  //         {
  //              navigate("/error-page")
  //         }
  //         else
  //         {
  //             setResearchMapPeopleList(response_research_m_ppl.data[0]);
  //         }
  // })
  
  // },[getResearchid])

  
  useEffect(() => {

    // remove bg-header-home 
    $(window).on("scroll", function () {
      $(".navbar").offset().top > 10 ? $(".fixed-top").removeClass("bg-header-home") : $(".fixed-top").removeClass("bg-header-home")
    });

    // initiate the wow effect
    new WOW.WOW({
      live: false,
    }).init();
  }, [researchList]);

  return (
    <>
      <Header></Header>

      {/* MetaTag */}
      <Metatag title='Green Energy and Sustainability Research Projects by IIT Bombay'
        description='Green Energy and Sustainability Research Projects by IIT Bombay'
        keywords='Green Energy and Sustainability Research Projects by IIT Bombay'
      />

      {/* Visual Panel */}
      <Container fluid className="bg-visual-inside p-0">
        <div className="visual-inside-big visual-container-inside">
          <Container>
            <Row className="g-md-0 rounded-4">
              <Col md={6}>
                <div className="d-flex align-items-end h-100 pb-md-5">
                  <div className="d-inline-block m-0 align-self-end py-xl-3 p-2 text-white">
                    <h1 className="fw-light text-white m-0 align-self-center wow fadeInUp">
                      GESH Projects
                    </h1>
                  </div>
                </div>
              </Col>
              <Col className="mt-auto ms-auto">
                <div className="border-bottom border-5 border-primary rounded-4">
                  <img src={global.variables.strDomainUrl + "images/visual-research2.jpg"} className="img-fluid rounded-4" alt="" title="" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>
      {/* Container */}
      <Container className="my-5 pt-md-5 position-relative">
        <Row className="g-0">
          <Col md={8} lg={10} className="projects order-md-1 order-2">
            <div className="border rounded-4 p-lg-5 p-3 h-100">
              <div className="pe-2 col-md-11 col-12">
                {getresearchTypeList != null ?
                  getresearchTypeList.length > 0 ?
                    <Row className="row-cols-1 g-0 px-2 px-md-0">
                      {
                        // researchList.length > 0 ? 
                        researchList.map((val_research) => {
                          return (
                            <>
                              {
                                <Col>
                                  <div>
                                    <div className="d-lg-flex justify-content-between">
                                      <h5 className="text-dark wow fadeInUp">
                                        {val_research.res_title}
                                      </h5>
                                      <div className="col-lg-3 col-6 text-lg-end text-start">
                                        <Link onClick={(e) => handleShow(e, val_research.res_id)} className="btn btn-primary rounded-pill px-4 mt-auto mt-2 research-projects wow fadeInUp">
                                          Read More
                                        </Link>
                                      </div>
                                    </div>
                                    <ul className="list-group list-group-flush px-0 mb-3 projects list d-none">
                                      {val_research.res_gesh_faculty != null && val_research.res_gesh_faculty != "" ?
                                        <li className="list-group-item border-0 p-0 wow fadeInUp">
                                          <b>Faculty:</b> <span
                                            dangerouslySetInnerHTML={{
                                              __html: val_research.res_gesh_faculty,
                                            }}
                                          ></span>
                                        </li>
                                        : null}

                                      {val_research.res_gesh_partner != null &&
                                        val_research.res_gesh_partner != "" ?
                                        <li className="list-group-item border-0 p-0 wow fadeInUp">
                                          <b>Partner:</b>
                                          <span
                                            dangerouslySetInnerHTML={{
                                              __html: val_research.res_gesh_partner,
                                            }}
                                          ></span>
                                        </li>
                                        : null}

                                      {val_research.res_supervisor != null &&
                                        val_research.res_supervisor != "" ?
                                        <li className="list-group-item border-0 p-0 wow fadeInUp">
                                          <b>Supervisor:</b>
                                          <span
                                            dangerouslySetInnerHTML={{
                                              __html: val_research.res_supervisor,
                                            }}
                                          ></span>
                                        </li>
                                        : null}
                                      {val_research.res_co_supervisor != null &&
                                        val_research.res_co_supervisor != "" ?
                                        <li className="list-group-item border-0 p-0 wow fadeInUp">
                                          <b>Co-supervisor:</b>
                                          <span
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                val_research.res_co_supervisor,
                                            }}
                                          ></span>
                                        </li>
                                        : null}
                                    </ul>

                                  </div>
                                  <hr className="my-4 border-opacity-50" />
                                </Col>
                              }
                            </>
                          );
                        })
                      }
                    </Row>
                    :
                    <Col>
                      <span className="text-center">
                        <b>No records found</b>
                      </span>
                    </Col>

                  :
                  <Col className="text-center">
                    <span className="text-center">Loading...</span>
                  </Col>
                }
              </div>
            </div>
          </Col>
          <Col md={5} lg={3} className="py-5 container-end order-md-2 order-1">
            <div className="ps-md-5 bg-white h-100">
              <div className="border-bottom border-4 border-primary rounded-4 h-100">
                <div className="bg-primary bg-opacity-10 rounded-4 py-4 px-4 border h-100">
                  <GESHProjectDeatilsNav qs_rtype_pagename={qs_rtype_pagename} submenutitle={researchList[0]?.rtype_name} researchCategory={researchCategory} getresearchTypeList={getresearchTypeList}>
                  </GESHProjectDeatilsNav>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      {/* Breadcrumb */}
      <Breadcrumb className="breadcrumb-panel mt-auto">
        <Breadcrumb.Item href={globalVariables.variables.strDomainUrl}>Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Research</Breadcrumb.Item>
        <Breadcrumb.Item active>GESH Projects</Breadcrumb.Item>
      </Breadcrumb>
      {/* PROJECT DETAILS */}
      <ProjectDetailsPopup
        show={show}
        handleClose={handleClose}
        researchList={researchList}
        getResearchid={getResearchid}
        // researchMapPeople={researchMapPeople}
      />
      {/* PROJECT DETAILS */}
    </>
  );
}
